import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_APPRAISALS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateAppraisal } from 'modules/authorizations/store/actions/homesale.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const OrderDetailsCard = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { appraisalInfo, isReadOnly } = props;

  const recOptions = [
    { title: 'Transferee', id: 1 },
    { title: 'Client', id: 2 },
    { title: 'Other', id: 3 },
  ];

  const statusOptions = [
    { title: 'Used', id: 1 },
    { title: 'Cancelled', id: 2 },
    { title: 'Dropped', id: 3 },
    { title: 'Approved', id: 4 },
    { title: 'Reappraisal', id: 5 },
    { title: 'Dest. Appraisal', id: 6 },
    { title: 'Appraisal Reconsideration', id: 7 },
  ];

  const hoursDiff = (endDate, startDate) => {
    const diffInMs = endDate - startDate;
    return Math.round(diffInMs / (1000 * 60 * 60));
  };

  const tabs = [
    { header: 'Process Details', order: 1 },
    { header: 'Other Info', order: 2 },
  ];

  const summaries = [
    { header: 'Status', accessorKey: 'status', type: 'dropdown-v2', options: statusOptions, required: false, value: appraisalInfo ? appraisalInfo.status : '', tab: 1 },
    { header: 'Appraised Value (V)', accessorKey: 'appraisedValueVerbal', type: 'money', required: false, value: appraisalInfo ? appraisalInfo.appraisedValueVerbal : '', tab: 1 },
    { header: 'Appraised Value (W)', accessorKey: 'appraisedValueWritten', type: 'money', required: false,  value: appraisalInfo ? appraisalInfo.appraisedValueWritten : '', tab: 1 },
    { header: 'Suggested List (V)', accessorKey: 'suggestedListVerbal', type: 'money', required: false, value: appraisalInfo ? appraisalInfo.suggestedListVerbal : '', tab: 1 },
    { header: 'Suggested List (W)', accessorKey: 'suggestedListWritten', type: 'money', required: false, value: appraisalInfo ? appraisalInfo.suggestedListWritten : '', tab: 1 },
    { header: 'Received Date (V)', accessorKey: 'receivedDateVerbal', type: 'date', required: false, value: appraisalInfo ? appraisalInfo.receivedDateVerbal : '', tab: 1 },
    { header: 'Received Date (W)', accessorKey: 'receivedDateWritten', type: 'date', required: false, value: appraisalInfo ? appraisalInfo.receivedDateWritten : '', tab: 1 },
    { header: 'Ordered/Received (Hours)', accessorKey: 'orderedReceived', type: 'text', required: false, hideInModal: true, value: (appraisalInfo && appraisalInfo.receivedDateWritten && appraisalInfo.orderedDate) ? hoursDiff(new Date(appraisalInfo.receivedDateWritten), new Date(appraisalInfo.orderedDate)) : '', tab: 1 },
    { header: 'Reviewed By', accessorKey: 'reviewedBy', type: 'text', required: false, value: appraisalInfo ? appraisalInfo.reviewedBy : '', tab: 1 },
    { header: 'Reviewed When', accessorKey: 'reviewedWhen', type: 'date', required: false, value: appraisalInfo ? appraisalInfo.reviewedWhen : '', tab: 1 },
    { header: 'Delay Reason', accessorKey: 'delayReason', type: 'notes', required: false, showBelow: true, value: appraisalInfo ? appraisalInfo.delayReason : '', tab: 1 },

    { header: 'Recommended By', accessorKey: 'reccomendation', type: 'dropdown-v2', options: recOptions, required: false, value: appraisalInfo ? appraisalInfo.reccomendation : '', tab: 2 },
    { header: 'Num Appraisals Preferred', accessorKey: 'appraisalsPreferred', type: 'number', required: false, value: appraisalInfo ? appraisalInfo.appraisalsPreferred : '', tab: 2 },
    { header: 'Preferred Appointment', accessorKey: 'preferredAppointment', type: 'date', required: false, value: appraisalInfo ? appraisalInfo.preferredAppointment : '', tab: 2 },
    { header: 'Approx Start Date', accessorKey: 'approxStartDate', type: 'date', required: false, value: appraisalInfo ? appraisalInfo.approxStartDate : '', tab: 2 },
    { header: 'Home for Listing', accessorKey: 'homeForListing', type: 'text', required: false, value: appraisalInfo ? appraisalInfo.homeForListing : '', tab: 2 },
    { header: 'Org Name', accessorKey: 'orgName', type: 'text', required: false, value: appraisalInfo ? appraisalInfo.orgName : '', tab: 2 },
    { header: 'Contact UID', accessorKey: 'contactUid', type: 'text', required: false, value: appraisalInfo ? appraisalInfo.contactUid : '', tab: 2 },
    { header: 'Contact First', accessorKey: 'contactFirstName', type: 'text', required: false, value: appraisalInfo ? appraisalInfo.contactFirstName : '', tab: 2 },
    { header: 'Contact Last', accessorKey: 'contactLastName', type: 'text', required: false, value: appraisalInfo ? appraisalInfo.contactLastName : '', tab: 2 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...appraisalInfo };

    if (tab === 1) {
      newValues.status = values.status;
      newValues.appraisedValueVerbal = values.appraisedValueVerbal;
      newValues.appraisedValueWritten = values.appraisedValueWritten;
      newValues.suggestedListVerbal = values.suggestedListVerbal;
      newValues.suggestedListWritten = values.suggestedListWritten;
      newValues.receivedDateVerbal = values.receivedDateVerbal;
      newValues.receivedDateWritten = values.receivedDateWritten;
      newValues.delayReason = values.delayReason;
      newValues.reviewedBy = values.reviewedBy;
      newValues.reviewedWhen = values.reviewedWhen;
    } else {
      newValues.reccomendation = values.reccomendation;
      newValues.appraisalsPreferred = values.appraisalsPreferred;
      newValues.preferredAppointment = values.preferredAppointment;
      newValues.approxStartDate = values.approxStartDate;
      newValues.homeForListing = values.homeForListing;
      newValues.orgName = values.orgName;
      newValues.contactUid = values.contactUid;
      newValues.contactFirstName = values.contactFirstName;
      newValues.contactLastName = values.contactLastName;
    }


    const resp = await props.updateAppraisal(newValues);
    if (resp.type === UPDATE_APPRAISALS_FAILURE) {
      props.showToast('Failed to update appraisal info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateAppraisal,
    showToast,
  }),
)(OrderDetailsCard);