/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import DestinationServices from './destinationServices.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../../layout/types';

import {
  getAuthDateSummary, getAuthorizationSummary, setAuthId, setLoadingComplete,
} from '../../store/actions/authorizations.actions';

import {
  getAuthDestinationServices, getHouseHuntingList, getOfferHistoryList,
  postAuthDestinationServices, updateAuthDestinationServices,
} from 'modules/authorizations/store/actions/destinationService.actions';

import {
  getBrokerAgents,
} from 'modules/authorizations/store/actions/dataManagement.actions';

import {
  GET_AUTH_DATE_SUMMARY_FAILURE, GET_AUTH_SUMMARY_FAILURE,
} from '../../store/types/authorizationTypes';

import {
  GET_AUTH_DESTINATION_SERVICES_FAILURE, GET_AUTH_HOUSE_HUNTING_LIST_FAILURE, GET_AUTH_OFFER_HISTORY_LIST_FAILURE,
  POST_AUTH_DESTINATION_SERVICES_FAILURE, UPDATE_AUTH_DESTINATION_SERVICES_FAILURE,
} from 'modules/authorizations/store/types/destinationServiceTypes';

import {
  GET_ALL_BROKER_AGENTS_FAILURE,
} from 'modules/authorizations/store/types/dataManagementTypes';

import {
  COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING,
} from 'styles/theme';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
});

const DestinationServicesView = (props) => {

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY, 
      warning: COLOR_WARNING,
    },
   
  });
    
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const clientInfo = details && details.summary && props.unmappedClients ? props.unmappedClients.find((e) => e.description === details.summary.clientName) : null;

  const [brokerAgents, setBrokerAgents] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const updateDestinationService = async (values) => {
    setIsLoading(true);
    if (details.destinationServices && details.destinationServices.length !== 0) {
      const resp = await props.updateAuthDestinationServices(values);
      if (resp.type === UPDATE_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update auth destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postAuthDestinationServices(values);
      if (resp.type === POST_AUTH_DESTINATION_SERVICES_FAILURE) {
        props.showToast('Failed to update auth destination services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  useEffect(() => {
    async function fetchData () {
      const id = props.match.params.id;

      setIsLoading(true);
      props.setAuthId(id);

      if (!details || (details && !details.destinationServices)) {
        let { type } = await props.getAuthDestinationServices(id);
        if (type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
          this.props.showToast('Failed to retrieve destination services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if (!details || (details && !details.dateSummary)) {
        let { type } = await props.getAuthDateSummary(id);
        if (type === GET_AUTH_DATE_SUMMARY_FAILURE) {
          this.props.showToast('Failed to retrieve date summary, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if (!details || (details && !details.summary)) {
        let { type } = await props.getAuthorizationSummary(id);
        if (type === GET_AUTH_SUMMARY_FAILURE) {
          this.props.showToast('Failed to retrieve auth summary, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
      }

      if (!details || (details && !details.houseHunting)) {
        let { type } = await props.getHouseHuntingList(id);
        if (type === GET_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
          this.props.showToast('Failed to retrieve all house hunting trips, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        } 
      }

      if (!details || (details && !details.offerHistory)) {
        let { type } = await props.getOfferHistoryList(id);
        if (type === GET_AUTH_OFFER_HISTORY_LIST_FAILURE) {
          this.props.showToast('Failed to retrieve all offer history records, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        } 
      }

      if (!brokerAgents && clientInfo) {
        let { type, response } = await props.getBrokerAgents();
        if (type === GET_ALL_BROKER_AGENTS_FAILURE) {
          this.props.showToast('Failed to retrieve all brokers/agents, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        } else {
          setBrokerAgents(response);
        }
      }

      setIsLoading(false);
      props.setLoadingComplete();
    }

    fetchData();
  }, [clientInfo, details]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details &&
          <DestinationServices updateDestinationService={updateDestinationService} details={details} brokerAgents={brokerAgents} id={props.match.params.id} history={props.history} location={props.location} />
        }
        {isLoading && <FullscreenSpinner />}

      </Box>
    </ThemeProvider>
  );

};

DestinationServicesView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { authorizationDetails, isLoading } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    unmappedClients,
    isLoading,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getAuthDateSummary,
    getAuthDestinationServices,
    updateAuthDestinationServices,
    postAuthDestinationServices,
    getBrokerAgents,
    setAuthId,
    setLoadingComplete,
    getHouseHuntingList,
    getOfferHistoryList,
  }),
)(DestinationServicesView);