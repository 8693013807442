/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Paper, Typography } from '@mui/material';
import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import React from 'react';
import classNames from 'classnames';
import ReportDetailsCard from './reportDetailsCard.component';
import FilePreviewCard from './filePreview.component';

const styles = theme => ({
  container: {
    height: 'auto',
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    whiteSpace: 'nowrap',
    width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
    },
  },
  headerFixed: {
    zIndex: 4,
  },
  estimateHeaderFixed: {
    paddingTop: '115px',
    zIndex: 2,
  },
});

const ExpenseDetails = props => {
  const { details, clientInfo, location, vendors, inspectors, titleReps, brokerAgents } = props;
  const { authClientPolicies, employee, submittedExpenses, summaryExpenseLines } = props.details;

  const expenseInfo = submittedExpenses ? submittedExpenses.find((s) => s.expenseId === parseInt(props.expenseId)) : null;
  
  const isReadOnly = expenseInfo?.isExpenseApproved ?? false;

  return (
    <Box>
      <Paper square className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px', width: '100%' }}>
          <Box sx={{ paddingLeft: '100px' }}>&nbsp;</Box>
          <Box sx={{ display: 'flex', justifyContent: 'center', flexGrow: 1 }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '10px' }}>
                <InfoBox label="Authorization Name" value={`${employee?.firstName} ${employee?.lastName}`} />
                <InfoBox label="Policy" value={authClientPolicies?.clientPolicyCode} />
                <InfoBox label="Status" value="Active" />
                <InfoBox label="Move Cap" value="$5,000,000,000" />
                <InfoBox label="Budget Remaining" value="$4,999,998,001" />
              </Box>
            </Box>
          </Box>
          <Box sx={{ color: 'green', padding: '10px' }}>Repay Agreement Received</Box>
        </Box>
      </Paper>
      <Box sx={{ paddingTop: '110px', margin: '16px', marginTop: '0px' }}>
        <ReportDetailsCard expenseInfo={expenseInfo} expenseLines={summaryExpenseLines} authId={props.authId} expenseCodes={props.expenseCodes} history={props.history} isReadOnly={isReadOnly}/>
      </Box>
      <Box sx={{ paddingTop: '10px', margin: '16px', marginTop: '0px' }}>
        <FilePreviewCard 
          details={details} 
          authId={props.authId} 
          clientInfo={clientInfo} 
          history={props.history} 
          location={location} 
          match={props.match}
          brokerAgents={brokerAgents}
          vendors={vendors}
          inspectors={inspectors}
          titleReps={titleReps}
        />
      </Box>
    </Box>
  );
};

const InfoBox = ({ label, value }) => (
  <Box>
    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
      <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
        {label}
      </Typography>
    </Box>
    <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
      <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
        {value}
      </Typography>
    </Box>
  </Box>
);

const mapStateToProps = state => ({});

export default compose(withStyles(styles), connect(mapStateToProps, {}))(ExpenseDetails);
