/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Button, Dialog, DialogActions, Grid, MenuItem, Select, Typography,
} from '@mui/material';

import { DELETE_HOMESALE_RESALE_OFFER_FAILURE, GET_ALL_HOMESALE_RESALE_OFFER_FAILURE, GET_HOMESALE_RESALE_OFFER_FAILURE, POST_HOMESALE_RESALE_OFFER_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { deleteHomesaleResaleOffer, getHomesaleResaleOffer, getHomesaleResaleOffers, postHomesaleResaleOffer } from 'modules/authorizations/store/actions/homesale.actions';
import { formatDateForInput } from 'utilities/common';
import { showToast } from 'modules/layout/layout.actions';
import AdvancedSearch from 'modules/common/advancedSearch.component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ResaleOffersCard from './resaleOffersCard.component';
import Spinner from 'modules/common/spinner.component';


const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const ResaleOffers = (props) => {    
  const { details, id, brokerAgents, isReadOnly } = props;

  const [currentOfferId, setCurrentOfferId] = useState(details.allHomesaleResaleOffers && details.allHomesaleResaleOffers[0] ? details.allHomesaleResaleOffers[0].offerId : null);
  const [showAddOffer, setShowAddOffer] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);

  const [newBrokerId, setNewBrokerId] = useState(0);
  const [newBrokerName, setNewBrokerName] = useState('');

  const brokerAgentSearchInfo = {
    searchingColumns: [{ name: 'typeOfAgent', title: 'Type of Agent' }, { name: 'agentName', title: 'Agent Name' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }],
    searchingColumnExtensions: [{ columnName: 'typeOfAgent', width: 150 }, { columnName: 'agentName', width: 200 }, { columnName: 'companyName', width: 300 }, { columnName: 'city', width: 150 }, { columnName: 'state', width: 150 }],
  };

  const offer = details.allHomesaleResaleOffers ? details.allHomesaleResaleOffers.find((s) => s.offerId === currentOfferId) : null;
  
  const createOffer = async () => {
    const authId = details.summary.authorizationId;
    
    setIsLoadingModal(true);

    const resp = await props.postHomesaleResaleOffer({
      authorizationId: authId,
      brokerId: newBrokerId,
      brokerName: newBrokerName,
    });

    if (resp.type === POST_HOMESALE_RESALE_OFFER_FAILURE) {
      props.showToast('Failed to update the Homesale Resale Offer info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      const allOffersResp = await props.getHomesaleResaleOffers(authId);
      if (allOffersResp.type === GET_ALL_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to fetch Homesale Resale Offers, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('New Offer created successfully.', { userMustDismiss: false, severity: 'success' });
        setCurrentOfferId(resp.response.offerId);
        setShowAddOffer(false);
      }
      setIsLoadingModal(false);
    }
  };

  const deleteOffer = async () => {
    const deleteOfferResp = await props.deleteHomesaleResaleOffer(currentOfferId);
    if (deleteOfferResp.type === DELETE_HOMESALE_RESALE_OFFER_FAILURE) {
      props.showToast('Failed to delete the Homesale Resale Offer info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('Offer deleted successfully.', { userMustDismiss: false, severity: 'success' });
      setCurrentOfferId(details.allHomesaleResaleOffers[0].offerId);
      const getOffersResp = await props.getHomesaleResaleOffers(id);
      if (getOffersResp.type === GET_ALL_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to get the Homesale Resale Offer info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }     
    }
  };

  const getOfferData = async () => {
    const offerResp = await props.getHomesaleResaleOffer(currentOfferId);
    if (offerResp.type === GET_HOMESALE_RESALE_OFFER_FAILURE) {
      props.showToast('Failed to get the Homesale Resale Offer info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } 
  };

  useEffect(() => {
    if (currentOfferId) {
      getOfferData();
    }
  }, [currentOfferId]);

  return (
    <Box>
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
          <Box>
            <div className={props.classes.mb2}>
              <Typography variant="subtitle2">
                Offer
              </Typography>
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
              <Select
                labelId={'offerSelect'}
                key={'offerSelect'}
                label={''}
                name={'offerId'}
                value={currentOfferId}
                sx={{ maxWidth: '360px', minWidth: '180px' }}
                onChange={(e) =>
                  setCurrentOfferId(e.target.value)
                }
              >
                {details && details.allHomesaleResaleOffers && details.allHomesaleResaleOffers.map((opt) => (
                  <MenuItem key={opt.offerId} value={opt.offerId} sx={{ margin: 'dense' }}>
                    {opt.brokerName} ({formatDateForInput(opt.offerDate)})
                  </MenuItem>
                ))}
              </Select>
              {!isReadOnly && <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={() => setShowAddOffer(true)}> New Offer </Button> }
            </Box>
          </Box>
        </Box>
        {!isReadOnly && currentOfferId == null && (
          <Box sx={{ padding: '20px', paddingTop: '165px', fontSize: '20px' }}>
            <b>No offer selected. Create a new offer above to get started.</b>
          </Box>
        )}
        { currentOfferId &&(
          <Grid container>
            <ResaleOffersCard offer={offer} offerId={currentOfferId} id={id} deleteOffer={deleteOffer} isReadOnly={isReadOnly}/>
          </Grid>
        )}

        <Dialog open={showAddOffer} fullWidth={false} maxWidth={'lg'}>
          <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
            <Box sx={{ marginRight: '20px' }}>
              <AdvancedSearch 
                labelText={'Broker'} 
                displayValueAccessorKey={'agentName'} 
                saveIdValueAccessorKey={'brokerId'} 
                searchingCriteria={brokerAgentSearchInfo.searchingColumns} 
                searchingCriteriaExtensions={brokerAgentSearchInfo.searchingColumnExtensions} 
                searchingRows={brokerAgents.filter((a) => a.typeOfAgent === 'Broker')} 
                id={newBrokerId} 
                value={newBrokerName} 
                setIdValue={(brokerId, value) => { setNewBrokerId(brokerId); setNewBrokerName(value); }}
                isReadOnly={isReadOnly}
              />
              <Typography variant="caption" display="block" color="primary">
                Required
              </Typography>
            </Box>
          </Box>
          <DialogActions sx={{ p: '1.25rem' }}>
            {isLoadingModal && <Spinner />}
            {!isReadOnly && !isLoadingModal && 
              <>
                <Button onClick={() => setShowAddOffer(false)}>Cancel</Button>
                <Button onClick={createOffer} color="primary" type="submit" variant="contained" disabled={!(newBrokerName && newBrokerName.length > 0)}>
                  Create Offer
                </Button>
              </>
            }
          </DialogActions>
        </Dialog>
      </Grid>
    </Box>
  );

};

ResaleOffers.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getHomesaleResaleOffer,
    getHomesaleResaleOffers,
    postHomesaleResaleOffer,
    deleteHomesaleResaleOffer,
    showToast,
  }),
)(ResaleOffers);