import { ValueType, parseFormValue, vendorContactSearchInfo, vendorSearchInfo } from './formDataConstants';

export const createLanguageTrainingRows = (formDataList, vendors, vendorContacts, familyMembers) => {
  if (!formDataList || !Array.isArray(formDataList)) {
    return [];
  }

  return formDataList.map((data) => ({
    ...data,
    hoursApproved: parseFormValue(data.hoursApproved, ValueType.FloatType),
    hourlyRate: parseFormValue(data.hourlyRate, ValueType.FloatType),
    hoursAttended: parseFormValue(data.hoursAttended, ValueType.FloatType),
    initationDate: parseFormValue(data.initationDate, ValueType.DateTimeType),
    commission: parseFormValue(data.commission, ValueType.FloatType),
    referralAmount: parseFormValue(data.referralAmount, ValueType.FloatType),
    familyMember: familyMembers?.find((f) => f.id === data?.familyId)?.name,
  }));
};

export const createLanguageTrainingPostRequest = (formData, authorizationId, familyMembers) => {
  const request = { ...formData };
  request.authorizationId = authorizationId;
  parseLanguageTrainingFormValues(request, formData);
  request.familyId = familyMembers?.find((f) => f.name === formData.familyMember)?.id;
  return request;
};

export const createLanguageTrainingPutRequest = (originalFormData, newFormData, authorizationId, familyMembers) => {
  const request = { ...newFormData };
  request.authorizationId = authorizationId;
  request.authGlobalLanguageId = originalFormData.authGlobalLanguageId;
  request.familyId = familyMembers?.find((f) => f.name === newFormData.familyMember)?.id;
  return request;
};

export const createLanguageTrainingColumns = (vendors, vendorContacts, familyMembers) => {
  return [
    {
      accessorKey: 'familyMember',
      header: 'Family Member',
      type: 'dropdown',
      options: familyMembers?.map((f) => f.name),
      required: true,
      size: 65,
    },
    {
      accessorKey: 'vendorId',
      header: 'Vendor (Trainer)',
      nameKey: 'companyName',
      searchInfo: vendorSearchInfo(vendors),
      type: 'advanced-search',
      required: true,
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? vendors?.find((v) => v.vendorId === cell.getValue())?.companyName : ''),
    },
    {
      //TODO - Figure out how to filter this based on the selected company ...
      accessorKey: 'vendorContactId',
      header: 'Vendor Contact',
      nameKey: 'fullName',
      searchInfo: vendorContactSearchInfo(vendorContacts),
      type: 'advanced-search',
      required: true,
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? vendorContacts?.find((v) => v.vendorContactId === cell.getValue())?.fullName : ''),
    },
    { accessorKey: 'language', header: 'Language', type: 'text', required: true, size: 65 },
    { accessorKey: 'hoursApproved', header: 'Hours Approved', type: 'number', hideInTable: true, size: 65 },
    { accessorKey: 'hourlyRate', header: 'Hourly Rate', type: 'number', hideInTable: true, size: 65 },
    { accessorKey: 'hoursAttended', header: 'Hours Attended', type: 'number', hideInTable: true, size: 65 },
    {
      accessorKey: 'initationDate',
      header: 'Initation Date',
      type: 'date',
      hideInTable: true,
      required: true,
      size: 65,
      Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-'),
    },
    { accessorKey: 'originCity', header: 'Origin City', type: 'text', hideInTable: true, size: 65 },
    { accessorKey: 'originCountry', header: 'Origin Country', type: 'text', hideInTable: true, size: 65 },
    { accessorKey: 'destinationCity', header: 'Destination City', type: 'text', hideInTable: true, size: 65 },
    { accessorKey: 'destinationCountry', header: 'Destination County', type: 'text', hideInTable: true, size: 65 },
    { accessorKey: 'commission', header: 'Commission', type: 'number', hideInTable: true, size: 65 },
    { accessorKey: 'referralAmount', header: 'Referral Amount', type: 'number', hideInTable: true, size: 65 },
  ];
};

const parseLanguageTrainingFormValues = (currentRequest, newRequestValues) => {
  currentRequest.hoursApproved = parseFormValue(newRequestValues.hoursApproved, ValueType.FloatType);
  currentRequest.hourlyRate = parseFormValue(newRequestValues.hourlyRate, ValueType.FloatType);
  currentRequest.hoursAttended = parseFormValue(newRequestValues.hoursAttended, ValueType.FloatType);
  currentRequest.initationDate = parseFormValue(newRequestValues.initationDate, ValueType.DateTimeType);
  currentRequest.commission = parseFormValue(newRequestValues.commission, ValueType.FloatType);
  currentRequest.referralAmount = parseFormValue(newRequestValues.referralAmount, ValueType.FloatType);
};
