/* eslint-disable react-hooks/exhaustive-deps */

import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import  { withStyles } from '@material-ui/core';

import {
  Box, Button, Grid, Paper, Typography,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import classNames from 'classnames';

import { GET_INTERN_SERVICES_FAILURE, POST_INTERN_SERVICES_FAILURE, UPDATE_INTERN_SERVICES_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';
import { getAuthDateSummary, getAuthorizationSummary, setAuthId, setLoadingComplete } from '../../store/actions/authorizations.actions';
import { getBrokerAgents, getBrokerAgentsById } from 'modules/authorizations/store/actions/dataManagement.actions';
import { getInternServices, postInternServices, updateInternServices } from 'modules/authorizations/store/actions/canidateTrip.actions';

import { calculateTripDuration } from 'modules/authorizations/utils';
import CostEstimatesCard from './costEstimatesCard.component';
import EditableCard from 'modules/common/editableCard.component';
import SummaryCard from '../summaryCard.component';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
});

const InternServices = (props) => {    

  const { brokerAgents, id, details, location } = props;

  const [currentStatus, setCurrentStatus] = useState(details && details.candidateServices ? details.candidateServices.status : 'Initiated');
  const [isLoading, setIsLoading] = useState(false);

  const brokerAgentSearchInfo = {
    searchingColumns: [{ name: 'typeOfAgent', title: 'Type of Agent' }, { name: 'agentName', title: 'Agent Name' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }],
    searchingColumnExtensions: [{ columnName: 'typeOfAgent', width: 150 }, { columnName: 'agentName', width: 200 }, { columnName: 'companyName', width: 300 }, { columnName: 'city', width: 150 }, { columnName: 'state', width: 150 }],
    searchingRows: brokerAgents,
    nameKey: 'agentName',
    idKey: 'brokerId',
  };

  const internOverviewCardTabs = [
    { header: 'Intern Services Overview', order: 1, iconPath: ['fas', 'fa-id-card'] },
  ];

  const internTripToCardTabs = [
    { header: 'Trip To Summary', order: 1, iconPath: ['fas', 'fa-plane-departure'] },
    { header: 'Trip To Travel Booking', order: 2, iconPath: ['fas', 'fa-hotel'] },
  ];
  const internReturnTripCardTabs = [
    { header: 'Return Trip Summary', order: 1, iconPath: ['fas', 'fa-plane-departure'] },
    { header: 'Return Trip Travel Booking', order: 2, iconPath: ['fas', 'fa-hotel'] },
  ];

  const internOverviewSummaries = [
    { header: 'Initiation Date', accessorKey: 'initiationDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.initiationDate : null, tab: 1 },
    { header: 'Interview Start Date', accessorKey: 'interviewStartDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.interviewStartDate : null, tab: 1 },
    { header: 'Interview End Date', accessorKey: 'interviewEndDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.interviewEndDate : null, tab: 1 },
    { header: 'Area Tour', accessorKey: 'areaTour', type: 'checkbox', required: false, value: details && details.internServices ? details.internServices.areaTour : false, tab: 1 },
    { header: 'Area Tour Initiated Date', accessorKey: 'areaTourInitiatedDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.areaTourInitiatedDate : null, tab: 1 },
    { header: 'Area Tour Canceled Date', accessorKey: 'areaTourCanceledDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.areaTourCanceledDate : null, tab: 1 },
    { header: 'Area Tour Completed Date', accessorKey: 'areaTourCompletedDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.areaTourCompletedDate : null, tab: 1 },
    { header: 'Area Tour Agent', accessorKey: 'areaTourAgentId', nameKey: 'areaTourAgentName', nameValue: details && details.internServices ? details.internServices.areaTourAgentName : '', type: 'advanced-search', searchInfo: brokerAgentSearchInfo, required: false, value: details && details.internServices ? details.internServices.areaTourAgentId : 0, tab: 1 },
    { header: 'Notes', accessorKey: 'notes', type: 'notes', required: false, value: details && details.internServices ? details.internServices.notes : '', tab: 1 },
  ];

  const tripToSummaries = [
    { header: 'Departure Date', accessorKey: 'tripToDepartureDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.tripToDepartureDate : null, tab: 1 },
    { header: 'Arrival Date', accessorKey: 'tripToArrivalDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.tripToArrivalDate : null, tab: 1 },
    { header: 'Trip Duration', accessorKey: 'tripToTravelDuration', type: 'number', required: false, value: details && details.internServices && (details.internServices.tripToDepartureDate && details.internServices.tripToArrivalDate) ? calculateTripDuration(details.internServices.tripToDepartureDate, details.internServices.tripToArrivalDate, details.internServices.tripToTravelDuration) : 0, tab: 1 },
    { header: 'Special Needs', accessorKey: 'tripToSpecialNeeds', type: 'notes', required: false, value: details && details.internServices ? details.internServices.tripToSpecialNeeds : '', tab: 1 },
    { header: 'Notes', accessorKey: 'tripToNotes', type: 'notes', required: false, value: details && details.internServices ? details.internServices.tripToNotes : '', tab: 1 },
    { header: 'Air Transport', accessorKey: 'tripToAirTransport', type: 'checkbox', required: false,  value: details.internServices ? details.internServices.tripToAirTransport : false, tab: 2 },
    { header: 'Departure Date', accessorKey: 'tripToAirTransportDepartureDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.tripToAirTransportDepartureDate : null, tab: 2 },
    { header: 'Arrival Date', accessorKey: 'tripToAirTransportArrivalDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.tripToAirTransportArrivalDate : null, tab: 2 },
    { dividerLine: true, tab: 2 },
    { header: 'Hotel Booking', accessorKey: 'tripToHotelBooking', type: 'checkbox', required: false,  value: details.internServices ? details.internServices.tripToHotelBooking : false, tab: 2 },
    { header: 'Hotel Name', accessorKey: 'tripToHotelName', type: 'text', required: false,  value: details.internServices ? details.internServices.tripToHotelName : '', tab: 2 },
    { header: 'Hotel Address', accessorKey: 'tripToHotelAddress', type: 'text', required: false,  value: details.internServices ? details.internServices.tripToHotelAddress : '', tab: 2 },
    { header: 'Hotel Check-In Date', accessorKey: 'tripToHotelCheckIn', type: 'date', required: false, value: details && details.internServices ? details.internServices.tripToHotelCheckIn : null, tab: 2 },
    { header: 'Hotel Check-Out Date', accessorKey: 'tripToHotelCheckOut', type: 'date', required: false, value: details && details.internServices ? details.internServices.tripToHotelCheckOut : null, tab: 2 },
    { header: 'Duration Of Stay', accessorKey: 'tripToHotelDurationOfStay', type: 'number', required: false, value: details && details.internServices && (details.internServices.tripToHotelCheckIn && details.internServices.tripToHotelCheckOut) ? calculateTripDuration(details.internServices.tripToHotelCheckIn, details.internServices.tripToHotelCheckOut, details.internServices.tripToHotelDurationOfStay) : 0, tab: 2 },
    { dividerLine: true, tab: 2 },
    { header: 'Rental Car', accessorKey: 'tripToRentalCarBooking', type: 'checkbox', required: false,  value: details.internServices ? details.internServices.tripToRentalCarBooking : false, tab: 2 },
    { header: 'Rental Car Year', accessorKey: 'tripToRentalCarYear', type: 'text', required: false,  value: details.internServices ? details.internServices.tripToRentalCarYear : '', tab: 2 },
    { header: 'Rental Car Make', accessorKey: 'tripToRentalCarMake', type: 'text', required: false,  value: details.internServices ? details.internServices.tripToRentalCarMake : '', tab: 2 },
    { header: 'Rental Car Model', accessorKey: 'tripToRentalCarModel', type: 'text', required: false,  value: details.internServices ? details.internServices.tripToRentalCarModel : '', tab: 2 },
    { header: 'Rental Car Start Date', accessorKey: 'tripToRentalCarStartDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.tripToRentalCarStartDate : null, tab: 2 },
    { header: 'Rental Car End Date', accessorKey: 'tripToRentalCarEndDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.tripToRentalCarEndDate : null, tab: 2 },
    { header: 'Rental Duration', accessorKey: 'tripToRentalCarDuration', type: 'number', required: false, value: details && details.internServices && (details.internServices.tripToRentalCarStartDate && details.internServices.tripToRentalCarEndDate) ? calculateTripDuration(details.internServices.tripToRentalCarStartDate, details.internServices.tripToRentalCarEndDate, details.internServices.tripToRentalCarDuration) : 0, tab: 2 },
  ];

  const returnTripSummaries = [
    { header: 'Departure Date', accessorKey: 'returnTripDepartureDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.returnTripDepartureDate : null, tab: 1 },
    { header: 'Arrival Date', accessorKey: 'returnTripArrivalDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.returnTripArrivalDate : null, tab: 1 },
    { header: 'Trip Duration', accessorKey: 'returnTripTravelDuration', type: 'number', required: false, value: details && details.internServices && (details.internServices.returnTripDepartureDate && details.internServices.returnTripArrivalDate) ? calculateTripDuration(details.internServices.returnTripDepartureDate, details.internServices.returnTripArrivalDate, details.internServices.returnTripTravelDuration) : 0, tab: 1 },
    { header: 'Special Needs', accessorKey: 'returnTripSpecialNeeds', type: 'notes', required: false, value: details && details.internServices ? details.internServices.returnTripSpecialNeeds : '', tab: 1 },
    { header: 'Notes', accessorKey: 'returnTripNotes', type: 'notes', required: false, value: details && details.internServices ? details.internServices.returnTripNotes : '', tab: 1 },
    { header: 'Air Transport', accessorKey: 'returnTripAirTransport', type: 'checkbox', required: false,  value: details.internServices ? details.internServices.returnTripAirTransport : false, tab: 2 },
    { header: 'Departure Date', accessorKey: 'returnTripAirTransportDepartureDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.returnTripAirTransportDepartureDate : null, tab: 2 },
    { header: 'Arrival Date', accessorKey: 'returnTripAirTransportArrivalDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.returnTripAirTransportArrivalDate : null, tab: 2 },
    { dividerLine: true, tab: 2 },
    { header: 'Hotel Booking', accessorKey: 'returnTripHotelBooking', type: 'checkbox', required: false,  value: details.internServices ? details.internServices.returnTripHotelBooking : false, tab: 2 },
    { header: 'Hotel Name', accessorKey: 'returnTripHotelName', type: 'text', required: false,  value: details.internServices ? details.internServices.returnTripHotelName : '', tab: 2 },
    { header: 'Hotel Address', accessorKey: 'returnTripHotelAddress', type: 'text', required: false,  value: details.internServices ? details.internServices.returnTripHotelAddress : '', tab: 2 },
    { header: 'Hotel Check-In Date', accessorKey: 'returnTripHotelCheckIn', type: 'date', required: false, value: details && details.internServices ? details.internServices.returnTripHotelCheckIn : null, tab: 2 },
    { header: 'Hotel Check-Out Date', accessorKey: 'returnTripHotelCheckOut', type: 'date', required: false, value: details && details.internServices ? details.internServices.returnTripHotelCheckOut : null, tab: 2 },
    { header: 'Duration Of Stay', accessorKey: 'returnTripHotelDurationOfStay', type: 'number', required: false, value: details && details.internServices && (details.internServices.returnTripHotelCheckIn && details.internServices.returnTripHotelCheckOut) ? calculateTripDuration(details.internServices.returnTripHotelCheckIn, details.internServices.returnTripHotelCheckOut, details.internServices.returnTripHotelDurationOfStay) : 0, tab: 2 },
    { dividerLine: true, tab: 2 },
    { header: 'Rental Car', accessorKey: 'returnTripRentalCarBooking', type: 'checkbox', required: false,  value: details.internServices ? details.internServices.returnTripRentalCarBooking : false, tab: 2 },
    { header: 'Rental Car Year', accessorKey: 'returnTripRentalCarYear', type: 'text', required: false,  value: details.internServices ? details.internServices.returnTripRentalCarYear : '', tab: 2 },
    { header: 'Rental Car Make', accessorKey: 'returnTripRentalCarMake', type: 'text', required: false,  value: details.internServices ? details.internServices.returnTripRentalCarMake : '', tab: 2 },
    { header: 'Rental Car Model', accessorKey: 'returnTripRentalCarModel', type: 'text', required: false,  value: details.internServices ? details.internServices.returnTripRentalCarModel : '', tab: 2 },
    { header: 'Rental Car Start Date', accessorKey: 'returnTripRentalCarStartDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.returnTripRentalCarStartDate : null, tab: 2 },
    { header: 'Rental Car End Date', accessorKey: 'returnTripRentalCarEndDate', type: 'date', required: false, value: details && details.internServices ? details.internServices.returnTripRentalCarEndDate : null, tab: 2 },
    { header: 'Rental Duration', accessorKey: 'returnTripRentalCarDuration', type: 'number', required: false, value: details && details.internServices && (details.internServices.returnTripRentalCarStartDate && details.internServices.returnTripRentalCarEndDate) ? calculateTripDuration(details.internServices.returnTripRentalCarStartDate, details.internServices.returnTripRentalCarEndDate, details.internServices.returnTripRentalCarDuration) : 0, tab: 2 },
  ];

  const updateInternOverview = async (tab, values) => {
    setIsLoading(true);
    let newVals = { ...details.internServices };
    newVals.authorizationId = props.id;

    newVals.status = values.status;
    newVals.initiationDate = values.initiationDate;
    newVals.interviewStartDate = values.interviewStartDate;
    newVals.interviewEndDate = values.interviewEndDate;
    newVals.areaTour = values.areaTour;
    newVals.areaTourInitiatedDate = values.areaTourInitiatedDate;
    newVals.areaTourCanceledDate = values.areaTourCanceledDate;
    newVals.areaTourCompletedDate = values.areaTourCompletedDate;
    newVals.buyerBrokerAgentId = values.buyerBrokerAgentId;
    newVals.buyerBrokerAgentName = values.buyerBrokerAgentName;
        
    if (details.internServices && details.internServices.length !== 0) {
      const resp = await props.updateInternServices(newVals);
      if (resp.type === UPDATE_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getInternServices(props.id);
        if (getResp.type === GET_INTERN_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postInternServices(values);
      if (resp.type === POST_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to update intern services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const updateTripToFields = async (tab, values) => {
    setIsLoading(true);
    let newVals = { ...details.internServices };
    newVals.authorizationId = props.id;

    newVals.tripToDepartureDate = values.tripToDepartureDate;
    newVals.tripToArrivalDate = values.tripToArrivalDate;
    newVals.tripToTravelDuration = values.tripToTravelDuration;
    newVals.tripToSpecialNeeds = values.tripToSpecialNeeds;
    newVals.tripToNotes = values.tripToNotes;
    newVals.tripToAirTransport = values.tripToAirTransport;
    newVals.tripToDepartureDate = values.tripToDepartureDate;
    newVals.tripToHotelBooking = values.tripToHotelBooking;
    newVals.tripToHotelName = values.tripToHotelName;
    newVals.tripToHotelAddress = values.tripToHotelAddress;
    newVals.tripToHotelCheckIn = values.tripToHotelCheckIn;
    newVals.tripToHotelCheckOut = values.tripToHotelCheckOut;
    newVals.tripToHotelDurationOfStay = values.tripToHotelDurationOfStay;
    newVals.tripToRentalCarBooking = values.tripToRentalCarBooking;
    newVals.tripToRentalCarYear = values.tripToRentalCarYear;
    newVals.tripToRentalCarMake = values.tripToRentalCarMake;
    newVals.tripToRentalCarModel = values.tripToRentalCarModel;
    newVals.tripToRentalCarStartDate = values.tripToRentalCarStartDate;
    newVals.tripToRentalCarEndDate = values.tripToRentalCarEndDate;
    newVals.tripToRentalCarDuration = values.tripToRentalCarDuration;
        
    if (details.internServices && details.internServices.length !== 0) {
      const resp = await props.updateInternServices(newVals);
      if (resp.type === UPDATE_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getInternServices(props.id);
        if (getResp.type === GET_INTERN_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postInternServices(values);
      if (resp.type === POST_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to update intern services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const updateReturnTripFields = async (tab, values) => {
    setIsLoading(true);
    let newVals = { ...details.internServices };
    newVals.authorizationId = props.id;

    newVals.returnTripDepartureDate = values.returnTripDepartureDate;
    newVals.returnTripArrivalDate = values.returnTripArrivalDate;
    newVals.returnTripTravelDuration = values.returnTripTravelDuration;
    newVals.returnTripSpecialNeeds = values.returnTripSpecialNeeds;
    newVals.returnTripNotes = values.returnTripNotes;
    newVals.returnTripAirTransport = values.returnTripAirTransport;
    newVals.returnTripDepartureDate = values.returnTripDepartureDate;
    newVals.returnTripHotelBooking = values.returnTripHotelBooking;
    newVals.returnTripHotelName = values.returnTripHotelName;
    newVals.returnTripHotelAddress = values.returnTripHotelAddress;
    newVals.returnTripHotelCheckIn = values.returnTripHotelCheckIn;
    newVals.returnTripHotelCheckOut = values.returnTripHotelCheckOut;
    newVals.returnTripHotelDurationOfStay = values.returnTripHotelDurationOfStay;
    newVals.returnTripRentalCarBooking = values.returnTripRentalCarBooking;
    newVals.returnTripRentalCarYear = values.returnTripRentalCarYear;
    newVals.returnTripRentalCarMake = values.returnTripRentalCarMake;
    newVals.returnTripRentalCarModel = values.returnTripRentalCarModel;
    newVals.returnTripRentalCarStartDate = values.returnTripRentalCarStartDate;
    newVals.returnTripRentalCarEndDate = values.returnTripRentalCarEndDate;
    newVals.returnTripRentalCarDuration = values.returnTripRentalCarDuration;
        
    if (details.internServices && details.internServices.length !== 0) {
      const resp = await props.updateInternServices(newVals);
      if (resp.type === UPDATE_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getInternServices(props.id);
        if (getResp.type === GET_INTERN_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postInternServices(values);
      if (resp.type === POST_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to update intern services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };

  const cancelService = async () => {
    setIsLoading(true);
    setCurrentStatus('Canceled');
    let newVals = { ...details.candidateServices };
    newVals.authorizationId = props.id;
    newVals.status = currentStatus;

    if (details.candidateServices && details.candidateServices.length !== 0) {
      const resp = await props.updateCandidateServices(newVals);
      if (resp.type === UPDATE_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getCandidateServices(props.id);
        if (getResp.type === GET_INTERN_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    }
  };

  const completeService = async () => {
    setIsLoading(true);
    setCurrentStatus('Complete');
    let newVals = { ...details.candidateServices };
    newVals.authorizationId = props.id;
    newVals.status = currentStatus;

    if (details.candidateServices && details.candidateServices.length !== 0) {
      const resp = await props.updateCandidateServices(newVals);
      if (resp.type === UPDATE_INTERN_SERVICES_FAILURE) {
        props.showToast('Failed to update the candidate services info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        const getResp = await props.getCandidateServices(props.id);
        if (getResp.type === GET_INTERN_SERVICES_FAILURE) {
          return false;
        } else {
          setIsLoading(false);
          return true; 
        }
      }
    }
  };

  return (
    <Box >
      { details && details.summary && (details.summary.moveType === 'Intern') &&
        <>
          <Box sx={{ padding: '20px' }}>
            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 1, md: 1 }} >
              <Paper square className={classNames('position-fixed', props.classes.headerFixed, props.classes.container)} sx={{
                position: 'sticky',
                paddingRight: '10px',
                paddingLeft: '10px',
                marginBottom: '15px',
                minWidth: '100%',
              }}
              >
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px', marginBottom: '10px' }}>
                  <Box />
                  <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                    <Box>
                      <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                          Move Type
                        </Typography>
                      </Box>
                      <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                          {details.summary.moveType}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                          {details.summary.moveType ? details.summary.moveType : ''} Status
                        </Typography>
                      </Box>
                      <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                          {currentStatus}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                          Policy
                        </Typography>
                      </Box>
                      <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                        <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                          {details.authClientPolicies?.clientPolicyCode}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                  { location && !location.pathname.includes('expenses') &&
                  <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                      <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }} onClick={cancelService}> Cancel Service </Button>
                      <Button color="secondary"variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: '#f0b11d', '&:hover': { backgroundColor: '#ba8a00' } }} > Set On Hold </Button>
                      <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={completeService} > Complete Service </Button>
                    </Box>
                  </>
                  }
                </Box>
              </Paper>
            </Grid>
            <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
              <Grid item xs={12} xl={8} rowSpacing={2}>
                <Grid item sx={{ marginBottom: '10px' }}>
                  <EditableCard
                    tabs={internOverviewCardTabs}
                    summaries={internOverviewSummaries}
                    numColumns={5} 
                    updateFields={updateInternOverview}
                    isLoading={isLoading}
                    hideEdit={location.pathname.includes('expenses')}
                  />
                </Grid>
                <Grid item sx={{ marginBottom: '10px' }}>
                  <EditableCard
                    tabs={internTripToCardTabs}
                    summaries={tripToSummaries}
                    numColumns={5} 
                    updateFields={updateTripToFields}
                    isLoading={isLoading}
                    hideEdit={location.pathname.includes('expenses')}
                  />
                </Grid>
                <Grid item sx={{ marginBottom: '10px' }}>
                  <EditableCard
                    tabs={internReturnTripCardTabs}
                    summaries={returnTripSummaries}
                    numColumns={5} 
                    updateFields={updateReturnTripFields}
                    isLoading={isLoading}
                    hideEdit={location.pathname.includes('expenses')}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} xl={4} rowSpacing={2}>
                <Grid item sx={{ marginBottom: '10px' }}>
                  <SummaryCard dateSummary={details.dateSummary} id={props.id} hideEdit={true} />
                </Grid>
                <Grid item sx={{ marginBottom: '10px' }}>
                  <CostEstimatesCard costEstimate={details.costEstimate} hideEdit={location.pathname.includes('expenses')} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      }
        
    </Box>
  );

};

InternServices.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getAuthDateSummary,
    getBrokerAgents,
    setAuthId,
    setLoadingComplete,
    getBrokerAgentsById,
    getInternServices,
    updateInternServices,
    postInternServices,
  }),
)(InternServices);