import  { Box, Checkbox, FormControlLabel, Grid, MenuItem, Paper, Typography, withStyles } from '@material-ui/core';

import { Field } from 'formik';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { isEqual, values } from 'lodash';
import PropTypes from 'prop-types';
import PropertyAddressForm from './propertyAddressForm.component';
import React, { Component } from 'react';
import classNames from 'classnames';

import { CandidateInternBenefits, HhgBenefits, isCandidateMoveType, isInternMoveType } from 'modules/intake/models';
import { Checkbox as CheckboxCustom, Select, Switch, TextField } from 'modules/intake/form';
import { NO_SELF_SERVICE_POLICY } from 'modules/common/constants';
import ConfirmationDialog from 'modules/common/confirmationDialog.component';
import Log from 'utilities/log';

import { SET_EE_INTAKE_SUCCESS } from 'modules/intake/types';
import { setEEIntakeStatus } from 'modules/intake/intake.actions';

import { TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import { showToast } from 'modules/layout/layout.actions';
import { isExtendedBuinessTravelMoveType, isLongTermMoveType, isRepatriationMoveType, isShortTermMoveType } from 'modules/intake/models/authorizationInformation';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect:{
    minWidth: 300,
  },
});

class AuthorizationInformationForm extends Component {

  state = {
    showMoveTypeConfirmation: false,
    newMoveTypeId: null,
  };

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(this.props.authorizationInformation, nextProps.authorizationInformation)
      || !isEqual(this.props.primaryInformation, nextProps.primaryInformation)
      || !isEqual(this.props.departureProperty, nextProps.departureProperty)
      || !isEqual(this.props.selfServicePolicySummaries, nextProps.selfServicePolicySummaries)
      || !isEqual(this.props.intakeRecord, nextProps.intakeRecord)
      || this.state.showMoveTypeConfirmation !== nextState.showMoveTypeConfirmation;
  }

  handleChangeMoveType = () => {
    this.props.setFieldValue('benefits.candidateIntern', new CandidateInternBenefits(), false);
    this.props.setFieldValue('benefits.householdGoods', new HhgBenefits({}, {} ), false);
    this.props.setFieldValue('authorizationInformation.moveTypeId', this.state.newMoveTypeId, false);

    if (this.state.newMoveTypeId === 0 || this.state.newMoveTypeId === 7) {
      this.props.setFieldValue('hhgInformation.isHhgServiceInitiated', false, false);
      this.props.setFieldValue('temporaryLiving.isTemporaryLivingServiceInitiated', false, false);
      this.props.setFieldValue('newDestination.isDestinationServiceInitiated', false, false);
      this.props.setFieldValue('departureProperty.isRental', null, false);
    }

    this.setState({ showMoveTypeConfirmation: false });
  };

  handleSelfServicePolicyChange = (selfServicePolicyId) => {
    this.props.setFieldValue('authorizationInformation.selfServicePolicyId', selfServicePolicyId, false);
    const budget = selfServicePolicyId === NO_SELF_SERVICE_POLICY ? '' : this.getSelectedPolicyBudget(selfServicePolicyId);
    this.props.setFieldValue('authorizationInformation.selfServicePolicyBudget', budget, false);
  };

  getSelectedPolicyBudget = (selectedPolicyId) => {
    const selectedPolicy = this.props.selfServicePolicySummaries.find((pol) => pol.id === selectedPolicyId);
    if (selectedPolicy) {
      return selectedPolicy.mostRecentPublish.budget;
    }
  };

  getFilteredSelfServicePolicies = () => {
    const { authorizationInformation, primaryInformation, selfServicePolicySummaries } = this.props;

    const mtPolicy = authorizationInformation.client.relocationPolicyCodes.find((policy) => policy.id === primaryInformation.relocationPolicyCodeId);

    const filtered = selfServicePolicySummaries.filter((policy) => {
      if (!policy.mostRecentPublish) return false;
      const moveTrackPolicies = policy.mostRecentPublish.moveTrackPolicies;
      return moveTrackPolicies.some((mtPol) => mtPol.name === mtPolicy.policyCode) && policy.enabled;
    });

    return filtered;
  };

  getSelfServiceMenuItems = (filteredPolices) => {
    const menuItems = [
      <MenuItem key={NO_SELF_SERVICE_POLICY} value={NO_SELF_SERVICE_POLICY}>None</MenuItem>,
    ];
    menuItems.push(filteredPolices.map((policy) => {
      return (
        <MenuItem key={policy.id} value={policy.id}>{policy.mostRecentPublish.name}</MenuItem>
      );
    }));
        
    return menuItems;
  }

  hasStartAndEndDateMoveType = (moveTypeId, moveTypes) => {
    if (
      isExtendedBuinessTravelMoveType(moveTypeId, moveTypes) ||
      isRepatriationMoveType(moveTypeId, moveTypes) ||
      isShortTermMoveType(moveTypeId, moveTypes) ||
      isLongTermMoveType(moveTypeId, moveTypes) ||
      isInternMoveType(moveTypeId, moveTypes)
    ) {
      return true;
    }
    return false;
    
  }

  getDisabledSelfServicePolicyMessage = (filteredPolices) => {
    const { authorizationInformation, selfServicePolicySummaries } = this.props;

    const selectedPolicy = selfServicePolicySummaries.find((policy) => policy.id === authorizationInformation.selfServicePolicyId);
    if (selectedPolicy && !filteredPolices.some((ssPolicy) => ssPolicy.id === selectedPolicy.id)){
      return ( <Typography variant="caption" color="error">The selected policy <Box component="span" fontWeight="fontWeightBold">{selectedPolicy.mostRecentPublish.name}</Box> is no longer valid.  Please choose another.</Typography>);
    }
    return null;
  }

  transfereeIntakeRequiredClicked = async (e) => {
    const resp = await this.props.setEEIntakeStatus(this.props.intakeRecord.id, e.target.checked);
    if (resp.type === SET_EE_INTAKE_SUCCESS) {
      this.props.showToast('Transferee Intake Form Status Changed Successfully.', { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
    }
  }

  render() {
    Log.trace('RENDER', 'AuthorizationInformationForm');
    const {
      classes,
      authorizationInformation,
      departureProperty,
      handleChange,
      handleBlur,
      metadata,
      intakeRecord,
      primaryInformation
    } = this.props;

    const { showMoveTypeConfirmation } = this.state;

    const selfServicePolicies = this.getFilteredSelfServicePolicies();

    // const isCandidateOrIntern = (isCandidateMoveType(this.props.authorizationInformation.moveTypeId, this.props.metadata.moveTypes) || isInternMoveType(this.props.authorizationInformation.moveTypeId, this.props.metadata.moveTypes));

    const isCandidate = isCandidateMoveType(this.props.authorizationInformation.moveTypeId, this.props.metadata.moveTypes);

    return (
      <div className="form-group">
        <Typography gutterBottom variant="h3">Authorization Information</Typography>
        <Grid container item className={classNames('row', classes.section)}>
          <Field
            component={TextField}
            name="authorizationInformation.client.description"
            label="Client"
            value={authorizationInformation.client.description}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
          />
          <Field
            component={Select}
            name="authorizationInformation.moveTypeId"
            label="Move Type"
            className={classes.wideControl}
            value={authorizationInformation.moveTypeId}
            // onChange={handleChange}
            onChange={(event) => {
              if (event.target.value !== authorizationInformation.moveTypeId) {
                this.setState({ showMoveTypeConfirmation: true, newMoveTypeId: event.target.value });
              } else {
                handleChange(event);
              }
            }}
            onBlur={handleBlur}
            labelWidth={65}
          >
            {values(metadata.moveTypes).map((moveType) => (
              <MenuItem key={moveType.id} value={moveType.id}>{moveType.description}</MenuItem>
            ))}
          </Field>
        </Grid>

        <Grid container item className={classNames('row', classes.section)}>
          {intakeRecord.transferreeHasWebAccess && (!intakeRecord.eeIntakeForm || (intakeRecord.eeIntakeForm && !intakeRecord.eeIntakeForm.record.isSubmitted)) && (
            <FormControlLabel
              control={
                <Checkbox
                  checked={intakeRecord.transfereeIntakeRequired}
                  onChange={this.transfereeIntakeRequiredClicked}
                  //disabled={isReadOnly}
                />
              }
              label="Transferee Intake Form Required"
            />
          )}
          {intakeRecord.transfereeIntakeRequired && intakeRecord.eeIntakeForm && intakeRecord.eeIntakeForm.record.isSubmitted && (
            <h4>Transferee Intake Form Submitted</h4>
          )}
        </Grid>

        <Typography gutterBottom variant="h4">Self Service Policy</Typography>
        {this.getDisabledSelfServicePolicyMessage(selfServicePolicies)}
        <Grid container item className={classes.section}>
          <Field
            component={Select}
            name="authorizationInformation.selfServicePolicyId"
            label="Self Service Policy"
            className={classes.selfServicePolicySelect}
            value={authorizationInformation.selfServicePolicyId}
            onChange={(event) => {
              if (event.target.value !== authorizationInformation.selfServicePolicyId) {
                this.handleSelfServicePolicyChange(event.target.value);
              } else {
                handleChange(event);
              }
            }}
            onBlur={handleBlur}
          >
            {this.getSelfServiceMenuItems(selfServicePolicies)}
          </Field>
          <Field
            component={TextField}
            name="authorizationInformation.selfServicePolicyBudget"
            label="Self Service Budget"
            value={authorizationInformation.selfServicePolicyBudget}
            disabled={authorizationInformation.selfServicePolicyId === NO_SELF_SERVICE_POLICY}
            onChange={handleChange}
            onBlur={handleBlur}
            allowDecimal={true}
            numeric
          />
        </Grid>

        <Typography gutterBottom variant="h4">New Job Information</Typography>
        <Grid container item className={classes.section}>
          <Field
            component={TextField}
            name="authorizationInformation.authorizationDate"
            label="Authorization Received Date"
            value={authorizationInformation.authorizationDate}
            disabled={true}
            onChange={handleChange}
            onBlur={handleBlur}
            type="date"
          />
          <Field
            component={CheckboxCustom}
            name="authorizationInformation.vip"
            label="VIP"
            value={authorizationInformation.vip}
            onChange={handleChange}
            onBlur={handleBlur}
            alignCenter
          />
        </Grid>
        {this.hasStartAndEndDateMoveType(authorizationInformation.moveTypeId, metadata.moveTypes) ? (
          <Grid container item className={classes.section}>
            <Field
              component={TextField}
              name="primaryInformation.estimatedStartDate"
              label="Estimated Start Date"
              value={primaryInformation.estimatedStartDate}
              onChange={handleChange}
              onBlur={handleBlur}
              type="date"
            />
            <Field
              component={TextField}
              name="primaryInformation.estimatedEndDate"
              label="Estimated End Date"
              value={primaryInformation.estimatedEndDate}
              onChange={handleChange}
              onBlur={handleBlur}
              type="date"
            />
          </Grid>
        ) : null}
        <Grid container item>
          <Field
            component={TextField}
            name="authorizationInformation.newJobTitle"
            label="EE New Job Title"
            value={authorizationInformation.newJobTitle}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Grid>
        {intakeRecord.eeIntakeForm?.record.isSubmitted && intakeRecord.eeIntakeForm?.record.newJobTitle && (
          <Typography style={{ color:'green' }}>^ Sourced from EE</Typography>
        )}

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <PropertyAddressForm
              metadata={metadata}
              address={departureProperty.address}
              handleChange={handleChange}
              handleBlur={handleBlur}
              rootPath="departureProperty.address"
              eeIntakeForm={intakeRecord.eeIntakeForm}
            />
          </Grid>
        </Grid>
        { !isCandidate &&
          <>
            <Paper classes={{ root: 'card-root' }}>
              <Typography gutterBottom variant="h4">Repayment Agreement</Typography>
              <Grid container item>
                <Field
                  component={Switch}
                  name="authorizationInformation.repaymentAgreementRequired"
                  label="Repayment Required"
                  value={authorizationInformation.repaymentAgreementRequired}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  alignCenter
                />
                <Field
                  component={TextField}
                  name="authorizationInformation.repaymentAgreementSentDate"
                  label="Sent"
                  value={authorizationInformation.repaymentAgreementSentDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  disabled={!authorizationInformation.repaymentAgreementRequired}
                />
                <Field
                  component={TextField}
                  name="authorizationInformation.repaymentAgreementReceivedDate"
                  label="Received"
                  value={authorizationInformation.repaymentAgreementReceivedDate}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="date"
                  disabled={!authorizationInformation.repaymentAgreementRequired}
                />
              </Grid>
            </Paper>
          </>
        }
        
        <ConfirmationDialog
          open={showMoveTypeConfirmation}
          title="Change Move Type?"
          confirmLabel="Yes"
          cancelLabel="No"
          onConfirm={this.handleChangeMoveType}
          onCancel={() => this.setState({ showMoveTypeConfirmation: false })}
        >
          <Typography>
            Changing Move Type will cause previously entered benefits and timeline data to be deleted.{' '}
            <br />
            <br />
            Do you want to proceed?
          </Typography>
        </ConfirmationDialog>
      </div>
    );
  }
}

AuthorizationInformationForm.propTypes = {
  classes: PropTypes.object.isRequired,
  authorizationInformation: PropTypes.object.isRequired,
  primaryInformation: PropTypes.object.isRequired,
  departureProperty: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  metadata: PropTypes.object.isRequired,
  setEEIntakeStatus: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  selfServicePolicySummaries: PropTypes.array,
};

export default compose(
  withStyles(styles),
  connect(null, {
    setEEIntakeStatus,
    showToast,
  }),
)(AuthorizationInformationForm);
