/* eslint-disable array-callback-return */
export const checkDirty = (originalVals, newVals) => {
  let dirty = false;
  Object.keys(originalVals).map((fieldName) => {
    // Checks the datetime cases for equivalence which have inconsistent format
    if (typeof originalVals[fieldName] === 'string' && typeof newVals[fieldName] === 'string' && originalVals[fieldName].includes('T00:00:00') && !newVals[fieldName].includes('T00:00:00')) {
      if (originalVals[fieldName] !== `${newVals[fieldName]}T00:00:00`) dirty = true;
    } 
    // Checks the primary case where new and old values are different (ignores case where one is undefined and one is empty string)
    else if ((originalVals[fieldName] !== newVals[fieldName]) && !(originalVals[fieldName] && newVals[fieldName]?.length === 0) && fieldName !== 'authorizationId') {
      dirty = true;
    } 
  });
  return dirty;
};


// for Destination Services - set status types based on what service type is
export const setStatusTypes = (serviceType, status) => {

  let statusTypes = [];

  if (serviceType === 'Renter')
  {
    if (status === 'Canceled')
    {
      statusTypes = ['Initiated', 'In Progress', 'Rental Secured', 'Canceled'];
    }
    else {
      statusTypes = ['Initiated', 'In Progress', 'Rental Secured', 'Complete'];
    }
    return statusTypes;
  }
  else if (serviceType === 'Buyer')
  {
    if (status === 'Canceled')
    {
      statusTypes = ['Initiated', 'In Progress', 'Agent Selected', 'Under Contract', 'Canceled'];
    }
    else {
      statusTypes = ['Initiated', 'In Progress', 'Agent Selected', 'Under Contract', 'Complete'];
    }
    return statusTypes;
  }
  else
  {
    return ['Initiated'];
  }

};

// for candidate services - automatically calculate trip duration
export const calculateTripDuration = (startDay, endDay, tripDuration) => {
  if (tripDuration)
  {
    return tripDuration;
  }
  else {
    const newStartDate = new Date(startDay).getTime();
    const newEndDate = new Date(endDay).getTime();
  
    let msDay = 24 * 60 * 60 * 1000; // milliseconds per day
    return Math.floor((newEndDate - newStartDate) / msDay);
  }
};