/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
import { Button, Checkbox, FormControlLabel, FormHelperText, TextField, Typography, makeStyles } from '@material-ui/core';
import { GRANT_WEB_ACCESS_FAILURE, GRANT_WEB_ACCESS_SUCCESS, UPDATE_INTAKE_RECORD_FAILURE, UPDATE_INTAKE_RECORD_SUCCESS } from '../types';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from '../../layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { grantWebAccess, setEEIntakeStatus, updateIntakeRecord } from '../intake.actions';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Dialog from 'modules/common/dialog.component';
import Spinner from 'modules/common/spinner.component';

import { convertToDomesticPhoneNumber } from '../details/authorizationIntake/utils';

const useStyles = makeStyles((theme) => ({
  mobileNum: {
    marginRight: 10,
  },
  email: {
    minWidth: 250,
  },
}));

function WebAccessDialog(props) {
  const classes = useStyles(props);

  const {
    onSubmit,
    onCancel,
    maxLength,
    disabled,
    record,
    ...rest
  } = props;
  const [eeIntakeRequired, setEEIntakeRequired] = useState(false);
  const [mobileNumber, setMobileNumber] = useState('');
  const [primaryEmail, setPrimaryEmail] = useState('');
  const [noMobileNum, setNoMobileNum] = useState(false);
  const [grantingAccess, setGrantingAccess] = useState(false);

  const moveTypeExists = props.record && (props.record.moveTypeId || props.record.moveTypeId === 0);

  const handleSubmit = async () => {
    const record = props.record;

    setGrantingAccess(true);
    if (record) {
      let newRecord = { ...record };

      const originalMobile = newRecord.phoneNumbers.find((o) => o.phoneTypeId === 1);
      const originalEmail = newRecord.emailAddresses.find((o) => o.isPrimary === true);

      const diffMobile = originalMobile?.phoneNumber !== mobileNumber && mobileNumber?.length > 0;
      const diffEmail = originalEmail?.emailAddress !== primaryEmail && primaryEmail?.length > 0;
      newRecord.transfereeIntakeRequired = eeIntakeRequired;
      newRecord.noMobileNumber = noMobileNum;

      if (diffMobile) {
        if (originalMobile) {
          newRecord.phoneNumbers.find((o) => o.phoneTypeId === 1).phoneNumber = mobileNumber;
        } else {
          newRecord.phoneNumbers.push({
            phoneNumber: mobileNumber,
            phoneTypeId: 1,
            contactInfoOwnerTypeId: 1,
            isPrimary: false,
          });
        }
      }
            
      if (diffEmail) {
        if (originalEmail) {
          newRecord.emailAddresses.find((o) => o.isPrimary === true).emailAddress = primaryEmail;
        } else {
          newRecord.emailAddresses.push({
            emailAddress: primaryEmail,
            emailTypeId: 0,
            contactInfoOwnerTypeId: 1,
            isPrimary: true,
          });
        }
      }

      const webAccessReq = {
        Id: record.id,
        AuthorizationId: record.mtAuthorizationId,
        NoMobileNumber: noMobileNum,
        EmailAddress: primaryEmail,
        PhoneNumber: mobileNumber,
      };

      if (diffEmail || diffMobile) {
        // make the update call, wait on it then grantwebaccess
        const updateResponse = await props.updateIntakeRecord({ ...record, ...newRecord });
        if (updateResponse.type === UPDATE_INTAKE_RECORD_FAILURE) {
          props.showToast('Failed to save data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setGrantingAccess(false);
          return;
        } else if (updateResponse.type === UPDATE_INTAKE_RECORD_SUCCESS) {
          const resp = await props.grantWebAccess(webAccessReq);
          if (resp.type === GRANT_WEB_ACCESS_SUCCESS) {

            if (eeIntakeRequired) await props.setEEIntakeStatus(record.id, true);

            props.showToast(`Successfully granted web access for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
            onCancel();
          } else if (resp.type === GRANT_WEB_ACCESS_FAILURE) {
            props.showToast(`Failed to grant web access for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
            onCancel();
          }
        }
      } else {
        const resp = await props.grantWebAccess(webAccessReq);
        if (resp.type === GRANT_WEB_ACCESS_SUCCESS) {

          if (eeIntakeRequired) await props.setEEIntakeStatus(record.id, true);

          props.showToast(`Successfully granted web access for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_SUCCESS });
          onCancel();
        } else if (resp.type === GRANT_WEB_ACCESS_FAILURE) {
          props.showToast(`Failed to grant web access for authorization: ${record.mtAuthorizationId}`, { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          onCancel();
        }
      }
    }
    setGrantingAccess(false);
  };

  useEffect(() => {
    if (props.record) {
      const number = props.record.phoneNumbers?.find((o) => o.phoneTypeId === 1)?.phoneNumber;
      const email = props.record.emailAddresses?.find((o) => o.isPrimary === true)?.emailAddress;
      if (number) setMobileNumber(convertToDomesticPhoneNumber(number));
      if (email) setPrimaryEmail(email);
    }
  }, [props.record]);

  return (
    <Dialog
      {...rest}
      title="Grant Web Access"
      content={
        <div>
          {props.record && (
            <div>
              <Typography>{`${props.record.contacts[0].firstName} ${props.record.contacts[0].lastName}`}</Typography>
              <Typography>{props.record.client.description}</Typography>
              <div>
                <TextField
                  type="text"
                  name="mobileNumber"
                  label="Mobile Number"
                  variant="outlined"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(convertToDomesticPhoneNumber(e.target.value))}
                  className={classes.mobileNum}
                  disabled={noMobileNum}
                />
                <TextField
                  type="text"
                  name="emailAddress"
                  label="Email Address"
                  variant="outlined"
                  value={primaryEmail}
                  onChange={(e) => setPrimaryEmail(e.target.value)}
                  className={classes.email}
                />
              </div>
              <FormControlLabel
                control={
                  <Checkbox
                    name="confirmNoMobileNum"
                    label="Confirm No Mobile Number"
                    value={noMobileNum}
                    onChange={(e) => setNoMobileNum(!noMobileNum)}
                  />
                }
                label="Confirm No Mobile Number"
                classes={{ root: classes.checkbox }}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    name="eeIntakeRequired"
                    value={eeIntakeRequired}
                    onChange={(e) => setEEIntakeRequired(!eeIntakeRequired)}
                  />
                }
                label="Transferee Intake Form Required"
              />
            </div>
          )}
          {(!props.record || grantingAccess) && (
            <Spinner />
          )}
          {(!moveTypeExists) && <FormHelperText error>You must set the MoveType before enabling Web Access for this authorization.</FormHelperText>}
        </div>
      }
      actions={
        <>
          <Button disabled={disabled} onClick={onCancel} variant="outlined" color="primary">Cancel</Button>
          <Button disabled={!props.record || (!moveTypeExists) || grantingAccess} onClick={handleSubmit} variant="contained" color="primary">Grant Access</Button>
        </>
      }
    />
  );
}

WebAccessDialog.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  maxLength: PropTypes.number,
  disabled: PropTypes.bool,
  record: PropTypes.object,
};

export default compose(
  connect(null, {
    grantWebAccess,
    setEEIntakeStatus,
    showToast,
    updateIntakeRecord,
  }),
)(WebAccessDialog);