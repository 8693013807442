/* eslint-disable react-hooks/exhaustive-deps */

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_SEGMENT_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgSegment } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import React, { useEffect, useState } from 'react';

import {
  Box, Button, Card, CardContent, Dialog, DialogActions, Fab, Grid, Stack, TextField, Tooltip, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import Spinner from 'modules/common/spinner.component';

import { formatDateForInput } from 'utilities/common';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));


const EditCardModal = (props) => {
  const { open, onSubmit, isLoading, onClose, segmentInfo } = props;
  const classes = useStyles();

  const [estimatedPackStart, setEstimatedPackStart] = useState(segmentInfo ? segmentInfo.estimatedPackStart : '');
  const [estimatedPackEnd, setEstimatedPackEnd] = useState(segmentInfo ? segmentInfo.estimatedPackEnd : '');
  const [actualPackStart, setActualPackStart] = useState(segmentInfo ? segmentInfo.actualPackStart : '');
  const [actualPackEnd, setActualPackEnd] = useState(segmentInfo ? segmentInfo.actualPackEnd : '');
  const [estimatedLoadStart, setEstimatedLoadStart] = useState(segmentInfo ? segmentInfo.estimatedLoadStart : '');
  const [estimatedLoadEnd, setEstimatedLoadEnd] = useState(segmentInfo ? segmentInfo.estimatedLoadEnd : '');
  const [actualLoadStart, setActualLoadStart] = useState(segmentInfo ? segmentInfo.actualLoadStart : '');
  const [actualLoadEnd, setActualLoadEnd] = useState(segmentInfo ? segmentInfo.actualLoadEnd : '');
  const [estimatedDeliveryStart, setEstimatedDeliveryStart] = useState(segmentInfo ? segmentInfo.estimatedDeliveryStart : '');
  const [estimatedDeliveryEnd, setEstimatedDeliveryEnd] = useState(segmentInfo ? segmentInfo.estimatedDeliveryEnd : '');
  const [actualDeliveryStart, setActualDeliveryStart] = useState(segmentInfo ? segmentInfo.actualDeliveryStart : '');
  const [actualDeliveryEnd, setActualDeliveryEnd] = useState(segmentInfo ? segmentInfo.actualDeliveryEnd : '');
  const [estimatedCarLoadingStart, setEstimatedCarLoadingStart] = useState(segmentInfo ? segmentInfo.estimatedCarLoadingStart : '');
  const [estimatedCarLoadingEnd, setEstimatedCarLoadingEnd] = useState(segmentInfo ? segmentInfo.estimatedCarLoadingEnd : '');
  const [actualCarLoadingStart, setActualCarLoadingStart] = useState(segmentInfo ? segmentInfo.actualCarLoadingStart : '');
  const [actualCarLoadingEnd, setActualCarLoadingEnd] = useState(segmentInfo ? segmentInfo.actualCarLoadingEnd : '');
  const [estimatedCarDeliveryStart, setEstimatedCarDeliveryStart] = useState(segmentInfo ? segmentInfo.estimatedCarDeliveryStart : '');
  const [estimatedCarDeliveryEnd, setEstimatedCarDeliveryEnd] = useState(segmentInfo ? segmentInfo.estimatedCarDeliveryEnd : '');
  const [actualCarDeliveryStart, setActualCarDeliveryStart] = useState(segmentInfo ? segmentInfo.actualCarDeliveryStart : '');
  const [actualCarDeliveryEnd, setActualCarDeliveryEnd] = useState(segmentInfo ? segmentInfo.actualCarDeliveryEnd : '');

  const [isDirty, setIsDirty] = useState(false);

  const onSubmitModal = (e) => {
    e.preventDefault();

    const values = {
      estimatedPackStart,
      estimatedPackEnd,
      actualPackStart,
      actualPackEnd,
      estimatedLoadStart,
      estimatedLoadEnd,
      actualLoadStart,
      actualLoadEnd,
      estimatedDeliveryStart,
      estimatedDeliveryEnd,
      actualDeliveryStart,
      actualDeliveryEnd,
      estimatedCarLoadingStart,
      estimatedCarLoadingEnd,
      actualCarLoadingStart,
      actualCarLoadingEnd,
      estimatedCarDeliveryStart,
      estimatedCarDeliveryEnd,
      actualCarDeliveryStart,
      actualCarDeliveryEnd,
    };

    onSubmit(values);
  };

  useEffect(() => {
    if (segmentInfo) {
      if ((!(estimatedPackStart === '' && segmentInfo.estimatedPackStart == null) && estimatedPackStart !== segmentInfo.estimatedPackStart) || (!(estimatedPackEnd === '' && segmentInfo.estimatedPackEnd == null) && estimatedPackEnd !== segmentInfo.estimatedPackEnd) || (!(actualPackStart === '' && segmentInfo.actualPackStart == null) && actualPackStart !== segmentInfo.actualPackStart) || (!(actualPackEnd === '' && segmentInfo.actualPackEnd == null) && actualPackEnd !== segmentInfo.actualPackEnd) || 
            (!(estimatedLoadStart === '' && segmentInfo.estimatedLoadStart == null) && estimatedLoadStart !== segmentInfo.estimatedLoadStart) || (!(estimatedLoadEnd === '' && segmentInfo.estimatedLoadEnd == null) && estimatedLoadEnd !== segmentInfo.estimatedLoadEnd) || (!(actualLoadStart === '' && segmentInfo.actualLoadStart == null) && actualLoadStart !== segmentInfo.actualLoadStart) || (!(actualLoadEnd === '' && segmentInfo.actualLoadEnd == null) && actualLoadEnd !== segmentInfo.actualLoadEnd) || 
            (!(estimatedDeliveryStart === '' && segmentInfo.estimatedDeliveryStart == null) && estimatedDeliveryStart !== segmentInfo.estimatedDeliveryStart) || (!(estimatedDeliveryEnd === '' && segmentInfo.estimatedDeliveryEnd == null) && estimatedDeliveryEnd !== segmentInfo.estimatedDeliveryEnd) || (!(actualDeliveryStart === '' && segmentInfo.actualDeliveryStart == null) && actualDeliveryStart !== segmentInfo.actualDeliveryStart) || (!(actualDeliveryEnd === '' && segmentInfo.actualDeliveryEnd == null) && actualDeliveryEnd !== segmentInfo.actualDeliveryEnd) || 
            (!(estimatedCarLoadingStart === '' && segmentInfo.estimatedCarLoadingStart == null) && estimatedCarLoadingStart !== segmentInfo.estimatedCarLoadingStart) || (!(estimatedCarLoadingEnd === '' && segmentInfo.estimatedCarLoadingEnd == null) && estimatedCarLoadingEnd !== segmentInfo.estimatedCarLoadingEnd) || (!(actualCarLoadingStart === '' && segmentInfo.actualCarLoadingStart == null) && actualCarLoadingStart !== segmentInfo.actualCarLoadingStart) || (!(actualCarLoadingEnd === '' && segmentInfo.actualCarLoadingEnd == null) && actualCarLoadingEnd !== segmentInfo.actualCarLoadingEnd) ||
            (!(estimatedCarDeliveryStart === '' && segmentInfo.estimatedCarDeliveryStart == null) && estimatedCarDeliveryStart !== segmentInfo.estimatedCarDeliveryStart) || (!(estimatedCarDeliveryEnd === '' && segmentInfo.estimatedCarDeliveryEnd == null) && estimatedCarDeliveryEnd !== segmentInfo.estimatedCarDeliveryEnd) || (!(actualCarDeliveryStart === '' && segmentInfo.actualCarDeliveryStart == null) && actualCarDeliveryStart !== segmentInfo.actualCarDeliveryStart) || (!(actualCarDeliveryEnd === '' && segmentInfo.actualCarDeliveryEnd == null) && actualCarDeliveryEnd !== segmentInfo.actualCarDeliveryEnd)
      ) {
        setIsDirty(true);
      }
    }

  }, [estimatedPackStart, estimatedPackEnd, actualPackStart, actualPackEnd, estimatedLoadStart, estimatedLoadEnd, actualLoadStart, actualLoadEnd, estimatedDeliveryStart, actualCarDeliveryStart, actualCarDeliveryEnd,
    estimatedDeliveryEnd, actualDeliveryStart, actualDeliveryEnd, estimatedCarLoadingStart, estimatedCarLoadingEnd, actualCarLoadingStart, actualCarLoadingEnd, estimatedCarDeliveryStart, estimatedCarDeliveryEnd]);

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={onSubmitModal} fullScreen>
        <div className="row justify-space-between align-center">
          <div className={classes.dialogIconRow}>
            <Tooltip title="Move Dates" key="Move Dates" placement="right">
              <FontAwesomeIcon icon={['fas', 'money-check-dollar']} size="3x" />
            </Tooltip>
            <Typography variant="h5" sx={{ paddingLeft: '20px' }}>
              Move Dates
            </Typography>
          </div>
        </div>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <Grid container spacing={2}>
              <Grid item md="4" />
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated End</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual End</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Pack</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedPackStart'}
                    label={''}
                    name={'estimatedPackStart'}
                    value={formatDateForInput(estimatedPackStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedPackStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedPackEnd'}
                    label={''}
                    name={'estimatedPackEnd'}
                    value={formatDateForInput(estimatedPackEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedPackEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualPackStart'}
                    label={''}
                    name={'actualPackStart'}
                    value={formatDateForInput(actualPackStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualPackStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualPackEnd'}
                    label={''}
                    name={'actualPackEnd'}
                    value={formatDateForInput(actualPackEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualPackEnd(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Load</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedLoadStart'}
                    label={''}
                    name={'estimatedLoadStart'}
                    value={formatDateForInput(estimatedLoadStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedLoadStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedLoadEnd'}
                    label={''}
                    name={'estimatedLoadEnd'}
                    value={formatDateForInput(estimatedLoadEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedLoadEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualLoadStart'}
                    label={''}
                    name={'actualLoadStart'}
                    value={formatDateForInput(actualLoadStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualLoadStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualLoadEnd'}
                    label={''}
                    name={'actualLoadEnd'}
                    value={formatDateForInput(actualLoadEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualLoadEnd(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Delivery</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedDeliveryStart'}
                    label={''}
                    name={'estimatedDeliveryStart'}
                    value={formatDateForInput(estimatedDeliveryStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedDeliveryStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedDeliveryEnd'}
                    label={''}
                    name={'estimatedDeliveryEnd'}
                    value={formatDateForInput(estimatedDeliveryEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedDeliveryEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualDeliveryStart'}
                    label={''}
                    name={'actualDeliveryStart'}
                    value={formatDateForInput(actualDeliveryStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualDeliveryStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualDeliveryEnd'}
                    label={''}
                    name={'actualDeliveryEnd'}
                    value={formatDateForInput(actualDeliveryEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualDeliveryEnd(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Loading</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarLoadingStart'}
                    label={''}
                    name={'estimatedCarLoadingStart'}
                    value={formatDateForInput(estimatedCarLoadingStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarLoadingStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarLoadingEnd'}
                    label={''}
                    name={'estimatedCarLoadingEnd'}
                    value={formatDateForInput(estimatedCarLoadingEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarLoadingEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarLoadingStart'}
                    label={''}
                    name={'actualCarLoadingStart'}
                    value={formatDateForInput(actualCarLoadingStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarLoadingStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarLoadingEnd'}
                    label={''}
                    name={'actualCarLoadingEnd'}
                    value={formatDateForInput(actualCarLoadingEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarLoadingEnd(e.target.value)}
                  />
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Delivery</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarDeliveryStart'}
                    label={''}
                    name={'estimatedCarDeliveryStart'}
                    value={formatDateForInput(estimatedCarDeliveryStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarDeliveryStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'estimatedCarDeliveryEnd'}
                    label={''}
                    name={'estimatedCarDeliveryEnd'}
                    value={formatDateForInput(estimatedCarDeliveryEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setEstimatedCarDeliveryEnd(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarDeliveryStart'}
                    label={''}
                    name={'actualCarDeliveryStart'}
                    value={formatDateForInput(actualCarDeliveryStart)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarDeliveryStart(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <TextField
                    key={'actualCarDeliveryEnd'}
                    label={''}
                    name={'actualCarDeliveryEnd'}
                    value={formatDateForInput(actualCarDeliveryEnd)}
                    margin="dense"
                    sx={{ maxWidth: '360px' }}
                    variant="standard"
                    type="date"
                    onChange={(e) => setActualCarDeliveryEnd(e.target.value)}
                  />
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="primary" type="submit" variant="contained" disabled={!isDirty}>Save</Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

const ShipmentMoveDatesCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);
  const [editingModalOpen, setEditingModalOpen] = useState(false);

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const { segmentInfo } = props;

  const updateFields = async (values) => {
    setIsLoading(true);

    let newVals = { ...segmentInfo };
    
    newVals.estimatedPackStart = values.estimatedPackStart;
    newVals.estimatedPackEnd= values.estimatedPackEnd;
    newVals.actualPackStart= values.actualPackStart;
    newVals.actualPackEnd= values.actualPackEnd;
    newVals.estimatedLoadStart= values.estimatedLoadStart;
    newVals.estimatedLoadEnd= values.estimatedLoadEnd;
    newVals.actualLoadStart= values.actualLoadStart;
    newVals.actualLoadEnd= values.actualLoadEnd;
    newVals.estimatedDeliveryStart= values.estimatedDeliveryStart;
    newVals.estimatedDeliveryEnd= values.estimatedDeliveryEnd;
    newVals.actualDeliveryStart= values.actualDeliveryStart;
    newVals.actualDeliveryEnd= values.actualDeliveryEnd;
    newVals.estimatedCarLoadingStart= values.estimatedCarLoadingStart;
    newVals.estimatedCarLoadingEnd= values.estimatedCarLoadingEnd;
    newVals.actualCarLoadingStart= values.actualCarLoadingStart;
    newVals.actualCarLoadingEnd= values.actualCarLoadingEnd;
    newVals.estimatedCarDeliveryStart= values.estimatedCarDeliveryStart;
    newVals.estimatedCarDeliveryEnd= values.estimatedCarDeliveryEnd;
    newVals.actualCarDeliveryStart= values.actualCarDeliveryStart;
    newVals.actualCarDeliveryEnd= values.actualCarDeliveryEnd;

    const resp = await props.updateHhgSegment(newVals);
    if (resp.type === UPDATE_HHG_SEGMENT_FAILURE) {
      props.showToast('Failed to update the moving dates segment info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };

  const handleSaveEdits = async (values) => {
    // Send update call to parent to update and refresh values.
    const resp = await updateFields(values);
    if (!resp) {
      showToast('Failed to update moving dates data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      return;
    } 
    setEditingModalOpen(false); //required to exit editing mode and close modal
  };
    
  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Estimates" key="Estimates" placement="right">
                  <FontAwesomeIcon icon={['fas', 'calendar-days']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Moving Dates
                </Typography>
              </div>
              { !props.hideEdit && 
              <>
                <Fab className={classes.iconOverlayImg} color="primary" size="small" onClick={() => setEditingModalOpen(true)}>
                  <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={theme.palette.common.white} />
                </Fab>
              </>
              }
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '30px' }}>
            <Grid container spacing={2}>
              <Grid item md="4" />
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Estimated End</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual Start</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>Actual End</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Pack</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.estimatedPackStart) ? new Date(segmentInfo.estimatedPackStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.estimatedPackEnd) ? new Date(segmentInfo.estimatedPackEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.actualPackStart) ? new Date(segmentInfo.actualPackStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.actualPackEnd) ? new Date(segmentInfo.actualPackEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Load</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.estimatedLoadStart) ? new Date(segmentInfo.estimatedLoadStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.estimatedLoadEnd) ? new Date(segmentInfo.estimatedLoadEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.actualLoadStart) ? new Date(segmentInfo.actualLoadStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.actualLoadEnd) ? new Date(segmentInfo.actualLoadEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Delivery</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.estimatedDeliveryStart) ? new Date(segmentInfo.estimatedDeliveryStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.estimatedDeliveryEnd) ? new Date(segmentInfo.estimatedDeliveryEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.actualDeliveryStart) ? new Date(segmentInfo.actualDeliveryStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.actualDeliveryEnd) ? new Date(segmentInfo.actualDeliveryEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Loading</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.estimatedCarLoadingStart) ? new Date(segmentInfo.estimatedCarLoadingStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.estimatedCarLoadingEnd) ? new Date(segmentInfo.estimatedCarLoadingEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.actualCarLoadingStart) ? new Date(segmentInfo.actualCarLoadingStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.actualCarLoadingEnd) ? new Date(segmentInfo.actualCarLoadingEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>

              <Grid item md="4">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>Car Delivery</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.estimatedCarDeliveryStart) ? new Date(segmentInfo.estimatedCarDeliveryStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.estimatedCarDeliveryEnd) ? new Date(segmentInfo.estimatedCarDeliveryEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.actualCarDeliveryStart) ? new Date(segmentInfo.actualCarDeliveryStart).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>
              <Grid item md="2">
                <Box>
                  <div className={classes.mb2}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>{(segmentInfo && segmentInfo.actualCarDeliveryEnd) ? new Date(segmentInfo.actualCarDeliveryEnd).toLocaleDateString('en-us') : <div>&mdash;</div>}</Typography>
                  </div>
                </Box>
              </Grid>

            </Grid>
          </CardContent>
        </Box>
      </Card>
      {editingModalOpen && (
        <EditCardModal
          classes={classes}
          tab={0}
          open={editingModalOpen}
          isLoading={isLoading}
          onClose={() => setEditingModalOpen(false)}
          onSubmit={handleSaveEdits}
          segmentInfo={segmentInfo}
        />
      )}
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateHhgSegment,
    showToast,
  }),
)(ShipmentMoveDatesCard);