/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Button, Card, CardContent, Dialog, DialogActions, Grid, MenuItem, Select, TextField, Typography,
} from '@mui/material'; 

import { DELETE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE, DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE, GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE, GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_FAILURE, GET_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE, POST_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE, POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE, UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { deleteGlobalHomeServiceProperty, deleteGlobalHomeServicePropertyTenant, getAllGlobalHomeServicePropertyTenants, getGlobalHomeServiceProperties, getGlobalHomeServiceProperty, postGlobalHomeServiceProperty, postGlobalHomeServicePropertyTenant, updateGlobalHomeServicePropertyTenant } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import PropTypes from 'prop-types';
import PropertyManagementHomeService from './propertyManagementHomeService.component';
import React, { useEffect, useState } from 'react';
import Spinner from 'modules/common/spinner.component';
import StatusStepperGlobal from './statusStepperGlobal.component';
import SummaryCard from '../summaryCard.component';
import TenancyManagementCard from './tenancyManagementCard.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const AssignmentLanding = (props) => {    
  const { details, id, hostServiceSelected, isReadOnly } = props;

  const [currentPropertyId, setCurrentPropertyId] = useState(details.allHomeServiceProperties && details.allHomeServiceProperties[0] ? details.allHomeServiceProperties[0].propertyId : null);
  const [showAddProperty, setShowAddProperty] = useState(false);
  const [isLoadingModal, setIsLoadingModal] = useState(false);
  const [newPropertyAddress, setNewPropertyAddress] = useState('');
  const [newPropertyCity, setNewPropertyCity] = useState('');
  const [newPropertyState, setNewPropertyState] = useState('');
  const [newPropertyCountry, setNewPropertyCountry] = useState('');
  const [newPropertyZip, setNewPropertyZip] = useState('');
  const [newPropertyOwnerName, setNewPropertyOwnerName] = useState('');
  const [newPropertyOwnerHomePhone, setNewPropertyOwnerHomePhone] = useState('');
  const [newPropertyOwnerCellPhone, setNewPropertyOwnerCellPhone] = useState('');
  const [newPropertyOwnerEmail, setNewPropertyOwnerEmail] = useState('');
  const [allPropertyTenants, setAllPropertyTenants] = useState([]);

  const property = details.allHomeServiceProperties ? details.allHomeServiceProperties.find((s) => s.propertyId === currentPropertyId) : null;

  const createProperty = async () => {
    const authId = details.summary.authorizationId;
    
    setIsLoadingModal(true);

    const resp = await props.postGlobalHomeServiceProperty({
      authorizationId: authId,
      propertyAddress: newPropertyAddress,
      propertyCity: newPropertyCity,
      propertyState: newPropertyState,
      propertyCountry: newPropertyCountry,
      propertyZip: newPropertyZip,
      propertyOwnerName: newPropertyOwnerName,
      propertyOwnerHomePhone: newPropertyOwnerHomePhone,
      propertyOwnerCellPhone: newPropertyOwnerCellPhone,
      propertyOwnerEmail: newPropertyOwnerEmail,
    });

    if (resp.type === POST_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
      props.showToast('Failed to update the Global Home Service Property info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      const allPropertiesResp = await props.getGlobalHomeServiceProperties(authId);
      if (allPropertiesResp.type === GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
        props.showToast('Failed to fetch Global Home Service Properties, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        props.showToast('New Property created successfully.', { userMustDismiss: false, severity: 'success' });
        setCurrentPropertyId(resp.response.propertyId);
        setShowAddProperty(false);
        setNewPropertyAddress('');
        setNewPropertyCity('');
        setNewPropertyState('');
        setNewPropertyCountry('');
        setNewPropertyZip('');
        setNewPropertyOwnerName('');
        setNewPropertyOwnerHomePhone('');
        setNewPropertyOwnerCellPhone('');
        setNewPropertyOwnerEmail('');
      }
      setIsLoadingModal(false);
    }
  };

  const deleteProperty = async () => {
    const deletePropertyResp = await props.deleteGlobalHomeServiceProperty(currentPropertyId);
    if (deletePropertyResp.type === DELETE_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
      props.showToast('Failed to delete the Global Home Service Property info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      props.showToast('Property deleted successfully.', { userMustDismiss: false, severity: 'success' });
      setCurrentPropertyId(details.allHomeServiceProperties[0].propertyId);
      const getPropertiesResp = await props.getGlobalHomeServiceProperties(id);
      if (getPropertiesResp.type === GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
        props.showToast('Failed to get the Global Home Service Properties info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }     }
  };

  const getPropertyData = async () => {
    const propertyResp = await props.getGlobalHomeServiceProperty(currentPropertyId);
    if (propertyResp.type === GET_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
      props.showToast('Failed to get the Global Home Service Property info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } 
  };

  const getTenantsData = async () => {
    const propertyTenantsResp = await props.getAllGlobalHomeServicePropertyTenants(currentPropertyId);
    if (propertyTenantsResp.type === GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_TENANTS_FAILURE) {
      props.showToast('Failed to get the Global Home Service Property Tenants info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
    } else {
      setAllPropertyTenants(propertyTenantsResp.response);
    }
  };

  const updateTenantRow = async (tenant, values) => {
    let newValues = { ...tenant };
    newValues.authorizationId = id;
    newValues.propertyId = currentPropertyId;
    newValues.tenantId = tenant.original.tenantId;
    newValues.tenantName = values.tenantName ? values.tenantName : '';
    newValues.status = values.status ? values.status : '';
    newValues.leaseStartDate = values.leaseStartDate ? values.leaseStartDate : null;
    newValues.leaseEndDate = values.leaseEndDate ? values.leaseEndDate : null;
    newValues.cancelledDate = values.cancelledDate ? values.cancelledDate : null;
    newValues.noticeGiven = values.noticeGiven ? values.noticeGiven : null;
    newValues.moveInInspectionDone = values.moveInInspectionDone ? values.moveInInspectionDone : false;
    newValues.moveOutInspectionDone = values.moveOutInspectionDone ? values.moveOutInspectionDone : false;
    newValues.actualMoveInDate = values.actualMoveInDate ? values.actualMoveInDate : null;
    newValues.actualMoveOutDate = values.actualMoveOutDate ? values.actualMoveOutDate : null;
    newValues.actualCancelledDate = values.actualCancelledDate ? values.actualCancelledDate : null;
    newValues.actualNoticeGiven = values.actualNoticeGiven ? values.actualNoticeGiven : null;
    newValues.noticeDays = values.noticeDays ? values.noticeDays : 0;
    newValues.depositAmount = values.depositAmount ? values.depositAmount : '';
    newValues.monthlyAmount = values.monthlyAmount ? values.monthlyAmount : '';
    newValues.terminationFee = values.terminationFee ? values.terminationFee : '';
    newValues.numAdults = values.numAdults ? values.numAdults : 0;
    newValues.numChildren = values.numChildren ? values.numChildren : 0;
    newValues.numPets = values.numPets ? values.numPets : 0;
    newValues.comments = values.comments ? values.comments : '';
    newValues.propertyManagementCompany = values.propertyManagementCompany ? values.propertyManagementCompany : '';

    const resp = await props.updateGlobalHomeServicePropertyTenant(newValues);
    if (resp.type === UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE) {
      return false;
    } else {
      getTenantsData();
      return true;
    }
  };

  const createTenantRow = async (values) => {
    let newValues = {
      authorizationId: id,
      propertyId: currentPropertyId,
      tenantName: values.tenantName ? values.tenantName : '',
      status: values.status ? values.status : '',
      leaseStartDate: values.leaseStartDate ? values.leaseStartDate : null,
      leaseEndDate: values.leaseEndDate ? values.leaseEndDate : null,
      cancelledDate: values.cancelledDate ? values.cancelledDate : null,
      noticeGiven: values.noticeGiven ? values.noticeGiven : null,
      moveInInspectionDone: values.moveInInspectionDone ? values.moveInInspectionDone : false,
      moveOutInspectionDone: values.moveOutInspectionDone ? values.moveOutInspectionDone : false,
      actualMoveInDate: values.actualMoveInDate ? values.actualMoveInDate : null,
      actualMoveOutDate: values.actualMoveOutDate ? values.actualMoveOutDate : null,
      actualCancelledDate: values.actualCancelledDate ? values.actualCancelledDate : null,
      actualNoticeGiven: values.actualNoticeGiven ? values.actualNoticeGiven : null,
      noticeDays: values.noticeDays ? values.noticeDays : 0,
      depositAmount: values.depositAmount ? values.depositAmount : '',
      monthlyAmount: values.monthlyAmount ? values.monthlyAmount : '',
      terminationFee: values.terminationFee ? values.terminationFee : '',
      numAdults: values.numAdults ? values.numAdults : 0,
      numChildren: values.numChildren ? values.numChildren : 0,
      numPets: values.numPets ? values.numPets : 0,
      comments: values.comments ? values.comments : '',
      propertyManagementCompany: values.propertyManagementCompany ? values.propertyManagementCompany : '',
    };
    const resp = await props.postGlobalHomeServicePropertyTenant(newValues);
    if (resp.type === POST_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE) {
      return false;
    } else {
      getTenantsData();
      return true;
    }
  };

  const deleteTenantRow = async (tenant) => {
    const tenantId = tenant.original.tenantId;
    const resp = await props.deleteGlobalHomeServicePropertyTenant(tenantId);
    if (resp.type === DELETE_GLOBAL_HOME_SERVICE_PROPERTY_TENANT_FAILURE) {
      return false;
    } else {
      getTenantsData();
      return true;
    }
  };

  useEffect(() => {
    if (currentPropertyId) {
      getTenantsData();
      getPropertyData();
    }
  }, [currentPropertyId]);

  return (
    <Box>
      <StatusStepperGlobal globalExtensions={details.globalExtensions} />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        {hostServiceSelected && (
          <>
            <Grid item xs={12} xl={8} rowSpacing={2}>
              <TenancyManagementCard globalInfo={details.global} id={id} isReadOnly={isReadOnly} />
            </Grid>
            <Grid item xs={12} xl={4} rowSpacing={2}>
              <SummaryCard dateSummary={details.dateSummary} id={id} hideEdit={true} />
            </Grid>
          </>
        )}
        {!hostServiceSelected && 
          <>
            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
              <Box>
                <div className={props.classes.mb2}>
                  <Typography variant="subtitle2">
                    Property
                  </Typography>
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                  <Select
                    labelId={'propertySelect'}
                    key={'propertySelect'}
                    label={''}
                    name={'propertyId'}
                    value={currentPropertyId}
                    sx={{ maxWidth: '360px', minWidth: '180px' }}
                    onChange={(e) =>
                      setCurrentPropertyId(e.target.value)
                    }
                  >
                    {details && details.allHomeServiceProperties && details.allHomeServiceProperties.map((opt) => (
                      <MenuItem key={opt.propertyId} value={opt.propertyId} sx={{ margin: 'dense' }}>
                        {opt.propertyAddress}
                      </MenuItem>
                    ))}
                  </Select>
                  {!isReadOnly && <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} onClick={() => setShowAddProperty(true)}> New Property </Button> }
                  {!isReadOnly && <Button color="secondary" type="submit" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px' }} disabled={(details.allHomeServiceProperties?.length ?? 0) === 0} onClick={() => deleteProperty()}> Delete Property </Button>} 
                </Box>
              </Box>
            </Box>
            {!isReadOnly && currentPropertyId == null && (
              <Box sx={{ padding: '20px', paddingTop: '165px', fontSize: '20px' }}>
                <b>No property selected. Create a new property above to get started.</b>
              </Box>
            )}
            { currentPropertyId &&(
              <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
                <Grid item xs={12} xl={8} rowSpacing={2}>
                  <PropertyManagementHomeService details={details} propertyId={currentPropertyId} id={id} tenants={allPropertyTenants} createTenantRow={createTenantRow} updateTenantRow={updateTenantRow} deleteTenantRow={deleteTenantRow} isReadOnly={isReadOnly}/>
                </Grid>
                <Grid item xs={12} xl={4} rowSpacing={2}>
                  <SummaryCard dateSummary={details.dateSummary} id={id} hideEdit={true} />
                  <Box sx={{ paddingTop: '16px' }}>
                    <Card>
                      <Box>
                        <CardContent>
                          <div className="row justify-space-between align-center">
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                                Property Info
                              </Typography>
                            </Box>
                          </div>
                        </CardContent>
                        <CardContent>
                          <div className="row justify-space-between align-center">
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <div>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Address
                                </Typography>
                                <div>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                    {property.propertyAddress}
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <div>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  City
                                </Typography>
                                <div>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                    {property.propertyCity}
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <div>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  State
                                </Typography>
                                <div>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                    {property.propertyState}
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <div>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Country
                                </Typography>
                                <div>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                    {property.propertyCountry}
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <div>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  ZIP Code
                                </Typography>
                                <div>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                    {property.propertyZip}
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                          </div>
                        </CardContent>
                      </Box>
                    </Card>
                  </Box>
                  <Box sx={{ paddingTop: '16px' }}>
                    <Card>
                      <Box>
                        <CardContent>
                          <div className="row justify-space-between align-center">
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                                Owner Info
                              </Typography>
                            </Box>
                          </div>
                        </CardContent>
                        <CardContent>
                          <div className="row justify-space-between align-center">
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <div>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Owner Name
                                </Typography>
                                <div>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                    {property.propertyOwnerName}
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <div>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Owner Home Phone
                                </Typography>
                                <div>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                    {property.propertyOwnerHomePhone}
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <div>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Owner Cell Phone
                                </Typography>
                                <div>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                    {property.propertyOwnerCellPhone}
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                              <div>
                                <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                  Owner Email
                                </Typography>
                                <div>
                                  <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem' }}>
                                    {property.propertyOwnerEmail}
                                  </Typography>
                                </div>
                              </div>
                            </Box>
                          </div>
                        </CardContent>
                      </Box>
                    </Card>
                  </Box>
                </Grid>
              </Grid>

            )}

            {!isReadOnly && <Dialog open={showAddProperty} fullWidth={false} maxWidth={'lg'}>
              <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                <Box sx={{ marginRight: '20px' }}>
                  <TextField
                    key={'newPropertyAddress'}
                    label={'Property Address'}
                    name={'newPropertyAddress'}
                    required={true}
                    disabled={false}
                    value={newPropertyAddress} // formatDateForInput(values[summary.accessorKey])
                    sx={{ maxWidth: '360px' }}
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setNewPropertyAddress(e.target.value)}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <TextField
                    key={'newPropertyCity'}
                    label={'Property City'}
                    name={'newPropertyCity'}
                    required={true}
                    disabled={false}
                    value={newPropertyCity} // formatDateForInput(values[summary.accessorKey])
                    sx={{ maxWidth: '360px' }}
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setNewPropertyCity(e.target.value)}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <TextField
                    key={'newPropertyState'}
                    label={'Property State'}
                    name={'newPropertyState'}
                    required={true}
                    disabled={false}
                    value={newPropertyState} // formatDateForInput(values[summary.accessorKey])
                    sx={{ maxWidth: '360px' }}
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setNewPropertyState(e.target.value)}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <TextField
                    key={'newPropertyCountry'}
                    label={'Property Country'}
                    name={'newPropertyCountry'}
                    required={true}
                    disabled={false}
                    value={newPropertyCountry} // formatDateForInput(values[summary.accessorKey])
                    sx={{ maxWidth: '360px' }}
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setNewPropertyCountry(e.target.value)}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <TextField
                    key={'newPropertyZip'}
                    label={'Property ZIP Code'}
                    name={'newPropertyZip'}
                    required={true}
                    disabled={false}
                    value={newPropertyZip} // formatDateForInput(values[summary.accessorKey])
                    sx={{ maxWidth: '360px' }}
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setNewPropertyZip(e.target.value)}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
              </Box>
              <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                <Box sx={{ marginRight: '20px' }}>
                  <TextField
                    key={'newPropertyOwnerName'}
                    label={'Property Owner'}
                    name={'newPropertyOwnerName'}
                    required={true}
                    disabled={false}
                    value={newPropertyOwnerName} // formatDateForInput(values[summary.accessorKey])
                    sx={{ maxWidth: '360px' }}
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setNewPropertyOwnerName(e.target.value)}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <TextField
                    key={'newPropertyOwnerHomePhone'}
                    label={'Property Owner Home Phone'}
                    name={'newPropertyOwnerHomePhone'}
                    required={true}
                    disabled={false}
                    value={newPropertyOwnerHomePhone} // formatDateForInput(values[summary.accessorKey])
                    sx={{ maxWidth: '360px' }}
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setNewPropertyOwnerHomePhone(e.target.value)}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <TextField
                    key={'newPropertyOwnerCellPhone'}
                    label={'Property Owner Cell Phone'}
                    name={'newPropertyOwnerCellPhone'}
                    required={true}
                    disabled={false}
                    value={newPropertyOwnerCellPhone} // formatDateForInput(values[summary.accessorKey])
                    sx={{ maxWidth: '360px' }}
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setNewPropertyOwnerCellPhone(e.target.value)}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
                <Box sx={{ marginRight: '20px' }}>
                  <TextField
                    key={'newPropertyOwnerEmail'}
                    label={'Property Owner Email'}
                    name={'newPropertyOwnerEmail'}
                    required={true}
                    disabled={false}
                    value={newPropertyOwnerEmail} // formatDateForInput(values[summary.accessorKey])
                    sx={{ maxWidth: '360px' }}
                    margin="dense"
                    variant="standard"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setNewPropertyOwnerEmail(e.target.value)}
                  />
                  <Typography variant="caption" display="block" color="primary">
                    Required
                  </Typography>
                </Box>
              </Box>

              <DialogActions sx={{ p: '1.25rem' }}>
                {isLoadingModal && <Spinner />}
                {!isLoadingModal && 
                  <>
                    <Button onClick={() => setShowAddProperty(false)}>Cancel</Button>
                    <Button onClick={createProperty} color="primary" type="submit" variant="contained" disabled={!(newPropertyAddress && newPropertyAddress.length > 0)}>
                      Create Property
                    </Button>
                  </>
                }
              </DialogActions>
            </Dialog>
            }
          </>
        }
      </Grid>
    </Box>
  );

};

AssignmentLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getGlobalHomeServiceProperties,
    getGlobalHomeServiceProperty,
    postGlobalHomeServiceProperty,
    getAllGlobalHomeServicePropertyTenants,
    deleteGlobalHomeServiceProperty,
    postGlobalHomeServicePropertyTenant,
    updateGlobalHomeServicePropertyTenant,
    deleteGlobalHomeServicePropertyTenant,
    showToast,
  }),
)(AssignmentLanding);