/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */

import {
    Box, Button, Grid, Paper, Typography,
  } from '@mui/material';
  import { withStyles } from '@material-ui/core';
  import { compose } from 'recompose';
  import { connect } from 'react-redux';
  import React, { useEffect, useState } from 'react';
  import classNames from 'classnames';
  
  import { showToast } from 'modules/layout/layout.actions';
  import AdvancedSearch from 'modules/common/advancedSearch.component';
  
  import { DRAWER_WIDTH_CLOSED, DRAWER_WIDTH_CLOSED_XS, DRAWER_WIDTH_OPEN } from 'styles/theme';
import VoucherInfoCard from './voucherInfoCard.component';
import SpinnerComponent from 'modules/common/spinner.component';
import { checkDirty } from 'modules/authorizations/utils';
import { updateVoucherSummary } from 'modules/expenses/expenses.actions';
import { UPDATE_VOUCHER_SUMMARY_FAILURE } from 'modules/expenses/types';
import { TOAST_MESSAGE_SEVERITY_ERROR, TOAST_MESSAGE_SEVERITY_SUCCESS } from 'modules/layout/types';
import PaymentInfoCard from './paymentInfoCard.component';
import { Prompt } from 'react-router-dom/cjs/react-router-dom';
  
  
  const styles = (theme) => ({
    card: {
      width: '100%',
      maxWidth: 1500,
      flexGrow: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    cardContainer: {
      maxWidth: '100% !important',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
  
    },
    iconRow:{
      display: 'flex',
      flexDirection: 'row',
    },
    dialogIconRow:{
      display: 'flex',
      flexDirection: 'row',
      padding: '40px',
      paddingBottom: '20px',
    },
    cardDisplayContent: {
      maxWidth: '100% !important',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      margin: '10px',
    },
    container: {
      height: 'auto',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      whiteSpace: 'nowrap',
      width: `calc(100% - ${DRAWER_WIDTH_CLOSED_XS}px)`,
      [theme.breakpoints.up('sm')]: {
        width: `calc(100% - ${DRAWER_WIDTH_CLOSED}px)`,
      },
    },
    drawerOpen: {
      width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)`,
    },
    headerFixed: {
      zIndex: 4,
    },
    tabFixed: { 
      paddingTop: '100px',
      zIndex: 3,
    }, 
    estimateHeaderFixed: {
      paddingTop: '150px',
      zIndex: 2,
    },
  });

  const statusOptions = [
    { title: 'DRAFT', id: 1 },
    { title: 'PENDING', id: 2 },
    { title: 'ON HOLD', id: 3 },
    { title: 'APPROVED', id: 4 },
    { title: 'DENIED', id: 5 },
  ];
  
  const VoucherDetails = (props) => {    
    const { unmappedClients, authorizationSummaries, shouldShowSideDrawer, vendors, voucherInfo } = props;

    const [voucherInfoForm, setVoucherInfoForm] = useState({...voucherInfo});
    const [isLoading, setIsLoading] = useState(false);
    const [isDirty, setIsDirty] = useState(false);

    let unmappedClientsAdjusted = [];
    if (unmappedClients) {
      unmappedClientsAdjusted = unmappedClients.map((c) => {
          let newCopy = {...c};
          newCopy.clientName = c.description;
          newCopy.clientId = c.id;
          return newCopy;
      })
    }

    const saveDraft = async () => {
      setIsLoading(true);
  
      let newVals = { ...voucherInfoForm };
  
      const resp = await props.updateVoucherSummary(newVals);
      if (resp.type === UPDATE_VOUCHER_SUMMARY_FAILURE) {
          props.showToast('Failed to update the voucher summary, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
      } else { 
          props.showToast(`Successfully updated voucher summary ${voucherInfoForm.voucherId}.`, {severity: TOAST_MESSAGE_SEVERITY_SUCCESS});
          setIsLoading(false);
          setIsDirty(false);
          return true;
      }
    };

    useEffect(() => {
      setIsDirty(checkDirty(voucherInfo, voucherInfoForm));
    }, [voucherInfoForm]);
  
    return (
      <Box>
        <Prompt
            when={true}
            message={({ pathname }) => {
                return !isDirty ? true : 'Make sure to save any unsaved changes. Are you sure you would like to leave?';
            }}
        />
        <Paper
          square
          className={classNames({
            'position-fixed': true,
            [props.classes.headerFixed]: true,
            [props.classes.container]: true,
            [props.classes.drawerOpen]: shouldShowSideDrawer
          })}
          sx={{ paddingTop: '5px', zIndex: 4, position: 'relative', width: '100%' }}
        >
  
          <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', padding: '10px' }}>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                <Box sx={{ marginRight: '20px' }}>
                  <AdvancedSearch 
                    labelText={'Client'} 
                    displayValueAccessorKey={'clientName'} 
                    saveIdValueAccessorKey={'clientId'} 
                    searchingCriteria={[{ name: 'clientId', title: 'Client Id' },{ name: 'clientName', title: 'Company Name' },{ name: 'moveTrackClientId', title: 'MT Client Id' },{ name: 'clientCode', title: 'Client Code' },{ name: 'clientStatus', title: 'Status' },{ name: 'serviceRep', title: 'Service Rep' },]} 
                    searchingCriteriaExtensions={[{ columnName: 'clientId', width: 150 },{ columnName: 'clientName', width: 250 },{ name: 'moveTrackClientId', width: 175 },{ name: 'clientCode', width: 175 },{ columnName: 'clientStatus', width: 175 },{ columnName: 'serviceRep', width: 175 },]} 
                    searchingRows={unmappedClientsAdjusted} 
                    id={voucherInfoForm.clientId} 
                    value={voucherInfoForm.clientName} 
                    setIdValue={(id, value) => { setVoucherInfoForm({ ...voucherInfoForm, clientId: id, clientName: value}); }}
                    isReadOnly={false}
                  />
                </Box>

                <Box>
                  <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: voucherInfoForm.payToVendor ? '#36939b' : '#ffffff', '&:hover': { backgroundColor: voucherInfoForm.payToVendor ? '#15585e' : '#faf8f2' }, color: voucherInfoForm.payToVendor ? 'ffffff' : '#080600' }} onClick={() => setVoucherInfoForm({ ...voucherInfoForm, payToVendor: !voucherInfoForm.payToVendor})}> Pay to Vendor </Button>
                  <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginLeft: '12px', marginTop: '8px', backgroundColor: !voucherInfoForm.payToVendor ? '#36939b' : '#ffffff', '&:hover': { backgroundColor: !voucherInfoForm.payToVendor ? '#15585e' : '#faf8f2' }, color: !voucherInfoForm.payToVendor ? 'ffffff' : '#080600' }} onClick={() => setVoucherInfoForm({ ...voucherInfoForm, payToVendor: !voucherInfoForm.payToVendor})}> Pay to EE </Button>
                </Box>

                {voucherInfoForm.payToVendor && (
                  <Box sx={{ marginLeft: '25px' }}>
                    <AdvancedSearch 
                      labelText={'Vendor'} 
                      displayValueAccessorKey={'companyName'} 
                      saveIdValueAccessorKey={'vendorId'} 
                      searchingCriteria={[{ name: 'vendorId', title: 'Vendor Id' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }, { name: 'phone', title: 'Company Phone' }, { name: 'email', title: 'Company Email' }]} 
                      searchingCriteriaExtensions={[{ columnName: 'vendorId', width: 150 }, { columnName: 'companyName', width: 250 }, { name: 'city', width: 175 }, { name: 'state', width: 175 }, { columnName: 'phone', width: 175 }, { columnName: 'email', width: 175 }]} 
                      searchingRows={vendors} 
                      id={voucherInfoForm.vendorId} 
                      value={voucherInfoForm.vendorName} 
                      setIdValue={(id, value) => { setVoucherInfoForm({ ...voucherInfoForm, vendorId: id, vendorName: value}); }}
                      isReadOnly={false}
                    />
                  </Box>
                )}

                {!voucherInfoForm.payToVendor && (
                  <Box sx={{ marginLeft: '25px' }}>
                    <AdvancedSearch 
                      labelText={'Authorization'} 
                      displayValueAccessorKey={'transfereeName'} 
                      saveIdValueAccessorKey={'authorizationId'} 
                      searchingCriteria={[{ name: 'authorizationId', title: 'Auth Id' }, { name: 'transfereeName', title: 'Full Name' }, { name: 'departure', title: 'Departure Location' }, { name: 'destination', title: 'Destination Location' }, { name: 'clientName', title: 'Client' }]} 
                      searchingCriteriaExtensions={[{ columnName: 'authorizationId', width: 150 }, { columnName: 'transfereeName', width: 250 }, { name: 'departure', width: 175 }, { name: 'destination', width: 175 }, { columnName: 'clientName', width: 175 } ]} 
                      searchingRows={authorizationSummaries.filter((a) => a.clientName === voucherInfoForm.clientName)} 
                      id={voucherInfoForm.authorizationId} 
                      value={voucherInfoForm.authName} 
                      setIdValue={(id, value) => { setVoucherInfoForm({ ...voucherInfoForm, authorizationId: id, authName: value}); }}
                      isReadOnly={false}
                    />
                  </Box>
                )}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', paddingTop: '20px' }}>
                <Box>
                <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem', textAlign: 'center' }}>
                        Status
                    </Typography>
                </Box>
                <Box sx={{ marginBottom: '4px', marginRight: '20px' }}>
                    <Typography variant="body2" sx={{ color: '#43423a', fontWeight: 500, fontSize: '1rem', textAlign: 'center' }}>
                        {statusOptions.find((s) => s.id === voucherInfoForm.status).title}
                    </Typography>
                </Box>
                </Box>
            </Box>
          </Box>
        </Paper>
        
        <Box sx={{paddingLeft: '16px', paddingTop: '10px'}}>
          <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
            <Grid item xs={12} xl={8} rowSpacing={2}>
              <VoucherInfoCard voucherInfo={voucherInfoForm} updateVoucherInfo={(newInfo) => setVoucherInfoForm(newInfo)} />
            </Grid>
            <Grid item xs={12} xl={4} rowSpacing={2}>
              <PaymentInfoCard voucherInfo={voucherInfoForm} updateVoucherInfo={(newInfo) => setVoucherInfoForm(newInfo)} />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{marginTop: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {/* {!isReadOnly && <Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginRight: '12px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }} onClick={approveAll}> Approve All </Button> }
                {!isReadOnly &&<Button color="secondary" variant="contained" sx={{ maxHeight: '40px', marginRight: '12px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }} onClick={denyAll}> Deny All </Button> }
                {!isReadOnly &&<Button color="secondary" variant="contained" sx={{ maxHeight: '40px', backgroundColor: '#868686', '&:hover': { backgroundColor: '#474747' } }} onClick={addLine}> Add Line</Button> } */}
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                {isLoading && <SpinnerComponent/>}
                {!isLoading && <>
                    <Button onClick={() => setVoucherInfoForm(voucherInfo)} sx={{ color: '#41B7C0', '&:hover': { color: '#3d959c' } }} disabled={!isDirty}>Cancel</Button>
                    <Button color="primary" variant="contained" sx={{ maxHeight: '40px', marginRight: '12px' }} onClick={saveDraft}  disabled={!isDirty}> Save Draft </Button>
                    {/* <Button color="secondary" variant="contained" sx={{ maxHeight: '40px' }} onClick={completeReport}> Submit as Pending </Button> */}
                </>}
            </Box>
        </Box>
      </Box>
    );
  
  };
  
  const mapStateToProps = (state) => {
    const {
        authorizations: { authorizationSummaries, vendors },
        expenses: { voucherSummaries, expenseLines, isLoading },
        clients: { clients, unmappedClients },
    } = state;
    return { voucherSummaries, expenseLines, clients, unmappedClients, isLoading, vendors, authorizationSummaries };
  };
  
  export default compose(
    withStyles(styles),
    connect(mapStateToProps, {
      updateVoucherSummary,
      showToast,
    }),
  )(VoucherDetails);