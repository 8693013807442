import { DELETE_ASSIGNMENT_EXTENSIONS_FAILURE, DELETE_ASSIGNMENT_TRIPS_FAILURE, GET_ASSIGNMENT_EXTENSIONS_FAILURE, GET_ASSIGNMENT_TRIPS_FAILURE, POST_ASSIGNMENT_EXTENSIONS_FAILURE, POST_ASSIGNMENT_TRIPS_FAILURE, UPDATE_ASSIGNMENT_EXTENSIONS_FAILURE, UPDATE_ASSIGNMENT_TRIPS_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { deleteAssignmentExtension, deleteAssignmentTrip, getAssignmentExtensions, getAssignmentTrips, postAssignmentExtension, postAssignmentTrip, postGlobalInfo, updateAssignmentExtension, updateAssignmentTrip, updateGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';

import CrudTableCard from 'modules/common/crudTableCard.component';
import Spinner from 'modules/common/spinner.component';

const AssignmentExtensionCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { globalTrips, globalExtensions, id, isReadOnly } = props;

  const tripOptions = [
    { title: 'Bereavment', id: 1 },
    { title: 'Business', id: 2 },
    { title: 'Evacuation', id: 3 },
    { title: 'Ext-Business', id: 4 },
    { title: 'Ext-Long', id: 5 },
    { title: 'Ext-Short', id: 6 },
    { title: 'Trip Home', id: 7 },
    { title: 'Visa Renewal Trip', id: 8 },
  ];

  const tabs = [
    { header: 'Trips on Assignment', order: 1, iconPath: ['fas', 'plane'] },
    { header: 'Assignment Extensions', order: 2, iconPath: ['fas', 'suitcase-rolling'] },
  ];

  const assignmentTripsColumns = [
    { accessorKey: 'description', header: 'Description', type: 'text', required: true, size: 70 },
    { accessorKey: 'tripType', header: 'Trip Type', type: 'dropdown-v2', required: false, options: tripOptions, size: 50, Cell: ({ cell }) => cell.row.original.tripType != null && <p>{tripOptions.find((o) => o.id === cell.row.original.tripType).title}</p> },
    { accessorKey: 'beginDate', header: 'Begin Date', type: 'date', required: false, size: 50, Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString('en-us') },
    { accessorKey: 'endDate', header: 'End Date', type: 'date', required: false, size: 50, Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString('en-us') },
  ];

  const assignmentExtensionsColumns = [
    { accessorKey: 'extensionDate', header: 'Extension Date', type: 'date', required: true, size: 70, Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString('en-us') },
    { accessorKey: 'revEndDate', header: 'Rev End Date', type: 'date', required: false, size: 50, Cell: ({ cell }) => new Date(cell.getValue()).toLocaleDateString('en-us') },
    { accessorKey: 'extensionType', header: 'Extension Type', type: 'dropdown-v2', required: false, options: tripOptions, size: 50, Cell: ({ cell }) => cell.row.original.extensionType != null && <p>{tripOptions.find((o) => o.id === cell.row.original.extensionType).title}</p> },
    { accessorKey: 'extensionNote', header: 'Extension Note', type: 'text', required: false, size: 50 },
  ];

  const updateTrip = async (vendor, values) => {
    values.authorizationId = id;
    values.assignmentTripId = vendor.original.assignmentTripId;

    setIsLoading(true);
    const resp = await props.updateAssignmentTrip(values);
    if (resp.type === UPDATE_ASSIGNMENT_TRIPS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getAssignmentTrips(id);
      setIsLoading(false);
      if (getResp.type === GET_ASSIGNMENT_TRIPS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createTrip = async (values) => {
    values.authorizationId = id;

    setIsLoading(true);
    const resp = await props.postAssignmentTrip(values);
    if (resp.type === POST_ASSIGNMENT_TRIPS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getAssignmentTrips(id);
      setIsLoading(false);
      if (getResp.type === GET_ASSIGNMENT_TRIPS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteTrip = async (vendor) => {
    const resp = await props.deleteAssignmentTrip(vendor.original.assignmentTripId);
    if (resp.type === DELETE_ASSIGNMENT_TRIPS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAssignmentTrips(id);
      if (getResp.type === GET_ASSIGNMENT_TRIPS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const updateExtension = async (vendor, values) => {
    values.authorizationId = id;
    values.assignmentExtensionId = vendor.original.assignmentExtensionId;

    setIsLoading(true);
    const resp = await props.updateAssignmentExtension(values);
    if (resp.type === UPDATE_ASSIGNMENT_EXTENSIONS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getAssignmentExtensions(id);
      setIsLoading(false);
      if (getResp.type === GET_ASSIGNMENT_EXTENSIONS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createExtension = async (values) => {
    values.authorizationId = id;

    setIsLoading(true);
    const resp = await props.postAssignmentExtension(values);
    if (resp.type === POST_ASSIGNMENT_EXTENSIONS_FAILURE) {
      setIsLoading(false);
      return false;
    } else {
      const getResp = await props.getAssignmentExtensions(id);
      setIsLoading(false);
      if (getResp.type === GET_ASSIGNMENT_TRIPS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteExtension = async (vendor) => {
    const resp = await props.deleteAssignmentExtension(vendor.original.assignmentExtensionId);
    if (resp.type === DELETE_ASSIGNMENT_EXTENSIONS_FAILURE) {
      return false;
    } else {
      const getResp = await props.getAssignmentExtensions(id);
      if (getResp.type === GET_ASSIGNMENT_EXTENSIONS_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  

  const summaries = [
    { adjustedColumns: assignmentTripsColumns, rows: globalTrips ? globalTrips : [], addButtonText: 'Add Trip', tab: 1, updateRow: updateTrip, createRow: createTrip, deleteRow: deleteTrip, hideEdit: isReadOnly },
    { adjustedColumns: assignmentExtensionsColumns, rows: globalExtensions ? globalExtensions : [], addButtonText: 'Add Extension', tab: 2, updateRow: updateExtension, createRow: createExtension, deleteRow: deleteExtension, hideEdit: isReadOnly },
  ];
    
  return (
    <>
      {isLoading && <Spinner />}
      {!isLoading && 
        <CrudTableCard
          tabs={tabs}
          summaries={summaries}
          numColumns={5}
        />
      }
    </>
  );

};

export default compose(
  connect(null, {
    updateGlobalInfo,
    postGlobalInfo,
    showToast,
    updateAssignmentExtension,
    updateAssignmentTrip,
    postAssignmentExtension,
    postAssignmentTrip,
    deleteAssignmentExtension,
    deleteAssignmentTrip,
    getAssignmentTrips,
    getAssignmentExtensions,
  }),
)(AssignmentExtensionCard);