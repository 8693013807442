/* eslint-disable no-case-declarations */
import {
  DELETE_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  DELETE_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
  GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
  GET_ASSIGNMENT_EXTENSIONS_SUCCESS,
  GET_ASSIGNMENT_TRIPS_SUCCESS,
  GET_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  GET_GLOBAL_INFO_SUCCESS,
  GET_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  GET_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
  POST_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  POST_GLOBAL_INFO_SUCCESS,
  POST_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  POST_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
  UPDATE_GLOBAL_DESTINATION_SERVICES_SUCCESS,
  UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS,
  UPDATE_GLOBAL_INFO_SUCCESS,
  UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS,
  UPDATE_GLOBAL_LANGUAGE_TRAINING_SUCCESS,
} from '../types/globalTypes';

const handleGlobalReducer = (draft, action) => {
  if (!draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};

  switch (action.type) {
    case GET_GLOBAL_INFO_SUCCESS:
    case UPDATE_GLOBAL_INFO_SUCCESS:
    case POST_GLOBAL_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].global = action.response;
      break;

    case GET_ASSIGNMENT_TRIPS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].globalTrips = action.response;
      break;

    case GET_ASSIGNMENT_EXTENSIONS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].globalExtensions = action.response;
      break;

    case GET_GLOBAL_DESTINATION_SERVICES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].globalDestinationServices = action.response;
      break;

    case GET_GLOBAL_LANGUAGE_TRAINING_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].globalLanguageTrainings = action.response;
      break;

    case GET_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].internationalSchoolings = action.response;
      break;

    case POST_GLOBAL_DESTINATION_SERVICES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].globalDestinationServices.push(action.response);
      break;

    case POST_GLOBAL_LANGUAGE_TRAINING_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].globalLanguageTrainings.push(action.response);
      break;

    case POST_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].internationalSchoolings.push(action.response);
      break;

    case UPDATE_GLOBAL_DESTINATION_SERVICES_SUCCESS: {
      const destServices = draft.authorizationDetails[draft.currentAuthId]?.globalDestinationServices;
      if (destServices) {
        const indexToUpdate = destServices.findIndex(
          (destService) => destService.authGlobalDestinationId === action.response?.authGlobalDestinationId,
        );
        if (indexToUpdate !== -1) {
          draft.authorizationDetails[draft.currentAuthId].globalDestinationServices[indexToUpdate] = action.response;
        }
      }
      break;
    }

    case UPDATE_GLOBAL_LANGUAGE_TRAINING_SUCCESS: {
      const langTrainings = draft.authorizationDetails[draft.currentAuthId]?.globalLanguageTrainings;
      if (langTrainings) {
        const indexToUpdate = langTrainings.findIndex(
          (langTraining) => langTraining.authGlobalLanguageId === action.response?.authGlobalLanguageId,
        );
        if (indexToUpdate !== -1) {
          draft.authorizationDetails[draft.currentAuthId].globalLanguageTrainings[indexToUpdate] = action.response;
        }
      }
      break;
    }

    case UPDATE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS: {
      const internationalSchoolings = draft.authorizationDetails[draft.currentAuthId]?.internationalSchoolings;
      if (internationalSchoolings) {
        const indexToUpdate = internationalSchoolings.findIndex(
          (internationalSchooling) =>
            internationalSchooling.authGlobalInternationSchoolingId === action.response?.authGlobalInternationSchoolingId,
        );
        if (indexToUpdate !== -1) {
          draft.authorizationDetails[draft.currentAuthId].internationalSchoolings[indexToUpdate] = action.response;
        }
      }
      break;
    }

    case DELETE_GLOBAL_DESTINATION_SERVICES_SUCCESS: {
      const destServices = draft.authorizationDetails[draft.currentAuthId]?.globalDestinationServices;
      if (destServices) {
        const indexToUpdate = destServices.findIndex(
          (destService) => destService.authGlobalDestinationId === action.response?.authGlobalDestinationId,
        );
        if (indexToUpdate !== -1) {
          destServices.splice(indexToUpdate, 1);
        }
      }
      break;
    }

    case DELETE_GLOBAL_LANGUAGE_TRAINING_SUCCESS: {
      const langTrainings = draft.authorizationDetails[draft.currentAuthId]?.globalLanguageTrainings;
      if (langTrainings) {
        const indexToUpdate = langTrainings.findIndex(
          (langTraining) => langTraining.authGlobalLanguageId === action.response?.authGlobalLanguageId,
        );
        if (indexToUpdate !== -1) {
          langTrainings.splice(indexToUpdate, 1);
        }
      }
      break;
    }

    case DELETE_GLOBAL_INTERNATIONAL_SCHOOLING_SUCCESS: {
      const internationalSchoolings = draft.authorizationDetails[draft.currentAuthId]?.internationalSchoolings;
      if (internationalSchoolings) {
        const indexToUpdate = internationalSchoolings.findIndex(
          (internationalSchooling) =>
            internationalSchooling.authGlobalInternationSchoolingId === action.response?.authGlobalInternationSchoolingId,
        );
        if (indexToUpdate !== -1) {
          internationalSchoolings.splice(indexToUpdate, 1);
        }
      }
      break;
    }

    case GET_ALL_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].allHomeServiceProperties = action.response;
      break;

    case UPDATE_GLOBAL_HOME_SERVICE_PROPERTY_SUCCESS:
      let tempProperties = draft.authorizationDetails[draft.currentAuthId].allHomeServiceProperties;
      const tempPropertyIndex = tempProperties.findIndex((s) => s.propertyId === action.response.propertyId);
      tempProperties[tempPropertyIndex] = action.response;
      draft.authorizationDetails[draft.currentAuthId].allHomeServiceProperties = tempProperties;
      break;

    default:
      break;
  }

  return draft;
};

export default handleGlobalReducer;
