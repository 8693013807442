/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Grid,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { GET_ALL_BROKER_AGENTS_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';
import {
  GET_ALL_CANDIDATE_TRIPS_FAILURE,
  GET_CANDIDATE_SERVICES_FAILURE,
  GET_COST_ESTIMATES_FAILURE, GET_INTERN_SERVICES_FAILURE,
} from 'modules/authorizations/store/types/canidateTripTypes';
import { GET_AUTH_CLIENT_POLICIES_FAILURE, GET_AUTH_DATE_SUMMARY_FAILURE, GET_AUTH_SERVICES_FAILURE, GET_AUTH_SUMMARY_FAILURE, 
  GET_AUTH_TEAM_FAILURE, GET_COMMUNICATION_INFO_FAILURE, GET_DEPARTURE_ADDRESS_FAILURE, GET_DESTINATION_ADDRESS_FAILURE,
  GET_EMPLOYEE_INFO_FAILURE, GET_SO_INFO_FAILURE, GET_USER_SETTINGS_FAILURE } from '../../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../../layout/types';
import { getAuthClientPolicies, getAuthDateSummary, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress, getDestinationAddress, 
  getEmployeeInfo, getSOInfo, getServices, getTeam, getUserSettings, setAuthId, setLoadingComplete } from '../../store/actions/authorizations.actions';
import { getBrokerAgents } from 'modules/authorizations/store/actions/dataManagement.actions';
import { getCandidateServices, getCandidateTrips, getCostEstimates, getInternServices } from 'modules/authorizations/store/actions/canidateTrip.actions';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import CandidateServices from './candidateServices.component';
import InternServices from './internServices.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const CandidateServicesView = (props) => {

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
   
  });
    
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const clientInfo = details && details.summary && props.unmappedClients ? props.unmappedClients.find((e) => e.description === details.summary.clientName) : null;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData () {
      const id = props.match.params.id;

      window.scrollTo(0, 0);
      props.setAuthId(id);

      if (!details) {
        setIsLoading(true);
          
        const [authSummaryResp, employeeInfoResp, soInfoResp, authDateSummaryResp, communicationInfoResp, departureResp, destinationResp, authClientPoliciesResp,
          teamResp, authServiceResp, userSettingsResp, brokerAgentsResp, costEstimateResp, candidateResp, internResp, candidateTripsResp  ] = await Promise.all([
          props.getAuthorizationSummary(id),
          props.getEmployeeInfo(id),
          props.getSOInfo(id),
          props.getAuthDateSummary(id),
          props.getCommunicationInfo(id),
          props.getDepartureAddress(id),
          props.getDestinationAddress(id),
          props.getAuthClientPolicies(id),
          props.getTeam(id),
          props.getServices(id),
          props.getUserSettings(id),
          props.getBrokerAgents(),
          props.getCostEstimates(id),
          props.getCandidateServices(id),
          props.getInternServices(id),
          props.getCandidateTrips(id),
        ]);
  
        props.setLoadingComplete();
        setIsLoading(false);

        if (authSummaryResp.type === GET_AUTH_SUMMARY_FAILURE) {
          props.showToast('Failed to retrieve authorization info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
    
        if (employeeInfoResp.type === GET_EMPLOYEE_INFO_FAILURE) {
          props.showToast('Failed to retrieve employee info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
    
        if (soInfoResp.type === GET_SO_INFO_FAILURE) {
          props.showToast('Failed to retrieve significant other info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
            
        if (authDateSummaryResp.type === GET_AUTH_DATE_SUMMARY_FAILURE) {
          props.showToast('Failed to retrieve auth summary info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (communicationInfoResp.type === GET_COMMUNICATION_INFO_FAILURE) {
          props.showToast('Failed to retrieve employee communication info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (departureResp.type === GET_DEPARTURE_ADDRESS_FAILURE) {
          props.showToast('Failed to retrieve departure address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (destinationResp.type === GET_DESTINATION_ADDRESS_FAILURE) {
          props.showToast('Failed to retrieve destination address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
          props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (teamResp.type === GET_AUTH_TEAM_FAILURE) {
          props.showToast('Failed to retrieve auth team, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authServiceResp.type === GET_AUTH_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve auth services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
          props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (userSettingsResp.type === GET_USER_SETTINGS_FAILURE) {
          props.showToast('Failed to retrieve employee settings info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (brokerAgentsResp.type === GET_ALL_BROKER_AGENTS_FAILURE) {
          props.showToast('Failed to retrieve brokers/agents, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (costEstimateResp.type === GET_COST_ESTIMATES_FAILURE) {
          props.showToast('Failed to retrieve cost estimates info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (candidateResp.type === GET_CANDIDATE_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve candidate services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (internResp.type === GET_INTERN_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve intern services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (candidateTripsResp.type === GET_ALL_CANDIDATE_TRIPS_FAILURE) {
          props.showToast('Failed to retrieve candidate trips, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

      }
    }

    fetchData();
  }, [clientInfo, details]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && clientInfo &&
          <Grid>
            <CandidateServices details={details} id={props.match.params.id} brokerAgents={props.brokerAgents} history={props.history} location={props.location}/>
            <InternServices details={details} id={props.match.params.id} brokerAgents={props.brokerAgents} history={props.history} location={props.location} />
          </Grid>
        }
        {isLoading && <FullscreenSpinner />}

      </Box>
    </ThemeProvider>
  );

};

CandidateServicesView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { authorizationDetails, brokerAgents, isLoading } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    brokerAgents,
    unmappedClients,
    isLoading,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getEmployeeInfo,
    getSOInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    setAuthId,
    setLoadingComplete,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getBrokerAgents,
    getCandidateServices,
    getInternServices,
    getCandidateTrips,
    getCostEstimates,
  }),
)(CandidateServicesView);