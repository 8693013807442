/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';

import {
  Box, Button, Card, CardContent, Checkbox, Dialog, DialogActions, FormControl, FormControlLabel, Grid, InputAdornment, MenuItem, OutlinedInput, Select, TextField, Tooltip, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import { updateVoucherSummary } from 'modules/expenses/expenses.actions';
import { InputLabel } from '@material-ui/core';
import { formatDateForInput } from 'utilities/common';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));

const VoucherDetailsCard = (props) => {

  const [showCurrencyModal, setShowCurrencyModal] = useState(false);

  const { voucherInfo, isReadOnly, updateVoucherInfo } = props;

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const batchOptions = [
    { title: 'ACH/WIRE', id: 1 },
    { title: 'AP TRACKING', id: 2 },
    { title: 'BILL TO EE - CHECK', id: 3 },
    { title: 'BILL TO EE - INTL', id: 4 },
    { title: 'BILL TO EE - ACH/WIRE', id: 5 },
    { title: 'BILL TO EE - TRACKING', id: 7 },
    { title: 'BLACKOUT', id: 8 },
    { title: 'CHECK', id: 9 },
    { title: 'CLIENT INTEREST', id: 10 },
    { title: 'CODING CORRECTION', id: 11 },
    { title: 'DEPOSIT', id: 12 },
    { title: 'EM TRACKING', id: 13 },
    { title: 'FEE', id: 14 },
    { title: 'INTERNATIONAL', id: 15 },
    { title: 'JOURNAL ENTRY', id: 16 },
    { title: 'ON HOLD', id: 17 },
  ];

  const notPayableOptions = [
    { title: 'Backout/Re-enter', id: 1 },
    { title: 'Cost Avoidance', id: 2 },
    { title: 'Paid by Client Through Payroll', id: 3 },
    { title: 'Tracking', id: 4 },
    { title: 'N/A', id: 5 },
  ];

  const voucherTypeOptions = [
    { title: 'AP Voucher', id: 1 },
    { title: 'AR Voucher', id: 2 },
    { title: 'AFCR Voucher', id: 3 },
    { title: 'Refund Voucher', id: 4 },
  ];

  const payInOptions = [
    { title: 'USD', id: 1, fullDesc: 'United States Dollar' },
    { title: 'CAD', id: 2, fullDesc: 'Canadian Dollar' },
    { title: 'EUR', id: 3, fullDesc: 'Euro' },
    { title: 'GBP', id: 4, fullDesc: 'Great Britain Pound' },
    { title: 'JPY', id: 5, fullDesc: 'Japanese Yen' },
    { title: 'CHF', id: 6, fullDesc: 'Swiss Franc' },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '10px' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Voucher Details" key="Voucher" placement="right">
                  <FontAwesomeIcon icon={['fas', 'file-contract']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Voucher Details
                </Typography>
              </div>
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '10px', paddingTop: '0px' }}>
            <Grid container spacing={1} columns={5}>
                {voucherInfo.payToVendor && (
                    <Grid item md={1}>
                        <Box>
                            <InputLabel>Invoice Number</InputLabel>
                            <TextField
                                key={'invoiceNumber'}
                                label={''}
                                name={'invoiceNumber'}
                                required={false}
                                disabled={isReadOnly}
                                value={voucherInfo.invoiceNumber}
                                sx={{ maxWidth: '360px' }}
                                type="text"
                                InputLabelProps={{ shrink: true }}
                                onChange={(e) =>
                                    updateVoucherInfo({ ...voucherInfo, invoiceNumber: e.target.value })
                                }
                            />
                        </Box>
                    </Grid>
                )}
                <Grid item md={1}>
                    <Box>
                        <InputLabel>Entry Date</InputLabel>
                        <TextField
                            key={'entryDate'}
                            label={''}
                            name={'entryDate'}
                            required={false}
                            disabled={isReadOnly}
                            value={formatDateForInput(voucherInfo.entryDate)}
                            sx={{ maxWidth: '360px' }}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                updateVoucherInfo({ ...voucherInfo, entryDate: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box>
                        <InputLabel>Voucher Type</InputLabel>
                        <Select
                            labelId={'voucherType'}
                            key={'voucherType'}
                            label={'Voucher Type'}
                            name={'voucherType'}
                            value={voucherInfo.voucherType}
                            sx={{ maxWidth: '180px', minWidth: '180px' }}
                            onChange={(e) =>
                                updateVoucherInfo({ ...voucherInfo, voucherType: e.target.value })
                            }
                            disabled={isReadOnly}
                            input={<OutlinedInput name={'Voucher Type'} labelWidth={100} />}
                        >
                            {voucherTypeOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                    {opt.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box>
                        <InputLabel>Batch Type</InputLabel>
                        <Select
                            labelId={'batchType'}
                            key={'batchType'}
                            label={''}
                            name={'batchType'}
                            value={voucherInfo.batchType}
                            sx={{ maxWidth: '180px', minWidth: '180px' }}
                            disabled={isReadOnly}
                            onChange={(e) =>
                                updateVoucherInfo({ ...voucherInfo, batchType: e.target.value })
                            }
                        >
                            {batchOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                    {opt.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box>
                        <InputLabel>Amount</InputLabel>
                        <TextField
                            key={'amount'}
                            label={''}
                            name={'amount'}
                            required={false}
                            disabled={isReadOnly}
                            value={voucherInfo.amount}
                            sx={{ maxWidth: '360px' }}
                            type="number"
                            InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                            }}
                            allowDecimal={true}
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                updateVoucherInfo({ ...voucherInfo, amount: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{paddingLeft: '5px'}}>
                        <div className={classes.mb2}>
                            <Typography variant="subtitle2" sx={{ color: '#777667', fontWeight: 400, fontSize: '1rem' }}>
                                Currency
                            </Typography>
                        </div>
                        <div className={classes.mb2}>
                            <Typography variant="body2" sx={{ color: '#41B7C0', fontWeight: 500, fontSize: '1rem', cursor: 'pointer' }} onClick={() => setShowCurrencyModal(true)}>
                                {`${payInOptions.find((p) => p.id === voucherInfo.receiptCurrency)?.title ?? 'USD'}  ->  ${payInOptions.find((p) => p.id === voucherInfo.payInCurrency)?.title ?? 'USD'}`}
                            </Typography>
                        </div>
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box>
                        <InputLabel>Due Date</InputLabel>
                        <TextField
                            key={'dueDate'}
                            label={''}
                            name={'dueDate'}
                            required={false}
                            disabled={isReadOnly}
                            value={formatDateForInput(voucherInfo.dueDate)}
                            sx={{ maxWidth: '360px' }}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                updateVoucherInfo({ ...voucherInfo, dueDate: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box>
                        <InputLabel>Invoice Date</InputLabel>
                        <TextField
                            key={'invoiceDate'}
                            label={''}
                            name={'invoiceDate'}
                            required={false}
                            disabled={isReadOnly}
                            value={formatDateForInput(voucherInfo.invoiceDate)}
                            sx={{ maxWidth: '360px' }}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                updateVoucherInfo({ ...voucherInfo, invoiceDate: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box>
                        <InputLabel>Payroll Date</InputLabel>
                        <TextField
                            key={'payrollDate'}
                            label={''}
                            name={'payrollDate'}
                            required={false}
                            disabled={isReadOnly}
                            value={formatDateForInput(voucherInfo.payrollDate)}
                            sx={{ maxWidth: '360px' }}
                            type="date"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                updateVoucherInfo({ ...voucherInfo, payrollDate: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box>
                        <InputLabel>Not Payable</InputLabel>
                        <Select
                            labelId={'notPayable'}
                            key={'notPayable'}
                            label={''}
                            name={'notPayable'}
                            value={voucherInfo.notPayable}
                            sx={{ maxWidth: '180px', minWidth: '180px' }}
                            disabled={isReadOnly}
                            onChange={(e) =>
                                updateVoucherInfo({ ...voucherInfo, notPayable: e.target.value })
                            }
                        >
                            {notPayableOptions.map((opt) => (
                                <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                    {opt.title}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </Grid>
                <Grid item md={4}>
                    <Box>
                        <InputLabel>Description</InputLabel>
                        <TextField
                            key={'description'}
                            label={''}
                            name={'description'}
                            required={false}
                            disabled={isReadOnly}
                            value={voucherInfo.description}
                            sx={{ minWidth: '360px', width: '100%' }}
                            type="text"
                            InputLabelProps={{ shrink: true }}
                            onChange={(e) =>
                                updateVoucherInfo({ ...voucherInfo, description: e.target.value })
                            }
                        />
                    </Box>
                </Grid>
                <Grid item md={1}>
                    <Box sx={{paddingTop: '8px'}}>
                        <FormControl>
                            <FormControlLabel
                                key={'rushPayment'}
                                name={'rushPayment'}
                                label={'Rush Payment'}
                                labelPlacement="end"
                                control={<Checkbox disabled={isReadOnly} />}
                                checked={voucherInfo.rushPayment}
                                sx={{ minWidth: '180px', maxWidth: '360px', marginLeft: '0px', marginRight: '8px' }}
                                disabled={isReadOnly}
                                onClick={(e) =>
                                    { updateVoucherInfo({ ...voucherInfo, rushPayment: e.target.checked });}
                                }
                            />   
                        </FormControl>
                    </Box>
                </Grid>
            </Grid>
          </CardContent>
        </Box>
      </Card>

      <Dialog open={showCurrencyModal} fullWidth={false} maxWidth={'lg'}>
            <Box sx={{ padding: '25px'}}>
                <Typography variant="h5" >
                    Currency Selection
                </Typography>
            </Box>
            <Box sx={{ padding: '25px', paddingTop: '0px'}}>
                <Typography variant="body2" >
                    Select from the currency options below.
                </Typography>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                <Box sx={{marginRight: '20px', marginTop: '16px'}}>
                    <Typography variant="body2" >
                        Receipt Currency
                    </Typography>
                </Box>
                <Box sx={{marginRight: '20px'}}>
                    <Select
                        labelId={'receiptCurrency'}
                        key={'receiptCurrency'}
                        label={''}
                        name={'receiptCurrency'}
                        value={voucherInfo.receiptCurrency ?? 1}
                        sx={{ maxWidth: '180px', minWidth: '180px' }}
                        disabled={isReadOnly}
                        onChange={(e) =>
                            updateVoucherInfo({ ...voucherInfo, receiptCurrency: e.target.value })
                        }
                    >
                        {payInOptions.map((opt) => (
                            <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                {opt.title}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box sx={{ padding: '25px', paddingTop: '16px'}}>
                    <Typography variant="body2" >
                        {payInOptions.find((c) => c.id === voucherInfo.receiptCurrency)?.fullDesc}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                <Box sx={{marginRight: '20px', marginTop: '16px'}}>
                    <Typography variant="body2" >
                        Pay In Currency
                    </Typography>
                </Box>
                <Box sx={{marginRight: '20px'}}>
                    <Select
                        labelId={'payInCurrency'}
                        key={'payInCurrency'}
                        label={''}
                        name={'payInCurrency'}
                        value={voucherInfo.payInCurrency ?? 1}
                        sx={{ maxWidth: '180px', minWidth: '180px' }}
                        disabled={isReadOnly}
                        onChange={(e) =>
                            updateVoucherInfo({ ...voucherInfo, payInCurrency: e.target.value })
                        }
                    >
                        {payInOptions.map((opt) => (
                            <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                {opt.title}
                            </MenuItem>
                        ))}
                    </Select>
                </Box>
                <Box sx={{ padding: '25px', paddingTop: '16px'}}>
                    <Typography variant="body2" >
                        {payInOptions.find((c) => c.id === voucherInfo.payInCurrency)?.fullDesc}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                <Box sx={{marginRight: '20px', marginTop: '16px'}}>
                    <Typography variant="body2" >
                        {`${payInOptions.find((c) => c.id === voucherInfo.receiptCurrency)?.title}  ->  ${payInOptions.find((c) => c.id === voucherInfo.payInCurrency)?.title}`}
                    </Typography>
                </Box>
                <Box sx={{marginRight: '20px'}}>
                    <TextField
                        key={'currencyExchangeRate'}
                        label={''}
                        name={'currencyExchangeRate'}
                        required={false}
                        disabled={isReadOnly}
                        value={voucherInfo.currencyExchangeRate}
                        sx={{ maxWidth: '360px' }}
                        type="number"
                        allowDecimal={true}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) =>
                            updateVoucherInfo({ ...voucherInfo, currencyExchangeRate: e.target.value })
                        }
                    />
                </Box>
                <Box sx={{marginRight: '20px', marginTop: '16px'}}>
                    <Typography variant="body2" >
                        {`${payInOptions.find((c) => c.id === voucherInfo.receiptCurrency)?.title}  ->  ${payInOptions.find((c) => c.id === voucherInfo.receiptCurrency)?.title}`}
                    </Typography>
                </Box>
                <Box sx={{marginRight: '20px', marginTop: '16px'}}>
                    <Typography variant="body2" >
                        1.00
                    </Typography>
                </Box>
            </Box>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={() => setShowCurrencyModal(false)} sx={{ color: '#41B7C0', '&:hover': { color: '#3d959c' } }}>Cancel</Button>
                <Button onClick={() => setShowCurrencyModal(false)} color="primary" sx={{ backgroundColor: '#41B7C0', '&:hover': { backgroundColor: '#3d959c' } }} type="submit" variant="contained" disabled={false}>
                    Set Selection
                </Button>
            </DialogActions>
        </Dialog>
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateVoucherSummary,
    showToast,
  }),
)(VoucherDetailsCard);