/* eslint-disable array-callback-return */
/* eslint-disable no-shadow */
import { Accordion, AccordionDetails, AccordionSummary, Box, useTheme, withStyles, withTheme } from '@material-ui/core';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import CrudTable from '../clients/details/crudTable.component';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useState } from 'react';
import Spinner from 'modules/common/spinner.component';
import classNames from 'classnames';
import { deleteExpenseLine, getExpenseLines, postExpenseLine, updateExpenseLine, getExpenseLinesById } from 'modules/expenses/expenses.actions';
import { DELETE_EXPENSE_LINE_FAILURE, GET_EXPENSE_LINES_FAILURE, POST_EXPENSE_LINE_FAILURE, UPDATE_EXPENSE_LINE_FAILURE } from 'modules/expenses/types';
import { EXPENSE_LINE_APPROVAL_STATUS, SERVICE_FILTER_TYPES } from 'modules/authorizations/details/expenses/expenseConstants';

const styles = (theme) => {
  return {
    row: {
      backgroundColor: theme.palette.common.white,
    },
    inputGroup: {
      display: 'inline-flex',
      justifyContent: 'space-between',
    },
    root: {
      maxWidth: 170,
      marginRight: 10,
    },
    extRoot: {
      width: 115,
      marginRight: 10,
    },
    primaryContactRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      width: '100%',
    },
    primaryText: {
      paddingTop: 12,
      paddingRight: 5,
    },
    newRow: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: 1050,
    },
  };
};

const ExpenseLineTable = (props) => {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);

  const statusOptions = [
    'Not Viewed',
    'Not Viewed with Error',
    'On Hold',
    'Viewed',
  ];

  const catOptions = ['EA', 'RC', 'AC', 'PC', 'HHG', 'TRIP', 'HH', 'TL'];

  const payInOptions = ['USD', 'CAD', 'EUR', 'GBP', 'JPY', 'CHF',];

  const expenseCodeSearchInfo = {
    searchingColumns: [{ name: 'expenseCodeCode', title: 'Expense Code' }, { name: 'expenseCodeDescription', title: 'Description' }, { name: 'classification', title: 'Classification' }, { name: 'category', title: 'Category' },],
    searchingColumnExtensions: [{ columnName: 'expenseCodeCode', width: 150 }, { columnName: 'expenseCodeDescription', width: 250 }, { columnName: 'classification', width: 200 }, { columnName: 'category', width: 200 },],
    searchingRows: props.expenseCodes,
    idKey: 'expenseCodeId',
    nameKey: 'expenseCodeCode',
  };

  const expenseSummarySearchInfo = {
    searchingColumns: [{ name: 'expenseId', title: 'Expense Id' }, { name: 'authorizationId', title: 'Authorization Id' }, { name: 'description', title: 'Description' }, { name: 'batchType', title: 'Batch Type' }],
    searchingColumnExtensions: [{ columnName: 'expenseId', width: 150 }, { columnName: 'authorizationId', width: 150 }, { columnName: 'description', width: 200 }, { columnName: 'batchType', width: 150 }],
    searchingRows: props.expenseSummaries,
    idKey: 'expenseId',
    nameKey: 'description',
  };

  const adjustedColumns = [
    { header: 'Expense', accessorKey: 'expenseId', nameKey: 'description', nameValue: props.expenseSummaries ? props.expenseSummaries.description : '', type: 'advanced-search', searchInfo: expenseSummarySearchInfo, required: true, value: props.expenseSummaries ? props.expenseSummaries.expenseId : 0, size: 70 },
    { accessorKey: 'status', header: 'Status', type: 'dropdown', options: statusOptions, required: true, size: 50, },
    { accessorKey: 'cat', header: 'CAT', type: 'dropdown', options: catOptions, required: true, size: 50 },
    { accessorKey: 'description', header: 'Description', type: 'text', required: true, size: 80 },
    { accessorKey: 'payOption', header: 'Pay Option', type: 'text', required: true, size: 50, hideInTable: true },
    { accessorKey: 'amount', header: 'Amount', type: 'money', required: true, size: 50 },
    { accessorKey: 'approved', header: 'Approved', type: 'money', required: true, size: 50, hideInTable: true },
    { accessorKey: 'denied', header: 'Denied', type: 'money', required: true, size: 50, hideInTable: true },
    { accessorKey: 'hasAttachment', header: 'Has Attachment', type: 'checkbox', required: false, size: 50, hideInTable: true },
    { accessorKey: 'reference', header: 'Reference', type: 'text', required: false, size: 50, hideInTable: true },
    { header: 'Expense Code', accessorKey: 'expenseCodeId', nameKey: 'expenseCodeCode', nameValue: props.expenseCodes ? props.expenseCodes.expenseCodeCode : '', type: 'advanced-search', searchInfo: expenseCodeSearchInfo, required: true, value: props.expenseCodes ? props.expenseCodes.expenseCodeId : 0, size: 70 },
    { accessorKey: 'expenseCodeDescription', header: 'Expense Code Description', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'glNumber', header: 'Gl Number', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'reportDate', header: 'Report Date', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'deniedReason', header: 'Denied Reason', type: 'text', required: false, size: 50, hideInTable: true },
    { accessorKey: 'expenseDate', header: 'Expense Date', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'payrollDate', header: 'Payroll Date', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'balance', header: 'Balance', type: 'money', required: false, size: 50, hideInTable: true },
    { accessorKey: 'dueDate', header: 'Due Date', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'fromDate', header: 'From Date', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'toDate', header: 'To Date', type: 'date', required: false, size: 50, hideInTable: true },
    { accessorKey: 'currency', header: 'Currency', type: 'dropdown', options: payInOptions, required: false, size: 50, hideInTable: true },
    { accessorKey: 'service', header: 'Service', type: 'dropdown', options: SERVICE_FILTER_TYPES, required: false, size: 50, hideInTable: true },
    { accessorKey: 'approvalStatus', header: 'Is Expense Approved', type: 'dropdown', options: EXPENSE_LINE_APPROVAL_STATUS, required: false, size: 50, hideInTable: true },
  ];


  const updateRow = async (ogRow, values) => {

    const matchingExpense = props.expenseSummaries.find((p) => p.expenseId === values.expenseId);
    const matchingExpenseCode = props.expenseCodes.find((c) => c.expenseCodeCode === values.expenseCodeCode)
    const checkValues = {
      expenseLineId: 1,
      authorizationId: matchingExpense.authorizationId,
      expenseId: matchingExpense.expenseId,
      status: values.status ? values.status : '',
      cat: values.cat ? values.cat : '',
      description: values.description ? values.description : '',
      payOption: values.payOption ? values.payOption : '',
      amount: values.amount ? values.amount : '',
      approved: values.approved ? values.approved : '',
      denied: values.denied ? values.denied : '',
      hasAttachment: values.hasAttachment ? values.hasAttachment : false,
      reference: values.reference ? values.reference : '',
      expenseCodeId: matchingExpenseCode ? matchingExpenseCode.expenseCodeId : 0,
      expenseCode: matchingExpenseCode ? matchingExpenseCode.expenseCodeCode : '',
      expenseCodeDescription: matchingExpenseCode ? matchingExpenseCode.expenseCodeDescription : '',
      glNumber: values.glNumber ? values.glNumber : '',
      reportDate: values.reportDate ? values.reportDate : null,
      deniedReason: values.deniedReason ? values.deniedReason : '',
      expenseDate: values.expenseDate ? values.expenseDate : null,
      payrollDate: values.payrollDate ? values.payrollDate : null,
      balance: values.balance ? values.balance : '',
      dueDate: values.dueDate ? values.dueDate : null,
      fromDate: values.fromDate ? values.fromDate : null,
      toDate: values.toDate ? values.toDate : null,
      currency: values?.currency,
      service: values?.service,
      approvalStatus: values?.approvalStatus
    }

    const resp = await props.updateExpenseLine(checkValues);
    if (resp.type === UPDATE_EXPENSE_LINE_FAILURE) {
      return false;
    } else {
      const getResp = await props.getExpenseLines();
      if (getResp.type === GET_EXPENSE_LINES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const createRow = async (values) => {
    const matchingExpense = props.expenseSummaries.find((p) => p.expenseId === values.expenseId);
    const matchingExpenseCode = props.expenseCodes.find((c) => c.expenseCodeCode === values.expenseCodeCode)

    const checkValues = {
      authorizationId: matchingExpense.authorizationId,
      expenseId: matchingExpense.expenseId,
      status: values.status ? values.status : '',
      cat: values.cat ? values.cat : '',
      description: values.description ? values.description : '',
      payOption: values.payOption ? values.payOption : '',
      amount: values.amount ? values.amount : '',
      approved: values.approved ? values.approved : '',
      denied: values.denied ? values.denied : '',
      hasAttachment: values.hasAttachment ? values.hasAttachment : false,
      reference: values.reference ? values.reference : '',
      expenseCodeId: matchingExpenseCode ? matchingExpenseCode.expenseCodeId : 0,
      expenseCode: matchingExpenseCode ? matchingExpenseCode.expenseCodeCode : '',
      expenseCodeDescription: matchingExpenseCode ? matchingExpenseCode.expenseCodeDescription : '',
      glNumber: values.glNumber ? values.glNumber : '',
      reportDate: values.reportDate ? values.reportDate : null,
      deniedReason: values.deniedReason ? values.deniedReason : '',
      expenseDate: values.expenseDate ? values.expenseDate : null,
      payrollDate: values.payrollDate ? values.payrollDate : null,
      balance: values.balance ? values.balance : '',
      dueDate: values.dueDate ? values.dueDate : null,
      fromDate: values.fromDate ? values.fromDate : null,
      toDate: values.toDate ? values.toDate : null,
      currency: values?.currency,
      service: values?.service,
      approvalStatus: values?.approvalStatus
    }

    const resp = await props.postExpenseLine(checkValues);
    if (resp.type === POST_EXPENSE_LINE_FAILURE) {
      return false;
    } else {
      const getResp = await props.getExpenseLines();
      if (getResp.type === GET_EXPENSE_LINES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  const deleteRow = async (expenseLine) => {
    const id = expenseLine.original.expenseLineId;
    const resp = await props.deleteExpenseLine(id);
    if (resp.type === DELETE_EXPENSE_LINE_FAILURE) {
      return false;
    } else {
      const getResp = await props.getExpenseLines();
      if (getResp.type === GET_EXPENSE_LINES_FAILURE) {
        return false;
      } else {
        return true;
      }
    }
  };

  return (
    <Accordion
      expanded={isOpen}
      onChange={() => {
        setIsOpen(!isOpen);
      }}
      classes={{ root: classNames(props.classes.accordionStyleContacts) }}
    >
      <AccordionSummary
        expandIcon={
          <Box color={theme.palette.secondary.main}>
            <ExpandMoreIcon color="inherit" />
          </Box>
        }
      >
        <div className={props.classes.newRow}>
          <h2>Expense Lines</h2>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        {!props.isLoadingExpenseLines && props.expenseLines && (
          <div>
            <CrudTable
              editingMode={'modal'} // Can be changed to 'row' to edit inline
              columns={adjustedColumns}
              rows={props.expenseLines}
              updateRow={updateRow}
              createRow={createRow}
              deleteRow={deleteRow}
            />
          </div>
        )}
        {props.isLoadingExpenseLines && <Spinner />}
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state) => {
  const { expenseLines, isLoadingExpenseLines, expenseSummaries } = state.expenses;
  const { authorizationSummaries, expenseCodes } = state.authorizations; 
  return {
    expenseLines,
    isLoadingExpenseLines,
    expenseSummaries,
    authorizationSummaries,
    expenseCodes,
  };
};

export default compose(
  withStyles(styles),
  withTheme,
  connect(mapStateToProps, {
    getExpenseLines,
    getExpenseLinesById,
    updateExpenseLine,
    postExpenseLine,
    deleteExpenseLine,
  }),
)(ExpenseLineTable);
