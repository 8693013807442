import { LocalDate } from 'js-joda';
import { REQUIRED_NUMBER, REQUIRED_STRING } from 'modules/common/constants';
import { keys } from 'lodash';
import { object, string } from 'yup';

export const HEADER_HEIGHT = 110;
export const HEADER_HEIGHT_SHRINK = 94;

export const MAX_AGE = 120;

export const METADATA_ITEM_SHAPE = object().shape({
  id: REQUIRED_NUMBER,
  description: REQUIRED_STRING,
});

// this doesn't work as an arrow function
export function isAfterDate(key) {
  return function (value) {
    const compareToDate = this.parent[key];
    if (!compareToDate || !value) {
      return true;
    }

    try {
      const authDate = LocalDate.parse(compareToDate).atStartOfDay();
      const newJobDate = LocalDate.parse(value).atStartOfDay();
      return newJobDate.compareTo(authDate) > 0;
    } catch (err) {
      return false;
    }
  };
}

export const afterAuthDateSchema = string().test(
  'is-after-auth-date',
  'Should be after Authorization Date',
  isAfterDate('authorizationDate'),
);

// TODO
// const validDateSchema = string().test(
//   'is-valid-date',
//   'Invalid date'
//   function (value) {
//     if (!value) {
//       return true;
//     }
//   }
// );

export const AUTHORIZATION_INTAKE = 'AUTHORIZATION_INTAKE';
export const BENEFITS_COUNSELING = 'BENEFITS_COUNSELING';
export const AUTHORIZATION_COMMENTS = 'AUTHORIZATION_COMMENTS';
export const SUMMARY = 'SUMMARY';
export const TAB_ORDER = [AUTHORIZATION_INTAKE, BENEFITS_COUNSELING, AUTHORIZATION_COMMENTS, SUMMARY];

const formStructure = {
  [AUTHORIZATION_INTAKE]: [{
    group: 'primaryInformation',
    label: 'Primary Information',
  }, {
    group: 'authorizationInformation',
    label: 'Authorization Information',
  }, {
    group: 'familyInformation',
    label: 'Family Information',
  }, {
    group: 'departureProperty',
    label: 'Departure Property',
  }, {
    group: 'hhgInformation',
    label: 'HHG Information',
  }, {
    group: 'temporaryLiving',
    label: 'Temporary Living',
  }, {
    group: 'newDestination',
    label: 'New Destination',
  }],
  [BENEFITS_COUNSELING]: [{
    group: 'relocationAllowance',
    label: 'Relocation Allowance',
  }, {
    group: 'candidateInternInfo',
    label: 'Candidate/Intern',
  }, {
    group: 'houseHunting',
    label: 'House Hunting',
  }, {
    group: 'homesaleProcess',
    label: 'Homesale Process',
  }, {
    group: 'finalMove',
    label: 'Final Move',
  }, {
    group: 'temporaryLivingTimeline',
    label: 'Temp Living',
  }, {
    group: 'householdGoods',
    label: 'Household Goods',
  }, {
    group: 'spousalAssistance',
    label: 'Spousal Assistance',
  }, {
    group: 'miscellaneous',
    label: 'Miscellaneous',
  }],
  [SUMMARY]: [{
    group: 'vendorLetters',
    label: 'Vendor Letters',
  }],
};

// flatten section map to make search easier
export const SECTIONS = keys(formStructure).reduce((results, tab) => {
  return [
    ...results,
    ...formStructure[tab].map((group) => ({ ...group, tab })),
  ];
}, []);

export const ValidationMode = Object.freeze({
  Autosave: 'AUTOSAVE',
  Strict: 'STRICT',
});
