/* eslint-disable no-case-declarations */
import {
  DELETE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  DELETE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  DELETE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
  GET_AUTH_PETS_FAILURE,
  GET_AUTH_PETS_REQUEST,
  GET_AUTH_PETS_SUCCESS,
  GET_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  GET_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  GET_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
  POST_AUTH_PETS_FAILURE,
  POST_AUTH_PETS_REQUEST,
  POST_AUTH_PETS_SUCCESS,
  POST_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  POST_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  POST_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS,
  UPDATE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE,
  UPDATE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST,
  UPDATE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS
} from '../types/temporaryLivingTypes';

const handleTemporaryLivingReducer = (draft, action) => {
  if (!draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};

  switch (action.type) {
    case GET_TEMPORARY_LIVING_ASSIGNMENT_REQUEST:
    case GET_AUTH_PETS_REQUEST:
    case POST_AUTH_PETS_REQUEST:
    case DELETE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST:
      draft.isLoading = true;
      break;

    case GET_AUTH_PETS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authPets = action.response;
      draft.isLoading = false;
      break;

    case POST_AUTH_PETS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authPets.push(action.response);
      draft.isLoading = false;
      break;

    case GET_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS: {
      const authId = draft.currentAuthId;
      if (!draft.authorizationDetails[authId]) {
        draft.authorizationDetails[authId] = { temporaryLivingAssignments: [] };
      }

      draft.authorizationDetails[authId].temporaryLivingAssignments = action.response || [];
      draft.isLoading = false;
      break;
    }

    case UPDATE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS: {
      const assignments = draft.authorizationDetails[draft.currentAuthId]?.temporaryLivingAssignments;
      if (assignments) {
        const indexToUpdate = assignments.findIndex(assignment => assignment.temporaryLivingId === action.response.temporaryLivingId);
        if (indexToUpdate !== -1) {
          draft.authorizationDetails[draft.currentAuthId].temporaryLivingAssignments[indexToUpdate] = action.response;
        }
      }
      draft.authorizationDetails[draft.currentAuthId].tempLivingAssignmentIsLoading = false;
      break;
    }

    case DELETE_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS: {
      draft.isLoading = false;
      break;
    }

    case POST_TEMPORARY_LIVING_ASSIGNMENT_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].temporaryLivingAssignments.push(action.response);
      draft.authorizationDetails[draft.currentAuthId].tempLivingAssignmentIsLoading = false;
      break;

    case POST_TEMPORARY_LIVING_ASSIGNMENT_REQUEST:
    case UPDATE_TEMPORARY_LIVING_ASSIGNMENT_REQUEST:
      draft.authorizationDetails[draft.currentAuthId].tempLivingAssignmentIsLoading = true;
      break;

    case POST_TEMPORARY_LIVING_ASSIGNMENT_FAILURE:
    case UPDATE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE:
      draft.authorizationDetails[draft.currentAuthId].tempLivingAssignmentIsLoading = false;
      break;

    case GET_AUTH_PETS_FAILURE:
    case GET_TEMPORARY_LIVING_ASSIGNMENT_FAILURE:
    case POST_AUTH_PETS_FAILURE:
    case DELETE_TEMPORARY_LIVING_ASSIGNMENT_FAILURE:
      draft.isLoading = false;
      break;

    default:
      break;
  }
  return draft;
};

export default handleTemporaryLivingReducer;
