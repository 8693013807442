/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Card, CardContent, Fab, Grid, Tooltip, Typography,
} from '@mui/material';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import AssignmentTripExtensionsCard from './assignmentTripExtensionsCard.component';
import OverviewGlobalCard from './overviewGlobalCard.component';
import PropTypes from 'prop-types';
import React from 'react';
import StatusStepperGlobal from './statusStepperGlobal.component';
import SummaryCard from '../summaryCard.component';
import VisaPermitCard from './visaPermitCard.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const AssignmentLanding = (props) => {    
  const { details, id, isReadOnly } = props;

  return (
    <Box>
      <StatusStepperGlobal globalExtensions={details.globalExtensions} />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          <OverviewGlobalCard globalInfo={details.global} id={id} isReadOnly={isReadOnly}/>
          <Box sx={{ paddingTop: '16px' }}>
            <VisaPermitCard globalInfo={details.global} id={id} isReadOnly={isReadOnly}/>
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <AssignmentTripExtensionsCard globalTrips={details.globalTrips} globalExtensions={details.globalExtensions} id={id} isReadOnly={isReadOnly}/>
          </Box>
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          <SummaryCard dateSummary={details.dateSummary} id={id} hideEdit={true} />
          <Box sx={{ paddingTop: '16px' }}>
            <Card>
              <Box>
                <CardContent>
                  <div className="row justify-space-between align-center">
                    <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title="Estimates" key="Estimates" placement="right">
                        <FontAwesomeIcon icon={['fas', 'person']} size="2x" />
                      </Tooltip>
                      <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                        Family Info
                      </Typography>
                    </Box>
                    {!isReadOnly && <Fab color="primary" size="small">
                      <FontAwesomeIcon size="1x" icon={['fas', 'pen']} color={'white'} />
                    </Fab>
                    }
                  </div>
                </CardContent>
              </Box>
            </Card>
          </Box> 
        </Grid>
      </Grid>
    </Box>
  );

};

AssignmentLanding.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
  }),
)(AssignmentLanding);