/* eslint-disable react-hooks/exhaustive-deps */
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import React from 'react';

import {
  Box, Card, CardContent, Checkbox, FormControl, FormControlLabel, MenuItem, Select, TextField, Tooltip, Typography,
} from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { makeStyles } from '@material-ui/styles';
import { updateVoucherSummary } from 'modules/expenses/expenses.actions';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
  chip: {
    marginRight: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  chipClickable: {
    cursor: 'pointer',
  },
  iconOverlayImg: {
    position: 'absolute',
    zIndex: '1 !important',
  },
  spacingX: {
    marginRight: theme.spacing(1),
  },
  footer: {
    height: 15,
    marginBottom: theme.spacing(2),
  },
  mb2: {
    marginBottom: '4px',
    marginRight: '16px',
  },
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
    paddingLeft: '50px',
    paddingRight: '50px',
  },
  labelText: {
    minWidth: 350,
    marginBottom: 0,
  },
}));

const PaymentInfoCard = (props) => {
  const { voucherInfo, isReadOnly, updateVoucherInfo } = props;

  const classes = useStyles();

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const batchOptions = [
    { title: 'ACH/WIRE', id: 1 },
    { title: 'AP TRACKING', id: 2 },
    { title: 'BILL TO EE - CHECK', id: 3 },
    { title: 'BILL TO EE - INTL', id: 4 },
    { title: 'BILL TO EE - ACH/WIRE', id: 5 },
    { title: 'BILL TO EE - TRACKING', id: 7 },
    { title: 'BLACKOUT', id: 8 },
    { title: 'CHECK', id: 9 },
    { title: 'CLIENT INTEREST', id: 10 },
    { title: 'CODING CORRECTION', id: 11 },
    { title: 'DEPOSIT', id: 12 },
    { title: 'EM TRACKING', id: 13 },
    { title: 'FEE', id: 14 },
    { title: 'INTERNATIONAL', id: 15 },
    { title: 'JOURNAL ENTRY', id: 16 },
    { title: 'ON HOLD', id: 17 },
  ];

  const bankOptions = [
    { title: 'BMO Harris Bank - USD - ****9999 (Dft/ACH)', id: 1 },
    { title: 'Wells Fargo Bank - USD - ****9999 (Dft/ACH)', id: 2 },
    { title: 'PNC Bank - USD - ****9999 (Dft/ACH)', id: 3 },
    { title: 'US Bank - USD - ****9999 (Dft/ACH)', id: 4 },
    { title: 'Bank of America - USD - ****9999 (Dft/ACH)', id: 5 },
    { title: 'Chase Bank - USD - ****9999 (Dft/ACH)', id: 6 },
  ];

  // useEffect(() => {
  //   console.log(voucherInfo);
  //   updateVoucherInfo(voucherInfo);
  // }, [voucherInfo]);

  // useEffect(() => {
  //   if (voucherInfo.batchType !== voucherInfo.batchType) {
  //       updateVoucherInfo({ ...voucherInfo, batchType: voucherInfo.batchType });
  //   }
  // }, [voucherInfo]);

  return (
    <ThemeProvider theme={theme}>
      <Card className={classes.card} sx={{ marginTop: '10px' }}>
        <Box>
          <CardContent className={classes.cardContainer}>
            <div className="row justify-space-between align-center">
              <div className={classes.iconRow}>
                <Tooltip title="Voucher Details" key="Voucher" placement="right">
                  <FontAwesomeIcon icon={['fas', 'dollar-sign']} size="2x" />
                </Tooltip>
                <Typography variant="h5" sx={{ paddingLeft: '20px', fontSize: '1.3125rem', fontWeight: 'bold' }}>
                  Payment Info
                </Typography>
              </div>
            </div>
          </CardContent>
          <CardContent sx={{ padding: '10px', marginLeft: '10px', paddingTop: '0px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', paddingBottm: '10px'}}>
                    <Typography>
                        {voucherInfo.payToVendor ? voucherInfo.vendorName : voucherInfo.authName}
                    </Typography>
                </Box>
                {voucherInfo.batchType !== 9 && (
                    <>
                        <Box sx={{ padding: '25px', paddingTop: '0px'}}>
                            <Typography variant="body2" >
                                Batch type of {batchOptions.find((b) => b.id === voucherInfo.batchType)?.title} does not require address information.
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '20px' }}>
                            <Box sx={{marginRight: '20px', paddingTop: '16px'}}>
                                <Typography variant="body2" >
                                    Pay To
                                </Typography>
                            </Box>
                            <Box sx={{marginRight: '20px'}}>
                                <Select
                                    labelId={'payToBankAccount'}
                                    key={'payToBankAccount'}
                                    label={''}
                                    name={'payToBankAccount'}
                                    value={voucherInfo.payToBankAccount}
                                    sx={{ maxWidth: '350px', minWidth: '180px' }}
                                    disabled={isReadOnly}
                                    onChange={(e) =>
                                        updateVoucherInfo({ ...voucherInfo, payToBankAccount: e.target.value })
                                    }
                                >
                                    {bankOptions.map((opt) => (
                                        <MenuItem disabled={isReadOnly} key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                                            {opt.title}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                            <Box sx={{}}>
                                <FormControl>
                                    <FormControlLabel
                                        key={'wireTransfer'}
                                        name={'wireTransfer'}
                                        label={'Wire Transfer'}
                                        labelPlacement="end"
                                        control={<Checkbox disabled={isReadOnly} />}
                                        checked={voucherInfo.wireTransfer}
                                        sx={{ minWidth: '180px', maxWidth: '360px', marginLeft: '0px', marginRight: '8px' }}
                                        disabled={isReadOnly}
                                        onClick={(e) =>
                                            { updateVoucherInfo({ ...voucherInfo, wireTransfer: e.target.checked });}
                                        }
                                    />   
                                </FormControl>
                            </Box>
                            <Box sx={{ marginRight: '16px'}}>
                                <FormControl>
                                    <FormControlLabel
                                        key={'onHold'}
                                        name={'onHold'}
                                        label={'On Hold'}
                                        labelPlacement="end"
                                        control={<Checkbox disabled={isReadOnly} />}
                                        checked={voucherInfo.status === 3}
                                        sx={{ minWidth: '180px', maxWidth: '360px', marginLeft: '0px', marginRight: '8px' }}
                                        disabled={isReadOnly}
                                        onClick={(e) => {   
                                            if (e.target.checked === false) { 
                                                updateVoucherInfo({ ...voucherInfo, status: 1 }) 
                                            } else {
                                                updateVoucherInfo({ ...voucherInfo, status: 3 });
                                            }
                                        }}
                                    />   
                                </FormControl>
                            </Box>
                        </Box>
                    </>
                )}
                {voucherInfo.batchType === 9 && (
                    <>
                        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                            <Box sx={{ marginTop: '25px'}}>
                              <FormControl>
                                    <FormControlLabel
                                        key={'oldAddressChecked'}
                                        name={'oldAddressChecked'}
                                        label={'Old Address'}
                                        labelPlacement="end"
                                        control={<Checkbox disabled={isReadOnly} />}
                                        checked={voucherInfo.addressSelected === 1}
                                        sx={{ minWidth: '180px', maxWidth: '360px', marginLeft: '0px', marginRight: '8px' }}
                                        disabled={isReadOnly}
                                        onClick={(e) => updateVoucherInfo({ ...voucherInfo, addressSelected: 1 }) }
                                    />   
                                </FormControl>
                            </Box>
                            <Box sx={{}}>
                                <TextField
                                    key={'oldAddress'}
                                    label={''}
                                    name={'oldAddress'}
                                    required={false}
                                    value={voucherInfo.oldAddress}
                                    disabled={false}
                                    margin="dense"
                                    multiline
                                    rows={2}
                                    sx={{ minWidth: '225px', width: '100%' }}
                                    onChange={(e) => updateVoucherInfo({ ...voucherInfo, oldAddress: e.target.value })}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                            <Box sx={{ marginTop: '25px'}}>
                              <FormControl>
                                    <FormControlLabel
                                        key={'tempAddressChecked'}
                                        name={'tempAddressChecked'}
                                        label={'Temp Address'}
                                        labelPlacement="end"
                                        control={<Checkbox disabled={isReadOnly} />}
                                        checked={voucherInfo.addressSelected === 2}
                                        sx={{ minWidth: '180px', maxWidth: '360px', marginLeft: '0px', marginRight: '8px' }}
                                        disabled={isReadOnly}
                                        onClick={(e) => updateVoucherInfo({ ...voucherInfo, addressSelected: 2 }) }
                                    />   
                                </FormControl>
                            </Box>
                            <Box sx={{}}>
                                <TextField
                                    key={'tempAddress'}
                                    label={''}
                                    name={'tempAddress'}
                                    required={false}
                                    value={voucherInfo.tempAddress}
                                    disabled={false}
                                    margin="dense"
                                    multiline
                                    rows={2}
                                    sx={{ minWidth: '225px', width: '100%' }}
                                    onChange={(e) => updateVoucherInfo({ ...voucherInfo, tempAddress: e.target.value })}
                                />
                            </Box>
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                            <Box sx={{ marginTop: '25px'}}>
                              <FormControl>
                                    <FormControlLabel
                                        key={'newAddress'}
                                        name={'newAddress'}
                                        label={'New Address'}
                                        labelPlacement="end"
                                        control={<Checkbox disabled={isReadOnly} />}
                                        checked={voucherInfo.addressSelected === 3}
                                        sx={{ minWidth: '180px', maxWidth: '360px', marginLeft: '0px', marginRight: '8px' }}
                                        disabled={isReadOnly}
                                        onClick={(e) => updateVoucherInfo({ ...voucherInfo, addressSelected: 3 }) }
                                    />   
                                </FormControl>
                            </Box>
                            <Box sx={{}}>
                                <TextField
                                    key={'newAddress'}
                                    label={''}
                                    name={'newAddress'}
                                    required={false}
                                    value={voucherInfo.newAddress}
                                    disabled={false}
                                    margin="dense"
                                    multiline
                                    rows={2}
                                    sx={{ minWidth: '225px', width: '100%' }}
                                    onChange={(e) => updateVoucherInfo({ ...voucherInfo, newAddress: e.target.value })}
                                />
                            </Box>
                        </Box>
                        <Box>
                          {/* <InputLabel>Internal Notes</InputLabel> */}
                          <TextField
                              key={'internalNotes'}
                              label={'Internal Notes'}
                              name={'internalNotes'}
                              required={false}
                              disabled={isReadOnly}
                              value={voucherInfo.internalNotes}
                              sx={{ minWidth: '200px', width: '100%' }}
                              type="text"
                              InputLabelProps={{ shrink: true }}
                              onChange={(e) =>
                                  updateVoucherInfo({ ...voucherInfo, internalNotes: e.target.value })
                              }
                          />
                      </Box>
                    </>
                )}
                
                
          </CardContent>
        </Box>
      </Card>
    </ThemeProvider>
  );

};

export default compose(
  connect(null, {
    updateVoucherSummary,
    showToast,
  }),
)(PaymentInfoCard);