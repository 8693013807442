import {
    Box, 
  } from '@mui/material';
  import React from 'react';

  
  
  const ExpenseDetails = (props) => {

    return (
      <Box sx={{ padding: '20px' }}>
        
      </Box>
    );
  };
  
  export default ExpenseDetails;