import {
  IconButton,
  Link,
  Switch,
  Typography,
  withStyles,
  withTheme,
} from '@material-ui/core';

import { FilteringState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Grid, Table, TableFilterRow, TableHeaderRow } from '@devexpress/dx-react-grid-material-ui';

import { compose } from 'recompose';
import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Fragment, PureComponent } from 'react';
import auth from 'auth/auth';
import classNames from 'classnames';

import { formatAddress, formatDate } from 'utilities/common';
import Log from 'utilities/log';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => {
  const intakeRecordBorder = `1px solid ${theme.palette.grey[500]}`;
  return {
    table: {
      borderSpacing: theme.spacing(0, 2),
    },
    tableHead: {
      height: 32,
    },
    cell: {
      borderTop: intakeRecordBorder,
      borderBottom: intakeRecordBorder,
      borderLeft: 0,
      borderRight: 0,
      '&:first-child': {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
        borderLeft: intakeRecordBorder,
      },
      '&:last-child': {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
        borderRight: intakeRecordBorder,
      },
    },
    urgencyMenuItem: {
      justifyContent: 'center',
    },
    selectInput: {
      lineHeight: 1.75,
      paddingBottom: theme.spacing(0.5),
      paddingRight: theme.spacing(4),
      paddingTop: theme.spacing(0.5),
    },
    row: {
      backgroundColor: theme.palette.common.white,
    },
    noDataCell: {
      paddingBottom: theme.spacing(12),
      paddingTop: theme.spacing(12),
      textAlign: 'center',
    },
    centerContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  };
};

const FILTER_DISABLED_COLUMNS = ['organization'];

class IntakeRecordsGrid extends PureComponent {
  static defaultProps = {
    isLoading: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      columns: [
        { name: 'mtAuthorizationId', title: 'Auth ID' },
        { name: 'name', title: 'Name', getCellValue: this.getNameCellValue },
        { name: 'relocationPolicy', title: 'Relo Policy', getCellValue: this.getRelocationPolicyCellValue },
        { name: 'authorizationDate', title: 'Auth Date', getCellValue: this.formatDate('authorizationDate') },
        { name: 'newJobStartDate', title: 'Start Date', getCellValue: this.formatDate('newJobStartDate') },
        { name: 'reassign', title: '', getCellValue: this.getReassignCellValue },
        { name: 'transferreeHasWebAccess', title: '', getCellValue: this.getWebAccessCellValue },
        { name: 'setUrgency', title: '', getCellValue: this.getSetUrgencyCellValue },
        { name: 'addNote', title: '', getCellValue: this.getAddNoteCellValue },
        { name: 'delete', title: '', getCellValue: this.getDeleteCellValue },
      ],
      filters: [],
      tableColumnExtensions: [
        { columnName: 'name', width: 300, wordWrapEnabled: true },
        { columnName: 'setUrgency', width: 85 },
        { columnName: 'addNote', width: 65 },
        { columnName: 'delete', width: 65 },
      ],
      integratedFilteringColumnExtensions: [
        { columnName: 'name', predicate: this.keyPredicate },
      ],
    };
  }

  keyPredicate = (value, filter) => {
    const valueLower = (value.key || '').toLowerCase();
    const filterLower = (filter.value || '').toLowerCase();
    return valueLower.indexOf(filterLower) > -1;
  };

  formatDate = (key) => (record) => {
    return formatDate(record[key]);
  };

  getNameCellValue = (record) => {
    const { transferee, client, mostRecentNote } = record;
    const name = `${transferee.firstName} ${transferee.lastName}`;
    return (
      <Fragment key={name}>
        <Typography>{name}</Typography>
        <Typography>{client.description}</Typography>
        <Typography>{mostRecentNote && mostRecentNote.note}</Typography>
      </Fragment>
    );
  };

  getRelocationPolicyCellValue = (record) => {
    const code = record.client.relocationPolicyCodes.find((instance) => instance.id === record.relocationPolicyCodeId);
    return code ? code.policyCode : '';
  };

  getValue = (path) => (record) => {
    return get(record, path);
  };

  getDepartureAddress = ({ departureCity, departureStateCode, departureCountryCode }) => {
    return formatAddress({
      city: departureCity,
      state: departureStateCode,
      country: departureCountryCode,
    });
  };

  getDestinationAddress = ({ destinationCity, destinationStateCode, destinationCountryCode }) => {
    return formatAddress({
      city: destinationCity,
      state: destinationStateCode,
      country: destinationCountryCode,
    });
  };

  getAddNoteCellValue = (record) => {
    const { onAddNote, theme: { palette } } = this.props;
    return (
      <IconButton onClick={() => onAddNote(record)}>
        <FontAwesomeIcon color={palette.primary.main} icon={['far', 'sticky-note']} size="lg" />
      </IconButton>
    );
  };

  getReassignCellValue = (record) => {
    const { onReassign } = this.props;
    return (
      auth.userHasRole('admin') &&
        <Link href="#" onClick={() => onReassign(record)} color="secondary">
          {record.relocationConsultant.fullName}
        </Link>
    );
  };

  getWebAccessCellValue = (record) => {
    return (
      <div className={this.props.classes.centerContent}>
        {record.transferreeHasWebAccess && (
          <Link href="#" onClick={() => this.props.onAlterWebAccess(record)} color="secondary">
            Web Access Granted
          </Link>
        )}
        {!record.transferreeHasWebAccess && (
          <>
            <Typography>Grant Web Access</Typography>
            <Switch
              checked={record.transferreeHasWebAccess}
              disabled={record.transferreeHasWebAccess}
              onChange={() => this.props.onGrantWebAccess(record)}
              value={record.transferreeHasWebAccess}
            />
          </>
        )}
      </div>
    );
  }

  getSetUrgencyCellValue = (record) => {
    const { theme: { palette } } = this.props;
    let date = record.transfereeIntakeSentDate ? new Date(record.transfereeIntakeSentDate) : null;
    let today = new Date();
    const diffTime = date ? Math.abs(today - date) : null;
    const diffDays = diffTime ? Math.ceil(diffTime / (1000 * 60 * 60 * 24)) : null;

    return (
      <div>
        {!record.transfereeIntakeSubmitted && diffDays && diffDays > 2 && diffDays < 4 && (
          <FontAwesomeIcon color={palette.warning.main} icon={['fas', 'exclamation-triangle']} size="lg" title="EE Form has not submitted for two days." />
        )}
        {!record.transfereeIntakeSubmitted && diffDays && diffDays >= 4 && (
          <FontAwesomeIcon color={palette.error.main} icon={['fas', 'exclamation-circle']} size="lg" title="EE Form not submitted for four days." />
        )}
        {record.transfereeIntakeSubmitted && (
          <FontAwesomeIcon color={palette.primary.main} icon={['fas', 'check-circle']} size="lg" title="EE Form submitted" />
        )}
      </div>
      // <Select
      //   autoWidth={true}
      //   value={!record.intakeRecordUrgency ? '' : record.intakeRecordUrgency}
      //   onChange={this.handleUrgencyChange(record)}
      //   labelWidth={0}
      //   input={
      //     <OutlinedInput name="urgency" classes={{ input: classes.selectInput }} />
      //   }
      // >
      //   <MenuItem value={0}>
      //     <em>Clear</em>
      //   </MenuItem>
      //   <MenuItem value={1} classes={{ root: classes.urgencyMenuItem }}>
      //     <FontAwesomeIcon color={palette.primary.main} icon={['fas', 'check-circle']} size="lg" />
      //   </MenuItem>
      //   <MenuItem value={2} classes={{ root: classes.urgencyMenuItem }}>
      //     <FontAwesomeIcon color={palette.warning.main} icon={['fas', 'exclamation-triangle']} size="lg" />
      //   </MenuItem>
      //   <MenuItem value={3} classes={{ root: classes.urgencyMenuItem }}>
      //     <FontAwesomeIcon color={palette.error.main} icon={['fas', 'exclamation-circle']} size="lg" />
      //   </MenuItem>
      // </Select>
    );
  };

  getDeleteCellValue = (record) => {
    const { onDelete, theme: { palette } } = this.props;
    return (
      auth.userHasRole('admin') &&
        <IconButton onClick={() => onDelete(record)} variant="outlined">
          <FontAwesomeIcon color={palette.error.main} icon={['far', 'trash-alt']} size="lg" />
        </IconButton>
    );
  };

  handleUrgencyChange = (row) => (event) => {
    this.props.onChange({ ...row, intakeRecordUrgency: event.target.value });
  };

  changeFilters = (filters) => this.setState({ filters });

  filterCell = (props) => {
    return props.column.title && !FILTER_DISABLED_COLUMNS.includes(props.column.name) ?
      <TableFilterRow.Cell {...props} /> : null;
  };

  headerCell = (props) => {
    return props.column.title ? <TableHeaderRow.Cell {...props} /> : null;
  };

  headerRowComponent = (props) => {
    return <TableHeaderRow.Row className={this.props.classes.tableHead} {...props} />;
  };

  rowComponent = (props) => {
    return <Table.Row className={this.props.classes.row} {...props} />;
  };

  cellComponent = (props) => {
    let clickHandler = null;
    let pointerClass = null;
    if (!['reassign', 'transferreeHasWebAccess', 'setUrgency', 'delete', 'addNote'].includes(props.column.name)) {
      clickHandler = () => this.props.onSelect(props.row);
      pointerClass = 'handles-click';
    }
    const className = classNames(this.props.classes.cell, pointerClass);
    return <Table.Cell onClick={clickHandler} className={className} {...props} />;
  };

  tableComponent = (props) => {
    return <Table.Table className={this.props.classes.table} {...props} />;
  };

  noDataCellComponent = (props) => {
    const content = this.props.isLoading ? <Spinner logo /> : 'No Records were returned.  Check the filters above.';
    // need to remove the getMessage function to avoid exception
    // eslint-disable-next-line no-unused-vars
    const { getMessage, ...rest } = props;
    return (
      <Table.Cell {...rest} className={this.props.classes.noDataCell}>
        <Typography>{content}</Typography>
      </Table.Cell>
    );
  };

  render() {
    Log.trace('RENDER', 'IntakeRecordsGrid');
    const { intakeRecords } = this.props;
    const {
      columns,
      filters,
      tableColumnExtensions,
      integratedFilteringColumnExtensions,
    } = this.state;

    return (
      <Grid
        rows={intakeRecords}
        columns={columns}
      >
        <FilteringState
          filters={filters}
          onFiltersChange={this.changeFilters}
        />
        <IntegratedFiltering columnExtensions={integratedFilteringColumnExtensions} />
        <Table
          columnExtensions={tableColumnExtensions}
          rowComponent={this.rowComponent}
          tableComponent={this.tableComponent}
          cellComponent={this.cellComponent}
          noDataCellComponent={this.noDataCellComponent}
        />
        <TableHeaderRow rowComponent={this.headerRowComponent} cellComponent={this.headerCell} />
        <TableFilterRow cellComponent={this.filterCell} />
      </Grid>
    );
  }
}

IntakeRecordsGrid.propTypes = {
  intakeRecords: PropTypes.array.isRequired,
  classes: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onAddNote: PropTypes.func.isRequired,
  onReassign: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onGrantWebAccess: PropTypes.func.isRequired,
  onAlterWebAccess: PropTypes.func.isRequired,
  theme: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

export default compose(
  withStyles(styles),
  withTheme,
)(IntakeRecordsGrid);
