import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import EditableCard from 'modules/common/editableCard.component';
import {
  createCustomCurrencyPutRequest,
  createCustomExceptionFormData,
  createCustomExceptionTabs,
  createCustomInfoFormData,
  createCustomInfoPutRequest,
  createCustomInfoTabs
} from './customExpenseLinesUtils';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { updateExpenseLine } from 'modules/expenses/expenses.actions';
import { UPDATE_EXPENSE_LINE_FAILURE } from 'modules/expenses/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import CustomExpenseDetailsCurrencyEditableCardComponent from './customExpenseDetailsCurrencyEditableCard.component';

const ExpenseLineItemCustomView = props => {
  const { expenseLines } = props.details;
  const expenseLineItemId = parseInt(props.expenseLineId, 10);

  const [selectedExpenseLineItem, setSelectedExpenseLineItem] = useState();

  useEffect(() => {
    if (expenseLines && expenseLines.length > 0 && expenseLineItemId !== undefined && expenseLineItemId !== null) {
      const foundItem = expenseLines.find(s => s.expenseLineId === expenseLineItemId);
      setSelectedExpenseLineItem(foundItem);
    }
  }, [expenseLines, expenseLineItemId]);

  const [isLoadingCustomLineItem, setIsLoadingCustomLineItem] = useState(false);

  const handleCustomInfoSubmission = async (tab, values) => {
    const request = createCustomInfoPutRequest(selectedExpenseLineItem, values);
    return await handleUpdateCustomTab(request);
  };
  const handleCustomCurrencySubmission = async (values) => {
    const request = createCustomCurrencyPutRequest(selectedExpenseLineItem, values);
    return await handleUpdateCustomTab(request);
  };

  const handleUpdateCustomTab = async request => {
    let isSuccessful = false;
    setIsLoadingCustomLineItem(true);
    try {
      const resp = await props.updateExpenseLine(request);
      if (resp.type === UPDATE_EXPENSE_LINE_FAILURE) {
        props.showToast('Fauled to update Expense Line Item', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      } else {
        isSuccessful = true;
      }
    } catch (error) {
      console.error('Error submitting employee form:', error);
    }
    setIsLoadingCustomLineItem(false);
    return isSuccessful;
  };
  return (
    <Grid container spacing={2} sx={{ padding: '20px' }}>
      <Grid item sm={12} md={12} lg={8} xl={8} sx={{ display: 'flex', flexDirection: 'column', height: 'auto', alignSelf: 'flex-start' }}>
        <EditableCard
          tabs={createCustomInfoTabs()}
          summaries={createCustomInfoFormData(selectedExpenseLineItem)}
          numColumns={5}
          updateFields={handleCustomInfoSubmission}
          isLoading={isLoadingCustomLineItem}
          hideEdit={props.viewOnly}
        />
      </Grid>
      <Grid item sm={12} md={12} lg={4} xl={4}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <CustomExpenseDetailsCurrencyEditableCardComponent
              selectedExpenseLineItem={selectedExpenseLineItem}
              updateFields={handleCustomCurrencySubmission}
              isLoading={isLoadingCustomLineItem}
              hideEdit={props.viewOnly}
            />
          </Grid>
          <Grid item>
            <EditableCard
              tabs={createCustomExceptionTabs()}
              summaries={createCustomExceptionFormData()}
              updateFields={() => console.log('I am actually a read only table.')}
              numColumns={3}
              hideEdit={true}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default compose(connect(null, { updateExpenseLine }))(ExpenseLineItemCustomView);
