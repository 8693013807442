/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Grid,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';

import BrokerInfoCard from './brokerInfoCard.component';
import ListingFullInfoCard from './listingFullInfoCard.component';
import OtherInfoCard from './otherInfoCard.component';
import ReferralFeesCard from './referralFeesCard.component';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const Listings = (props) => {    
  const { brokerAgents, details, isLoadingListing, listingId, isReadOnly } = props;

  const listingInfo = details.listings ? details.listings.find((a) => a.listingId === listingId) : null;

  return (
    <Box>
      {listingInfo == null && !isLoadingListing && (
        <Box sx={{ padding: '20px', fontSize: '20px' }}>
          <b>No Listing selected. Create a new one above to get started.</b>
        </Box>
      )}
      {listingInfo != null && !isLoadingListing && (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} xl={8} rowSpacing={2}>
            <BrokerInfoCard brokerAgents={brokerAgents} listingInfo={listingInfo} isReadOnly={isReadOnly} />
            <Box sx={{ paddingTop: '16px' }}>
              <ListingFullInfoCard listingInfo={listingInfo} isReadOnly={isReadOnly}/>
            </Box>
          </Grid>
          <Grid item xs={12} xl={4} rowSpacing={2}>
            <OtherInfoCard listingInfo={listingInfo} isReadOnly={isReadOnly}/>
            <Box sx={{ paddingTop: '16px' }}>
              <ReferralFeesCard listingInfo={listingInfo} isReadOnly={isReadOnly}/>
            </Box>
          </Grid>
        </Grid>
      )}
      {isLoadingListing && <Spinner />}
    </Box>
  );

};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
  }),
)(Listings);