import { Box } from '@material-ui/core';
import {
  GET_GLOBAL_DESTINATION_SERVICES_FAILURE,
  GET_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE,
  GET_GLOBAL_LANGUAGE_TRAINING_FAILURE,
} from 'modules/authorizations/store/types/globalTypes';
import {
  GET_VENDORS_FAILURE,
  GET_VENDOR_CONTACT_FAILURE,
} from 'modules/authorizations/store/types/dataManagementTypes';
import { Grid } from '@mui/material';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import {
  getGlobalDestinationServices,
  getGlobalInternationalSchooling,
  getGlobalLanguageTraining,
} from 'modules/authorizations/store/actions/global.actions';
import { 
  getVendorContacts,
  getVendors, 
} from 'modules/authorizations/store/actions/dataManagement.actions';
import { showToast } from 'modules/layout/layout.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useFetchFamilyDataHandler } from './apiHandlers/useFetchDataHandler';
import DestinationServicesCrudTable from './crudTables/destinationServicesCrud.component';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import InternationalSchoolingCrudTable from './crudTables/internationalSchoolingCrud.component';
import LanguageTrainingCrudTable from './crudTables/languageTrainingCrud.component';
import React, { useEffect, useState } from 'react';
import SummaryCard from '../../summaryCard.component';
export const GlobalDestinationServices = ({ details, id, isReadOnly }) => {
  const [isLoadingGlobalDestinationData, setIsLoadingGlobalDestinationData] = useState(false);

  const dispatch = useDispatch();
  //TODO - remove this when familt is ready
  const getFamilyhandler = useFetchFamilyDataHandler();
  const familyMembers = getFamilyhandler.fetchData();

  const { globalDestinationServices, globalLanguageTrainings, internationalSchoolings, vendors, vendorContacts, isLoading } = useSelector(
    (state) => {
      return {
        isLoading: state.authorizations.isLoading,
        globalDestinationServices: state.authorizations.authorizationDetails[id]?.globalDestinationServices,
        globalLanguageTrainings: state.authorizations.authorizationDetails[id]?.globalLanguageTrainings,
        internationalSchoolings: state.authorizations.authorizationDetails[id]?.internationalSchoolings,
        vendors: state.authorizations.vendors,
        vendorContacts: state.authorizations.vendorContacts,
      };
    },
  );

  useEffect(() => {
    const fetchGlobalDestinationServices = async () => {
      if (!globalDestinationServices) {
        setIsLoadingGlobalDestinationData(true);
        const resp = await dispatch(getGlobalDestinationServices(id));
        if (resp.type === GET_GLOBAL_DESTINATION_SERVICES_FAILURE) {
          showToast('Failed fetching global destination services, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
        setIsLoadingGlobalDestinationData(false);
      }
    };

    fetchGlobalDestinationServices();
  }, [id, globalDestinationServices, dispatch]);

  useEffect(() => {
    const fetchInternationalSchoolings = async () => {
      if (!internationalSchoolings) {
        setIsLoadingGlobalDestinationData(true);
        const resp = await dispatch(getGlobalInternationalSchooling(id));
        if (resp.type === GET_GLOBAL_INTERNATIONAL_SCHOOLING_FAILURE) {
          showToast('Failed fetching global international schooling, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
        setIsLoadingGlobalDestinationData(false);
      }
    };

    fetchInternationalSchoolings();
  }, [id, internationalSchoolings, dispatch]);

  useEffect(() => {
    const fetchGlobalLanguageTrainings = async () => {
      if (!globalLanguageTrainings) {
        setIsLoadingGlobalDestinationData(true);
        const resp = await dispatch(getGlobalLanguageTraining(id));
        if (resp.type === GET_GLOBAL_LANGUAGE_TRAINING_FAILURE) {
          showToast('Failed fetching global language training, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
        setIsLoadingGlobalDestinationData(false);
      }
    };

    fetchGlobalLanguageTrainings();
  }, [id, globalLanguageTrainings, dispatch]);

  useEffect(() => {
    const fetchVendors = async () => {
      if (!vendorContacts) {
        setIsLoadingGlobalDestinationData(true);
        const resp = await dispatch(getVendors());
        if (resp.type === GET_VENDORS_FAILURE) {
          showToast('Failed fetching vendors, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
        setIsLoadingGlobalDestinationData(false);
      }
    };

    fetchVendors();
  }, [vendorContacts, dispatch]);

  useEffect(() => {
    const fetchVendorContacts = async () => {
      if (!vendors) {
        setIsLoadingGlobalDestinationData(true);
        const resp = await dispatch(getVendorContacts());
        if (resp.type === GET_VENDOR_CONTACT_FAILURE) {
          showToast('Failed fetching vendor contacts, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        }
        setIsLoadingGlobalDestinationData(false);
      }
    };

    fetchVendorContacts();
  }, [vendors, dispatch]);

  return (
    <>
      {isLoading || isLoadingGlobalDestinationData ? (
        <FullscreenSpinner />
      ) : (
        <>
          <Grid container spacing={2} sx={{ padding: '20px' }}>
            <Grid item xs={12} md={8} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
              <DestinationServicesCrudTable id={id} isReadOnly={isReadOnly}/>
              <InternationalSchoolingCrudTable id={id} family={familyMembers} isReadOnly={isReadOnly}/>
              <LanguageTrainingCrudTable id={id} family={familyMembers} isReadOnly={isReadOnly}/>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box sx={{ position: 'sticky', top: 180, height: 'fit-content' }}>
                <SummaryCard dateSummary={details.dateSummary} id={id} hideEdit={true} />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default GlobalDestinationServices;
