const { CALL_INTAKE_V2_API } = require('middleware/intakeV2Api');
const {
  DELETE_EXPENSE_CODES_REQUEST,
  DELETE_EXPENSE_CODES_SUCCESS,
  DELETE_EXPENSE_CODES_FAILURE,
  UPDATE_EXPENSE_CODES_REQUEST,
  UPDATE_EXPENSE_CODES_SUCCESS,
  UPDATE_EXPENSE_CODES_FAILURE,
  POST_EXPENSE_CODES_REQUEST,
  POST_EXPENSE_CODES_SUCCESS,
  POST_EXPENSE_CODES_FAILURE,
  GET_EXPENSE_CODES_REQUEST,
  GET_EXPENSE_CODES_SUCCESS,
  GET_EXPENSE_CODES_FAILURE,
  GET_INSPECTORS_REQUEST,
  GET_INSPECTORS_SUCCESS,
  GET_INSPECTORS_FAILURE,
  POST_INSPECTORS_REQUEST,
  POST_INSPECTORS_SUCCESS,
  POST_INSPECTORS_FAILURE,
  UPDATE_INSPECTORS_REQUEST,
  UPDATE_INSPECTORS_FAILURE,
  DELETE_INSPECTORS_REQUEST,
  DELETE_INSPECTORS_SUCCESS,
  DELETE_INSPECTORS_FAILURE,
  GET_VENDORS_REQUEST,
  GET_VENDORS_SUCCESS,
  GET_VENDORS_FAILURE,
  POST_VENDORS_REQUEST,
  POST_VENDORS_SUCCESS,
  POST_VENDORS_FAILURE,
  UPDATE_VENDORS_REQUEST,
  UPDATE_VENDORS_SUCCESS,
  UPDATE_VENDORS_FAILURE,
  DELETE_VENDORS_REQUEST,
  DELETE_VENDORS_SUCCESS,
  DELETE_VENDORS_FAILURE,
  GET_VENDOR_CONTACT_REQUEST,
  GET_VENDOR_CONTACT_SUCCESS,
  GET_VENDOR_CONTACT_FAILURE,
  POST_VENDOR_CONTACT_REQUEST,
  POST_VENDOR_CONTACT_SUCCESS,
  POST_VENDOR_CONTACT_FAILURE,
  UPDATE_VENDOR_CONTACT_REQUEST,
  UPDATE_VENDOR_CONTACT_SUCCESS,
  UPDATE_VENDOR_CONTACT_FAILURE,
  DELETE_VENDOR_CONTACT_REQUEST,
  DELETE_VENDOR_CONTACT_SUCCESS,
  DELETE_VENDOR_CONTACT_FAILURE,
  GET_ALL_BROKER_AGENTS_REQUEST,
  GET_ALL_BROKER_AGENTS_SUCCESS,
  GET_ALL_BROKER_AGENTS_FAILURE,
  GET_BROKER_AGENTS_BY_ID_REQUEST,
  GET_BROKER_AGENTS_BY_ID_SUCCESS,
  GET_BROKER_AGENTS_BY_ID_FAILURE,
  POST_BROKER_AGENTS_SUCCESS,
  POST_BROKER_AGENTS_FAILURE,
  UPDATE_BROKER_AGENTS_REQUEST,
  UPDATE_BROKER_AGENTS_SUCCESS,
  UPDATE_BROKER_AGENTS_FAILURE,
  DELETE_BROKER_AGENTS_REQUEST,
  DELETE_BROKER_AGENTS_SUCCESS,
  DELETE_BROKER_AGENTS_FAILURE,
  GET_TAX_POLICIES_REQUEST,
  GET_TAX_POLICIES_SUCCESS,
  GET_TAX_POLICIES_FAILURE,
  POST_TAX_POLICY_REQUEST,
  POST_TAX_POLICY_SUCCESS,
  POST_TAX_POLICY_FAILURE,
  UPDATE_TAX_POLICY_REQUEST,
  UPDATE_TAX_POLICY_SUCCESS,
  UPDATE_TAX_POLICY_FAILURE,
  DELETE_TAX_POLICY_REQUEST,
  DELETE_TAX_POLICY_SUCCESS,
  DELETE_TAX_POLICY_FAILURE,
  POST_BROKER_AGENTS_REQUEST,
  UPDATE_INSPECTORS_SUCCESS,
  GET_TITLE_REP_REQUEST,
  GET_TITLE_REP_SUCCESS,
  GET_TITLE_REP_FAILURE,
} = require('../types/dataManagementTypes');

export const deleteExpenseCodes = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_EXPENSE_CODES_REQUEST, DELETE_EXPENSE_CODES_SUCCESS, DELETE_EXPENSE_CODES_FAILURE],
      authenticated: true,
      endpoint: `api/ExpenseCodes/${id}`,
      method: 'DELETE',
    },
  };
};
export const updateExpenseCodes = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_EXPENSE_CODES_REQUEST, UPDATE_EXPENSE_CODES_SUCCESS, UPDATE_EXPENSE_CODES_FAILURE],
      authenticated: true,
      endpoint: 'api/ExpenseCodes',
      method: 'PUT',
      payload: values,
    },
  };
};
export const postExpenseCodes = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_EXPENSE_CODES_REQUEST, POST_EXPENSE_CODES_SUCCESS, POST_EXPENSE_CODES_FAILURE],
      authenticated: true,
      endpoint: 'api/ExpenseCodes',
      method: 'POST',
      payload: values,
    },
  };
};
export const getExpenseCodes = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_EXPENSE_CODES_REQUEST, GET_EXPENSE_CODES_SUCCESS, GET_EXPENSE_CODES_FAILURE],
      authenticated: true,
      endpoint: 'api/ExpenseCodes',
      method: 'GET',
    },
  };
};
/* ^ End Homesale Equity */

export const getInspectors = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_INSPECTORS_REQUEST, GET_INSPECTORS_SUCCESS, GET_INSPECTORS_FAILURE],
      authenticated: true,
      endpoint: 'api/inspectors',
      method: 'GET',
    },
  };
};

export const postInspector = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_INSPECTORS_REQUEST, POST_INSPECTORS_SUCCESS, POST_INSPECTORS_FAILURE],
      authenticated: true,
      endpoint: 'api/inspectors',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateInspector = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_INSPECTORS_REQUEST, UPDATE_INSPECTORS_SUCCESS, UPDATE_INSPECTORS_FAILURE],
      authenticated: true,
      endpoint: 'api/inspectors',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteInspector = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_INSPECTORS_REQUEST, DELETE_INSPECTORS_SUCCESS, DELETE_INSPECTORS_FAILURE],
      authenticated: true,
      endpoint: `api/inspectors/${id}`,
      method: 'DELETE',
    },
  };
};
/* Vendor Requests */

export const getVendors = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_VENDORS_REQUEST, GET_VENDORS_SUCCESS, GET_VENDORS_FAILURE],
      authenticated: true,
      endpoint: 'api/Vendors',
      method: 'GET',
    },
  };
};

export const postVendor = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_VENDORS_REQUEST, POST_VENDORS_SUCCESS, POST_VENDORS_FAILURE],
      authenticated: true,
      endpoint: 'api/Vendors',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateVendor = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_VENDORS_REQUEST, UPDATE_VENDORS_SUCCESS, UPDATE_VENDORS_FAILURE],
      authenticated: true,
      endpoint: 'api/Vendors',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteVendor = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_VENDORS_REQUEST, DELETE_VENDORS_SUCCESS, DELETE_VENDORS_FAILURE],
      authenticated: true,
      endpoint: `api/Vendors/${id}`,
      method: 'DELETE',
    },
  };
};

export const getVendorContacts = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_VENDOR_CONTACT_REQUEST, GET_VENDOR_CONTACT_SUCCESS, GET_VENDOR_CONTACT_FAILURE],
      authenticated: true,
      endpoint: 'api/Vendors/contacts',
      method: 'GET',
    },
  };
};

export const postVendorContact = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_VENDOR_CONTACT_REQUEST, POST_VENDOR_CONTACT_SUCCESS, POST_VENDOR_CONTACT_FAILURE],
      authenticated: true,
      endpoint: 'api/Vendors/contact',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateVendorContact = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_VENDOR_CONTACT_REQUEST, UPDATE_VENDOR_CONTACT_SUCCESS, UPDATE_VENDOR_CONTACT_FAILURE],
      authenticated: true,
      endpoint: 'api/Vendors/contact',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteVendorContact = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_VENDOR_CONTACT_REQUEST, DELETE_VENDOR_CONTACT_SUCCESS, DELETE_VENDOR_CONTACT_FAILURE],
      authenticated: true,
      endpoint: `api/Vendors/contact/${id}`,
      method: 'DELETE',
    },
  };
};
/* Broker/Agent endpoints */

export const getBrokerAgents = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_ALL_BROKER_AGENTS_REQUEST, GET_ALL_BROKER_AGENTS_SUCCESS, GET_ALL_BROKER_AGENTS_FAILURE],
      authenticated: true,
      endpoint: 'api/BrokerAgents/',
      method: 'GET',
    },
  };
};

export const getBrokerAgentsById = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_BROKER_AGENTS_BY_ID_REQUEST, GET_BROKER_AGENTS_BY_ID_SUCCESS, GET_BROKER_AGENTS_BY_ID_FAILURE],
      authenticated: true,
      endpoint: `api/BrokerAgents/${id}`,
      method: 'GET',
    },
  };
};

export const postBrokerAgents = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_BROKER_AGENTS_REQUEST, POST_BROKER_AGENTS_SUCCESS, POST_BROKER_AGENTS_FAILURE],
      authenticated: true,
      endpoint: 'api/BrokerAgents/',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateBrokerAgents = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_BROKER_AGENTS_REQUEST, UPDATE_BROKER_AGENTS_SUCCESS, UPDATE_BROKER_AGENTS_FAILURE],
      authenticated: true,
      endpoint: 'api/BrokerAgents/',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteBrokerAgents = (brokerAgentId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_BROKER_AGENTS_REQUEST, DELETE_BROKER_AGENTS_SUCCESS, DELETE_BROKER_AGENTS_FAILURE],
      authenticated: true,
      endpoint: `api/BrokerAgents/${brokerAgentId}`,
      method: 'DELETE',
    },
  };
};
/* Tax Policies Calls */

export const getTaxPolicies = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_TAX_POLICIES_REQUEST, GET_TAX_POLICIES_SUCCESS, GET_TAX_POLICIES_FAILURE],
      authenticated: true,
      endpoint: 'api/TaxPolicys',
      method: 'GET',
    },
  };
};

export const postTaxPolicy = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_TAX_POLICY_REQUEST, POST_TAX_POLICY_SUCCESS, POST_TAX_POLICY_FAILURE],
      authenticated: true,
      endpoint: 'api/TaxPolicys',
      method: 'POST',
      payload: values,
    },
  };
};

export const updateTaxPolicy = (values) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_TAX_POLICY_REQUEST, UPDATE_TAX_POLICY_SUCCESS, UPDATE_TAX_POLICY_FAILURE],
      authenticated: true,
      endpoint: 'api/TaxPolicys',
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteTaxPolicy = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_TAX_POLICY_REQUEST, DELETE_TAX_POLICY_SUCCESS, DELETE_TAX_POLICY_FAILURE],
      authenticated: true,
      endpoint: `api/TaxPolicys/${id}`,
      method: 'DELETE',
    },
  };
};

export const getTitleReps = () => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_TITLE_REP_REQUEST, GET_TITLE_REP_SUCCESS, GET_TITLE_REP_FAILURE],
      authenticated: true,
      endpoint: 'api/titleRep',
    },
  };
};
