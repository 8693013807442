/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Grid,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import AddressCard from '../addressCard.component';
import PropTypes from 'prop-types';
import React from 'react';
import SummaryCard from '../summaryCard.component';

import MovingDates from './movingDates.component';
import SegmentInfoCard from './segmentInfoCard.component';
import StatusStepperHhg from './statusStepperHhg.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const PrimaryHhgInfo = (props) => {    
  const { details, id, isLoadingMovingDates, segmentId, location } = props;

  const segmentInfo = details.hhgSegments ? details.hhgSegments.find((s) => s.segmentId === segmentId) : null;

  return (
    <Box>
      <StatusStepperHhg />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          <SegmentInfoCard segmentInfo={segmentInfo} hideEdit={location.pathname.includes('expenses')} id={id} />
          <Box sx={{ paddingTop: '16px' }}>
            <MovingDates segmentInfo={segmentInfo} movingDates={details.hhgMovingDates} hideEdit={location.pathname.includes('expenses')} id={id} isLoadingMovingDates={isLoadingMovingDates} />
          </Box>
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          <SummaryCard dateSummary={details.dateSummary} id={id} hideEdit={true} />
          <Box sx={{ paddingTop: '16px' }}>
            <AddressCard departureAddress={details.departureAddress} hideEdit={location.pathname.includes('expenses')} destinationAddress={details.destinationAddress} id={id} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

};

PrimaryHhgInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
  }),
)(PrimaryHhgInfo);