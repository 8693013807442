/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Grid,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import ListingInfoCard from './listingInfoCard.component';
import ProgramInfoCard from './programInfoCard.component';
import PropTypes from 'prop-types';
import PropertyInfoCard from './propertyInfoCard.component';
import React from 'react';
import StatusStepperHomesale from './statusStepperHomesale.component';
import SummaryCard from '../summaryCard.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const PrimaryInfo = (props) => {    
  const { details, id, isReadOnly } = props;

  const listingInfo = (details.listings && details.listings[0]) ? details.listings[0] : null;

  return (
    <Box>
      <StatusStepperHomesale />
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          <ProgramInfoCard homesaleInfo={details.homesale} id={id} isReadOnly={isReadOnly}/>
          <Box sx={{ paddingTop: '16px' }}>
            <PropertyInfoCard homesaleInfo={details.homesale} id={id} isReadOnly={isReadOnly}/> 
          </Box>
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          <ListingInfoCard homesaleInfo={details.homesale} listingInfo={listingInfo} id={id} isReadOnly={isReadOnly}/>
          <Box sx={{ paddingTop: '16px' }}>
            <SummaryCard dateSummary={details.dateSummary} id={id} hideEdit={true} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );

};

PrimaryInfo.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
  }),
)(PrimaryInfo);