/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import HouseholdGoods from './householdGoods.component';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../../layout/types';

import {
  getAuthClientPolicies, getAuthDateSummary, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress, getDestinationAddress,
  getEmployeeInfo, getSOInfo, getServices, getTeam, getUserSettings, setAuthId, setLoadingComplete,
} from '../../store/actions/authorizations.actions';

import {
  getHhgSegments,
} from 'modules/authorizations/store/actions/houseHoldGoods.actions';

import {
  getVendorContacts, getVendors,
} from 'modules/authorizations/store/actions/dataManagement.actions';

import {
  GET_AUTH_CLIENT_POLICIES_FAILURE, GET_AUTH_DATE_SUMMARY_FAILURE, GET_AUTH_SERVICES_FAILURE, GET_AUTH_SUMMARY_FAILURE,
  GET_AUTH_TEAM_FAILURE, GET_COMMUNICATION_INFO_FAILURE, GET_DEPARTURE_ADDRESS_FAILURE, GET_DESTINATION_ADDRESS_FAILURE,
  GET_EMPLOYEE_INFO_FAILURE, GET_SO_INFO_FAILURE, GET_USER_SETTINGS_FAILURE,
} from '../../store/types/authorizationTypes';

import {
  GET_HHG_SEGMENTS_FAILURE,
} from 'modules/authorizations/store/types/houseHoldGoodsTypes';

import {
  GET_VENDORS_FAILURE, GET_VENDOR_CONTACT_FAILURE,
} from 'modules/authorizations/store/types/dataManagementTypes';

import {
  COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING,
} from 'styles/theme';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const HouseholdGoodsView = (props) => {

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
   
  });
    
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const clientInfo = details && details.summary && props.unmappedClients ? props.unmappedClients.find((e) => e.description === details.summary.clientName) : null;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData () {
      const id = props.match.params.id;

      window.scrollTo(0, 0);
      props.setAuthId(id);

      if (!details) {
        setIsLoading(true);
          
        const [authSummaryResp, employeeInfoResp, soInfoResp, authDateSummaryResp, communicationInfoResp, departureResp, destinationResp, authClientPoliciesResp,
          teamResp, authServiceResp, userSettingsResp, hhgSegmentsResp, vendorsResp, vendorContactsResp] = await Promise.all([
          props.getAuthorizationSummary(id),
          props.getEmployeeInfo(id),
          props.getSOInfo(id),
          props.getAuthDateSummary(id),
          props.getCommunicationInfo(id),
          props.getDepartureAddress(id),
          props.getDestinationAddress(id),
          props.getAuthClientPolicies(id),
          props.getTeam(id),
          props.getServices(id),
          props.getUserSettings(id),
          props.getHhgSegments(id),
          props.getVendors(),
          props.getVendorContacts(),
        ]);
  
        props.setLoadingComplete();
        setIsLoading(false);

        if (authSummaryResp.type === GET_AUTH_SUMMARY_FAILURE) {
          props.showToast('Failed to retrieve authorization info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
    
        if (employeeInfoResp.type === GET_EMPLOYEE_INFO_FAILURE) {
          props.showToast('Failed to retrieve employee info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
    
        if (soInfoResp.type === GET_SO_INFO_FAILURE) {
          props.showToast('Failed to retrieve significant other info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
            
        if (authDateSummaryResp.type === GET_AUTH_DATE_SUMMARY_FAILURE) {
          props.showToast('Failed to retrieve auth summary info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (communicationInfoResp.type === GET_COMMUNICATION_INFO_FAILURE) {
          props.showToast('Failed to retrieve employee communication info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (departureResp.type === GET_DEPARTURE_ADDRESS_FAILURE) {
          props.showToast('Failed to retrieve departure address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (destinationResp.type === GET_DESTINATION_ADDRESS_FAILURE) {
          props.showToast('Failed to retrieve destination address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
          props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (teamResp.type === GET_AUTH_TEAM_FAILURE) {
          props.showToast('Failed to retrieve auth team, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authServiceResp.type === GET_AUTH_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve auth services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
          props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (userSettingsResp.type === GET_USER_SETTINGS_FAILURE) {
          props.showToast('Failed to retrieve employee settings info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (hhgSegmentsResp.type === GET_HHG_SEGMENTS_FAILURE) {
          props.showToast('Failed to retrieve hhg segments info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (vendorsResp.type === GET_VENDORS_FAILURE) {
          props.showToast('Failed to retrieve vendor info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (vendorContactsResp.type === GET_VENDOR_CONTACT_FAILURE) {
          props.showToast('Failed to retrieve vendor contact info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
        
      }
    }

    fetchData();
  }, [clientInfo, details]);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && clientInfo &&
          <HouseholdGoods details={details} clientInfo={clientInfo} id={props.match.params.id} history={props.history} location={props.location} />
        }
        {isLoading && <FullscreenSpinner />}

      </Box>
    </ThemeProvider>
  );

};

HouseholdGoodsView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { authorizationDetails, isLoading } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    unmappedClients,
    isLoading,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getEmployeeInfo,
    getSOInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    setAuthId,
    setLoadingComplete,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getHhgSegments,
    getVendors,
    getVendorContacts,
  }),
)(HouseholdGoodsView);