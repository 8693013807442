/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Grid,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import React from 'react';

import InspectionsRequiredCard from './inspectionsRequiredCard.component';
import OrderDetailsCard from './orderDetailsCard.component';
import ProcessDetailsCard from './processDetailsCard.component';
import Spinner from 'modules/common/spinner.component';
import SpreadSelectorCard from './spreadSelectorCard.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const AppraisalBmaDetails = (props) => {    
  const { appraisalId, appraisalsSelected, brokerAgents, details, id, isLoadingEstimates, isReadOnly } = props;

  const appraisalInfo = details.appraisalsBmas ? details.appraisalsBmas.find((a) => a.appraisalId === appraisalId) : null;

  return (
    <Box>
      {!isReadOnly && appraisalInfo == null && !isLoadingEstimates && (
        <Box sx={{ padding: '20px', fontSize: '20px' }}>
          <b>No {appraisalsSelected ? 'Appraisal' : 'BMA'} selected. Create a new one above to get started.</b>
        </Box>
      )}
      {appraisalInfo != null && !isLoadingEstimates && (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} xl={8} rowSpacing={2}>
            <OrderDetailsCard appraisalsSelected={appraisalsSelected} id={id} appraisalInfo={appraisalInfo} brokerAgents={brokerAgents} isReadOnly={isReadOnly}/>
            <Box sx={{ paddingTop: '16px' }}>
              <SpreadSelectorCard appraisalsSelected={appraisalsSelected} id={id} appraisalInfo={appraisalInfo} brokerAgents={brokerAgents} appraisalsBmas={details.appraisalsBmas.filter((a) => a.appraisalBmaType === (appraisalsSelected ? 0 : 1))} homesaleInfo={details.homesale} isReadOnly={isReadOnly}/> 
            </Box>
          </Grid>
          <Grid item xs={12} xl={4} rowSpacing={2}>
            <ProcessDetailsCard appraisalsSelected={appraisalsSelected} id={id} appraisalInfo={appraisalInfo} brokerAgents={brokerAgents} isReadOnly={isReadOnly}/> 
            <Box sx={{ paddingTop: '16px' }}>
              <InspectionsRequiredCard appraisalsSelected={appraisalsSelected} id={id} appraisalInfo={appraisalInfo} brokerAgents={brokerAgents} isReadOnly={isReadOnly} />
            </Box>
          </Grid>
        </Grid>
      )}
      {isLoadingEstimates && <Spinner />}
    </Box>
  );

};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
  }),
)(AppraisalBmaDetails);