import {
    GET_EXPENSE_SUMMARIES_REQUEST, GET_EXPENSE_SUMMARIES_SUCCESS, GET_EXPENSE_LINES_REQUEST, GET_EXPENSE_LINES_SUCCESS,
    GET_VOUCHER_SUMMARIES_REQUEST,
    GET_VOUCHER_SUMMARIES_SUCCESS,
  } from './types';
  import produce from 'immer';
  
  const initialState = {
    expenseSummaries: null,
    voucherSummaries: null,
    expenseLines: null,
    isLoadingExpenseSummaries: false,
    isLoadingVoucherSummaries: false,
    isLoadingExpenseLines: false,
  };
  
  export default produce((draft = initialState, action) => {
    switch (action.type) {

      case GET_EXPENSE_SUMMARIES_REQUEST:
        draft.isLoadingExpenseSummaries = true;
        break;
  
      case GET_EXPENSE_SUMMARIES_SUCCESS:
        draft.isLoadingExpenseSummaries = false;
        draft.expenseSummaries = action.response;
        break;

      case GET_VOUCHER_SUMMARIES_REQUEST:
        draft.isLoadingExpenseSummaries = true;
        break;
  
      case GET_VOUCHER_SUMMARIES_SUCCESS:
        draft.isLoadingVoucherSummaries = false;
        draft.voucherSummaries = action.response;
        break;

      case GET_EXPENSE_LINES_REQUEST:
        draft.isLoadingExpenseLines = true;
      break;

      case GET_EXPENSE_LINES_SUCCESS:
        draft.isLoadingExpenseLines = false;
        draft.expenseLines = action.response;
        break;
  
      default:
        break;
    }
  
    return draft;
  }, initialState);