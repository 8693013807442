/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { GET_AUTH_SUMMARY_FAILURE, GET_EMPLOYEE_INFO_FAILURE } from '../../../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../../../layout/types';
import { getAuthClientPolicies, getEmployeeInfo, setAuthId } from '../../../store/actions/authorizations.actions';
import { setLoadingComplete } from 'modules/clients/clients.actions';
import { showToast } from 'modules/layout/layout.actions';
import { getExpenseSummariesByAuth } from 'modules/expenses/expenses.actions';
import { GET_EXPENSE_LINES_BY_AUTH_FAILURE, GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE } from 'modules/expenses/types';
import ExpenseLineItemDetails from './expenseLineItemDetailsView.component';

const ExpenseLineItemDetailsView = props => {
  const { authorizationDetails, currentAuthId, match, history } = props;

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const details = authorizationDetails ? authorizationDetails[currentAuthId] : null;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const id = match.params.id;
      setAuthId(id);

      if (!details || (details && !details.submittedExpenses && !details.expenseLines)) {
        setIsLoading(true);

        const [authSummaryResp, employeeInfoResp, expenseSummariesResp, expenseLineItemsResp] = await Promise.all([props.getAuthClientPolicies(id), props.getEmployeeInfo(id), props.getExpenseSummariesByAuth(id), props.getExpenseLinesByAuthId(id)]);

        setLoadingComplete();
        setIsLoading(false);

        if (authSummaryResp.type === GET_AUTH_SUMMARY_FAILURE) {
          showToast('Failed to retrieve authorization info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (employeeInfoResp.type === GET_EMPLOYEE_INFO_FAILURE) {
          showToast('Failed to retrieve employee info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (expenseSummariesResp.type === GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE) {
          showToast('Failed to retrieve submitted expenses, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if(expenseLineItemsResp.type === GET_EXPENSE_LINES_BY_AUTH_FAILURE){
          showToast('Failed to retrieve expense line items, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
      }
    };

    fetchData();
  }, [details, match.params.id]);

  const expenseLineId = match.params.expenseLineId;

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && <ExpenseLineItemDetails details={details} expenseLineId={expenseLineId} location={props.location} history={history}/>}
        {isLoading && <FullscreenSpinner />}
      </Box>
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  const { authorizationDetails, isLoading, currentAuthId } = state.authorizations;
  return {
    authorizationDetails,
    isLoading,
    currentAuthId,
  };
};

export default compose(
  connect(mapStateToProps, {
    getAuthClientPolicies,
    getEmployeeInfo,
    getExpenseSummariesByAuth,
    setAuthId,
    setLoadingComplete,
    showToast,
  }),
)(ExpenseLineItemDetailsView);
