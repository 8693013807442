import { Box } from '@material-ui/core';
import { Grid } from '@mui/material';
import { selectDefaultAssignment } from './Utils/tempLivingUtils';
import { showToast } from 'modules/layout/layout.actions';
import { useSelector } from 'react-redux';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import React, { Fragment, useEffect, useState } from 'react';
import SummaryCard from '../summaryCard.component';
import TempLivingForms from './Components/tempLivingForms.component';
import TempLivingHeader from './Components/tempLivingHeader.component';
import TempLivingStatusStepper from './Components/tempLivingStatusStepper.component';
import { useFetchDataHandler } from './useFormHandler';

const TempLivingView = ({ match, isReadOnly }) => {
  const { temporaryLivingAssignments, isLoading, details } = useSelector(state => {
    return {
      isLoading: state.authorizations.isLoading,
      temporaryLivingAssignments: state.authorizations.authorizationDetails[match.params.id]?.temporaryLivingAssignments,
      details: state.authorizations.authorizationDetails[match.params.id]
    };
  });

  const handleFetchData = useFetchDataHandler(showToast);
  const authId = match.params.id;
  const [isLoadingIconForForm, setIsLoadingIconForForm] = useState(false);
  const [selectedTempLivingAssignment, setSelectedTempLivingAssignment] = useState(selectDefaultAssignment(temporaryLivingAssignments));

  //Fetch Temp Living assignments.
  useEffect(() => {
    const fetchData = async () => {
      if (!temporaryLivingAssignments) {
        await handleFetchData(authId);
      }
    };
    fetchData();
  }, [authId, temporaryLivingAssignments, handleFetchData]);

  useEffect(() => {
    const hasAssignments = temporaryLivingAssignments?.length > 0;

    if (!hasAssignments && selectedTempLivingAssignment) {
      // If no assignments exist but one is selected (just deleted the last one), set selected to null
      setSelectedTempLivingAssignment(null);
    } else if (hasAssignments) {
      // Find the selected assignment or set the default if none is selected.
      // This is to force the Editable Card to update fields after an api call.
      const selectedAssignment =
        temporaryLivingAssignments.find(item => item?.temporaryLivingId === selectedTempLivingAssignment?.temporaryLivingId) ||
        selectDefaultAssignment(temporaryLivingAssignments);

      setSelectedTempLivingAssignment(selectedAssignment);
    }
  }, [temporaryLivingAssignments, selectedTempLivingAssignment]);

  return (
    <>
      {isLoading ? (
        <FullscreenSpinner />
      ) : (
        <>
          <TempLivingHeader
            match={match}
            showToast={showToast}
            setIsStatusCompleteLoading={setIsLoadingIconForForm}
            isReadOnly={isReadOnly}
            selectedTempLivingAssignment={selectedTempLivingAssignment}
            setSelectedTempLivingAssignment={setSelectedTempLivingAssignment}
            authId={authId}
            temporaryLivingAssignments={temporaryLivingAssignments}
          />
          {temporaryLivingAssignments && temporaryLivingAssignments.length > 0 ? (
            <Fragment>
              <TempLivingStatusStepper match={match} selectedTempLivingAssignment={selectedTempLivingAssignment} />
              <Grid container spacing={2} sx={{ padding: '20px' }}>
                {isLoadingIconForForm ? (
                  <>
                    <Grid item sm={12} md={8}>
                      <FullscreenSpinner />
                    </Grid>
                  </>
                ) : (
                  <Grid item sm={12} md={12} lg={12} xl={8} sx={{ display: 'flex', flexDirection: 'column' }}>
                    <TempLivingForms
                      showToast={showToast}
                      match={match}
                      isNewForm={false}
                      isReadOnly={isReadOnly}
                      selectedTempLivingAssignment={selectedTempLivingAssignment}
                      setSelectedTempLivingAssignment={setSelectedTempLivingAssignment}
                      authId={authId}
                    />
                  </Grid>
                )}
                <Grid item sm={12} md={12} lg={12} xl={4}>
                  <Box sx={{ position: 'sticky', top: 180, height: 'fit-content' }}>
                    <SummaryCard dateSummary={details.dateSummary} id={match.params.id} hideEdit={true} />
                  </Box>
                </Grid>
              </Grid>
            </Fragment>
          ) : (
            <div style={{ padding: '20px' }} />
          )}
        </>
      )}
    </>
  );
};

export default TempLivingView;
