const { CALL_INTAKE_V2_API } = require('middleware/intakeV2Api');
const {
  GET_HHG_ITEMS_REQUEST,
  GET_HHG_ITEMS_SUCCESS,
  GET_HHG_ITEMS_FAILURE,
  POST_HHG_ITEMS_REQUEST,
  POST_HHG_ITEMS_SUCCESS,
  POST_HHG_ITEMS_FAILURE,
  UPDATE_HHG_ITEMS_REQUEST,
  UPDATE_HHG_ITEMS_SUCCESS,
  UPDATE_HHG_ITEMS_FAILURE,
  DELETE_HHG_ITEMS_REQUEST,
  DELETE_HHG_ITEMS_SUCCESS,
  DELETE_HHG_ITEMS_FAILURE,
  GET_HHG_ESTIMATES_REQUEST,
  GET_HHG_ESTIMATES_SUCCESS,
  GET_HHG_ESTIMATES_FAILURE,
  POST_HHG_ESTIMATE_REQUEST,
  POST_HHG_ESTIMATE_SUCCESS,
  POST_HHG_ESTIMATE_FAILURE,
  UPDATE_HHG_ESTIMATE_REQUEST,
  UPDATE_HHG_ESTIMATE_SUCCESS,
  UPDATE_HHG_ESTIMATE_FAILURE,
  GET_HHG_SEGMENTS_REQUEST,
  GET_HHG_SEGMENTS_SUCCESS,
  GET_HHG_SEGMENTS_FAILURE,
  POST_HHG_SEGMENT_REQUEST,
  POST_HHG_SEGMENT_SUCCESS,
  POST_HHG_SEGMENT_FAILURE,
  UPDATE_HHG_SEGMENT_REQUEST,
  UPDATE_HHG_SEGMENT_SUCCESS,
  UPDATE_HHG_SEGMENT_FAILURE,
  GET_HHG_MOVING_DATE_REQUEST,
  GET_HHG_MOVING_DATE_SUCCESS,
  GET_HHG_MOVING_DATE_FAILURE,
  POST_HHG_MOVING_DATE_REQUEST,
  POST_HHG_MOVING_DATE_SUCCESS,
  POST_HHG_MOVING_DATE_FAILURE,
  UPDATE_HHG_MOVING_DATE_REQUEST,
  UPDATE_HHG_MOVING_DATE_SUCCESS,
  UPDATE_HHG_MOVING_DATE_FAILURE,
  DELETE_HHG_MOVING_DATE_REQUEST,
  DELETE_HHG_MOVING_DATE_SUCCESS,
  DELETE_HHG_MOVING_DATE_FAILURE,
} = require('../types/houseHoldGoodsTypes');

export const getHhgItems = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_HHG_ITEMS_REQUEST, GET_HHG_ITEMS_SUCCESS, GET_HHG_ITEMS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/hhgItem`,
      method: 'GET',
    },
  };
};

export const postHhgItems = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_HHG_ITEMS_REQUEST, POST_HHG_ITEMS_SUCCESS, POST_HHG_ITEMS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/hhgItem/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateHhgItems = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_HHG_ITEMS_REQUEST, UPDATE_HHG_ITEMS_SUCCESS, UPDATE_HHG_ITEMS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/hhgItem/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteHhgItems = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_HHG_ITEMS_REQUEST, DELETE_HHG_ITEMS_SUCCESS, DELETE_HHG_ITEMS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/hhgItem`,
      method: 'DELETE',
    },
  };
};
/* HHG Requests */

export const getHhgEstimates = (segmentId) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_HHG_ESTIMATES_REQUEST, GET_HHG_ESTIMATES_SUCCESS, GET_HHG_ESTIMATES_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${segmentId}/hhgEstimate`,
      method: 'GET',
    },
  };
};

export const postHhgEstimate = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_HHG_ESTIMATE_REQUEST, POST_HHG_ESTIMATE_SUCCESS, POST_HHG_ESTIMATE_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/hhgEstimate/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateHhgEstimate = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_HHG_ESTIMATE_REQUEST, UPDATE_HHG_ESTIMATE_SUCCESS, UPDATE_HHG_ESTIMATE_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/hhgEstimate/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
}; /* HHG Requests */

export const getHhgSegments = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_HHG_SEGMENTS_REQUEST, GET_HHG_SEGMENTS_SUCCESS, GET_HHG_SEGMENTS_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/hhg`,
      method: 'GET',
    },
  };
};

export const postHhgSegment = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_HHG_SEGMENT_REQUEST, POST_HHG_SEGMENT_SUCCESS, POST_HHG_SEGMENT_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/hhg/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateHhgSegment = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_HHG_SEGMENT_REQUEST, UPDATE_HHG_SEGMENT_SUCCESS, UPDATE_HHG_SEGMENT_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/hhg/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const getHhgMovingDates = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [GET_HHG_MOVING_DATE_REQUEST, GET_HHG_MOVING_DATE_SUCCESS, GET_HHG_MOVING_DATE_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/hhgMovingDate`,
      method: 'GET',
    },
  };
};

export const postHhgMovingDate = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [POST_HHG_MOVING_DATE_REQUEST, POST_HHG_MOVING_DATE_SUCCESS, POST_HHG_MOVING_DATE_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/hhgMovingDate/${username}`,
      method: 'POST',
      payload: values,
    },
  };
};

export const updateHhgMovingDate = (values) => {
  const username = localStorage.getItem('username') ? localStorage.getItem('username') : '';
  return {
    [CALL_INTAKE_V2_API]: {
      types: [UPDATE_HHG_MOVING_DATE_REQUEST, UPDATE_HHG_MOVING_DATE_SUCCESS, UPDATE_HHG_MOVING_DATE_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/hhgMovingDate/${username}`,
      method: 'PUT',
      payload: values,
    },
  };
};

export const deleteHhgMovingDate = (id) => {
  return {
    [CALL_INTAKE_V2_API]: {
      types: [DELETE_HHG_MOVING_DATE_REQUEST, DELETE_HHG_MOVING_DATE_SUCCESS, DELETE_HHG_MOVING_DATE_FAILURE],
      authenticated: true,
      endpoint: `api/authorizations/${id}/hhgMovingDate`,
      method: 'DELETE',
    },
  };
};
