
import { POST_DEPARTURE_ADDRESS_FAILURE, POST_DESTINATION_ADDRESS_FAILURE, UPDATE_DEPARTURE_ADDRESS_FAILURE, UPDATE_DESTINATION_ADDRESS_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postDepartureAddress, postDestinationAddress, updateDepartureAddress, updateDestinationAddress } from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const AddressCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { departureAddress, destinationAddress } = props;

  const tabs = [
    { header: 'Departure Address', order: 1, iconPath: ['fas', 'truck-moving'] },
    { header: 'Destination Address', order: 2, iconPath: ['fas', 'house'] },
  ];

  const summaries = [
    { header: 'Country', accessorKey: 'country', type: 'text', required: false, value: departureAddress ? departureAddress.country : '', tab: 1 },
    { header: 'County', accessorKey: 'county', type: 'text', required: false, value: departureAddress ? departureAddress.county : '', tab: 1 },
    { header: 'Address Type', accessorKey: 'addressType', type: 'text', required: false, value: departureAddress ? departureAddress.addressType : '', tab: 1 },
    { header: 'Line 1', accessorKey: 'addressLineOne', type: 'text', required: false, value: departureAddress ? departureAddress.addressLineOne : '', tab: 1 },
    { header: 'City', accessorKey: 'city', type: 'text', required: false, value: departureAddress ? departureAddress.city : '', tab: 1 },
    { header: 'Inventory', accessorKey: 'inventory', type: 'text', lineBelow: true, required: false, value: departureAddress ? departureAddress.inventory : '', tab: 1 },
    { header: 'Line 2', accessorKey: 'addressLineTwo', type: 'text', required: false, value: departureAddress ? departureAddress.addressLineTwo : '', tab: 1 },
    { header: 'State/Province', accessorKey: 'state', type: 'text', required: false, value: departureAddress ? departureAddress.state : '', tab: 1 },
    { header: 'Title', accessorKey: 'title', type: 'text', required: false, value: departureAddress ? departureAddress.title : '', tab: 1 },
    { header: 'Line 3', accessorKey: 'addressLineThree', type: 'text', required: false, value: departureAddress ? departureAddress.addressLineThree : '', tab: 1 },
    { header: 'Zip/Postal', accessorKey: 'postalCode', type: 'text', required: false, value: departureAddress ? departureAddress.postalCode : '', tab: 1 },
    { header: 'Department', accessorKey: 'department', type: 'text', required: false, value: departureAddress ? departureAddress.department : '', tab: 1 },
    { header: 'Country', accessorKey: 'country', type: 'text', required: false, value: destinationAddress ? destinationAddress.country : '', tab: 2 },
    { header: 'County', accessorKey: 'county', type: 'text', required: false, value: destinationAddress ? destinationAddress.county : '', tab: 2 },
    { header: 'Address Type', accessorKey: 'addressType', type: 'text', lineBelow: true, required: false, value: destinationAddress ? destinationAddress.addressType : '', tab: 2 },
    { header: 'Line 1', accessorKey: 'addressLineOne', type: 'text', required: false, value: destinationAddress ? destinationAddress.addressLineOne : '', tab: 2 },
    { header: 'City', accessorKey: 'city', type: 'text', required: false, value: destinationAddress ? destinationAddress.city : '', tab: 2 },
    { emptyField: true, tab: 2 },
    { header: 'Line 2', accessorKey: 'addressLineTwo', type: 'text', required: false, value: destinationAddress ? destinationAddress.addressLineTwo : '', tab: 2 },
    { header: 'State/Province', accessorKey: 'state', type: 'text', required: false, value: destinationAddress ? destinationAddress.state : '', tab: 2 },
    { header: 'Title', accessorKey: 'title', type: 'text', required: false, value: destinationAddress ? destinationAddress.title : '', tab: 2 },
    { header: 'Line 3', accessorKey: 'addressLineThree', type: 'text', required: false, value: destinationAddress ? destinationAddress.addressLineThree : '', tab: 2 },
    { header: 'Zip/Postal', accessorKey: 'postalCode', type: 'text', required: false, value: destinationAddress ? destinationAddress.postalCode : '', tab: 2 },
    { header: 'Department', accessorKey: 'department', type: 'text', required: false, value: destinationAddress ? destinationAddress.department : '', tab: 2 },
       
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    values.authorizationId = props.id;

    if (tab === 1) {
      if (departureAddress && departureAddress.length !== 0) {
        const resp = await props.updateDepartureAddress(values);
        if (resp.type === UPDATE_DEPARTURE_ADDRESS_FAILURE) {
          props.showToast('Failed to update departure info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      } else {
        const resp = await props.postDepartureAddress(values);
        if (resp.type === POST_DEPARTURE_ADDRESS_FAILURE) {
          props.showToast('Failed to update departure info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      }
    } else {
      if (destinationAddress && destinationAddress.length !== 0) {
        const resp = await props.updateDestinationAddress(values);
        if (resp.type === UPDATE_DESTINATION_ADDRESS_FAILURE) {
          props.showToast('Failed to update destination info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      } else {
        const resp = await props.postDestinationAddress(values);
        if (resp.type === POST_DESTINATION_ADDRESS_FAILURE) {
          props.showToast('Failed to update destination info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          setIsLoading(false);
          return false;
        } else { 
          setIsLoading(false);
          return true; 
        }
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
    />
  );

};

export default compose(
  connect(null, {
    postDepartureAddress, 
    postDestinationAddress, 
    updateDepartureAddress, 
    updateDestinationAddress,
    showToast,
  }),
)(AddressCard);