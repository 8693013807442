import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_HHG_ESTIMATE_FAILURE } from 'modules/authorizations/store/types/houseHoldGoodsTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateHhgEstimate } from 'modules/authorizations/store/actions/houseHoldGoods.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const VendorInfoCard = (props) => {

  const { estimateInfo, vendors, vendorContacts } = props;

  const [isLoading, setIsLoading] = useState(false);

  const tabs = [
    { header: 'Vendor Info', order: 1, iconPath: ['fas', 'person-walking-luggage'] },
  ];

  const vendorSearchInfo = {
    searchingColumns: [{ name: 'vendorId', title: 'Vendor Id' }, { name: 'companyName', title: 'Company Name' }, { name: 'city', title: 'City' }, { name: 'state', title: 'State' }, { name: 'phone', title: 'Company Phone' }, { name: 'email', title: 'Company Email' }],
    searchingColumnExtensions: [{ columnName: 'vendorId', width: 150 }, { columnName: 'companyName', width: 250 }, { name: 'city', width: 175 }, { name: 'state', width: 175 }, { columnName: 'phone', width: 175 }, { columnName: 'email', width: 175 }],
    searchingRows: vendors,
    idKey: 'vendorId',
    nameKey: 'companyName',
  };

  const vendorContactSearchInfo = {
    searchingColumns: [{ name: 'vendorContactId', title: 'Vendor Contact Id' }, { name: 'vendorId', title: 'Vendor Id' }, { name: 'fullName', title: 'Full Name' }, { name: 'officePhone', title: 'Contact Phone' }, { name: 'officeEmail', title: 'Contact Email' }],
    searchingColumnExtensions: [{ columnName: 'vendorContactId', width: 150 }, { columnName: 'vendorId', width: 150 }, { columnName: 'fullName', width: 200 }, { columnName: 'officePhone', width: 175 }, { columnName: 'officeEmail', width: 175 }],
    searchingRows: vendorContacts,
    idKey: 'vendorContactId',
    nameKey: 'fullName',
  };

  const summaries = [
    { header: 'Vendor Name', accessorKey: 'vendorId', nameKey: 'companyName', nameValue: estimateInfo ? estimateInfo.vendorCompanyName : '', type: 'advanced-search', searchInfo: vendorSearchInfo, required: true,  value: estimateInfo ? estimateInfo.vendorId : '', tab: 1, hideInTable: true },
    { header: 'Vendor Contact', accessorKey: 'vendorContactId', nameKey: 'fullName', nameValue: estimateInfo ? estimateInfo.vendorContactName: '',  type: 'advanced-search', searchInfo: vendorContactSearchInfo, required: true,  value: estimateInfo ? estimateInfo.vendorContactId : '', tab: 1, hideInTable: true },
    // { header: 'Vendor Id', accessorKey: 'vendorId', type: 'text', required: false, value: clientPolicies ? clientPolicies.clientId : '', tab: 1, hideInModal: true },
    { header: 'Company Name', accessorKey: 'vendorCompanyName', type: 'text',  required: false, value: estimateInfo ? estimateInfo.vendorCompanyName : '', tab: 1, hideInModal: true },
    { header: 'Company Phone', accessorKey: 'vendorPhone', type: 'text', required: false, value: estimateInfo ? estimateInfo.vendorPhone : '', tab: 1, hideInModal: true },
    { header: 'Company Email', accessorKey: 'vendorEmail', type: 'text', required: false, value: estimateInfo ? estimateInfo.vendorEmail : '', tab: 1, hideInModal: true },
    { header: 'Contact Name', accessorKey: 'vendorContactName', type: 'text', required: false,  value: estimateInfo ? estimateInfo.vendorContactName : '', tab: 1, hideInModal: true },
    { header: 'Contact Email', accessorKey: 'vendorContactEmail', type: 'text', required: false,  value: estimateInfo ? estimateInfo.vendorContactEmail : '', tab: 1, hideInModal: true },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...estimateInfo };
    
    if (values.vendorId != null) {
      const newVendor = vendors.find((v) => v.vendorId === values.vendorId);
      newValues.vendorId = newVendor.vendorId;
      newValues.vendorCompanyName = newVendor.companyName;
      newValues.vendorPhone = newVendor.phone;
      newValues.vendorEmail = newVendor.email;
    }

    if (values.vendorContactId != null) {
      const newVendorContact = vendorContacts.find((v) => v.vendorContactId === values.vendorContactId);
      newValues.vendorContactId = newVendorContact.vendorContactId;
      newValues.vendorContactName = newVendorContact.fullName;
      newValues.vendorContactEmail = newVendorContact.officeEmail;
    }
        
    if (estimateInfo && estimateInfo.length !== 0) {
      const resp = await props.updateHhgEstimate(newValues);
      if (resp.type === UPDATE_HHG_ESTIMATE_FAILURE) {
        props.showToast('Failed to update the vendor info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={props.hideEdit}
    />
  );

};

const mapStateToProps = (state) => {
  const {
    authorizations: { vendors, vendorContacts },
  } = state;
  return { vendors, vendorContacts };
};

export default compose(
  connect(mapStateToProps, {
    updateHhgEstimate,
    showToast,
  }),
)(VendorInfoCard);