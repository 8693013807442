import { GenderOptions, PrefixOptions, RelationshipOptions, SuffixOptions, ValueType, YesOrNo, parseFormValue } from './formFieldConstants';
import { calculateAge } from 'utilities/common';

export const createFamilyColumns = () => {
  return [
    {
      accessorKey: 'prefix',
      header: 'Prefix',
      type: 'dropdown',
      required: false,
      options: PrefixOptions,
      hideInTable: true,
      displayInHeaderRow: true,
    },
    { accessorKey: 'firstName', header: 'First Name', type: 'text', required: true, size: 65, displayInHeaderRow: true },
    { accessorKey: 'middle', header: 'Middle', type: 'text', required: false, size: 65, hideInTable: true, displayInHeaderRow: true },
    { accessorKey: 'lastName', header: 'Last Name', type: 'text', required: true, size: 65, displayInHeaderRow: true },
    {
      accessorKey: 'suffix',
      header: 'Suffix',
      type: 'dropdown',
      required: false,
      options: SuffixOptions,
      hideInTable: true,
      displayInHeaderRow: true,
    },
    {
      accessorKey: 'relationship',
      header: 'Relationship',
      type: 'dropdown',
      required: false,
      options: RelationshipOptions,
      size: 65,
    },
    { accessorKey: 'ssn', header: 'Social Sec. Number', type: 'text', required: false, hideInTable: true },
    {
      accessorKey: 'birthDate',
      header: 'Birthday',
      type: 'date',
      required: false,
      hideInTable: true,
      Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-'),
    },
    //TODO - Calculate this
    {
      accessorKey: 'age',
      header: 'Age',
      type: 'number',
      required: false,
      hideInModel: true,
      size: 65,
    },
    { accessorKey: 'gender', header: 'Gender', type: 'dropdown', required: false, options: GenderOptions, hideInTable: true },
    { accessorKey: 'primaryPhoneNumber', header: 'Primary Phone Number', type: 'text', required: false, hideInTable: true },
    { accessorKey: 'primaryEmailAddress', header: 'Primary Email Address', type: 'text', required: false, hideInTable: true },
    {
      accessorKey: 'highSchoolGradDate',
      header: 'High School GradDate',
      type: 'date',
      required: false,
      hideInTable: true,
      Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-'),
    },
    {
      accessorKey: 'onAssignmentWithFamily',
      header: 'On Assignment With Family',
      type: 'dropdown',
      required: false,
      options: YesOrNo,
      hideInTable: true,
    },
    {
      accessorKey: 'notPartOfFinalMove',
      header: 'Not Part of Final Move',
      type: 'dropdown',
      required: false,
      options: YesOrNo,
      hideInTable: true,
    },
    { accessorKey: 'visaType', header: 'Visa Type', type: 'text', required: false, hideInTable: true },
    { accessorKey: 'visaIssuedBy', header: 'Visa Issued By', type: 'text', required: false, hideInTable: true },
    { accessorKey: 'visaDocumentNumber', header: 'Visa Document Number', type: 'text', required: false, hideInTable: true },
    {
      accessorKey: 'passportRecievedDate',
      header: 'Passport Recieved Date',
      type: 'date',
      required: false,
      hideInTable: true,
      Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-'),
    },
    { accessorKey: 'passportIssuedBy', header: 'Passport Issued By', type: 'text', required: false, hideInTable: true },
    {
      accessorKey: 'immunizationRequired',
      header: 'Immunization Required',
      type: 'dropdown',
      required: false,
      options: YesOrNo,
      hideInTable: true,
    },
    {
      accessorKey: 'immunizationFiled',
      header: 'Immunization Filed',
      type: 'dropdown',
      required: false,
      options: YesOrNo,
      hideInTable: true,
    },
    { accessorKey: 'workPermit', header: 'Work Permit', type: 'dropdown', required: false, options: YesOrNo, hideInTable: true },
    {
      accessorKey: 'filedDate',
      header: 'FiledDate',
      type: 'date',
      required: false,
      hideInTable: true,
      Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-'),
    },
    {
      accessorKey: 'aquiredDate',
      header: 'Aquired Date',
      type: 'date',
      required: false,
      hideInTable: true,
      Cell: ({ cell }) => (cell.getValue() ? new Date(cell.getValue()).toLocaleDateString('en-us') : '-'),
    },
    { accessorKey: 'languages', header: 'Languages', type: 'notes', required: false, hideInTable: true },
    { accessorKey: 'specialNeeds', header: 'Special Needs', type: 'notes', required: false, size: 65 },
    { accessorKey: 'comments', header: 'Comments', type: 'notes', required: false, hideInTable: true },
  ];
};

export const createFamilyRows = (formDataList) => {
  if (!formDataList || !Array.isArray(formDataList)) {
    return [];
  }

  return formDataList.map((data) => mapFamilyRow(data));
};

export const mapFamilyRow = (data) => {
  return {
    ...data,
    prefix: !data?.prefix || data?.prefix === '' ? PrefixOptions[0] : data?.prefix,
    suffix: !data?.suffix || data?.suffix === '' ? SuffixOptions[0] : data?.suffix,

    onAssignmentWithFamily: data?.onAssignmentWithFamily ? YesOrNo[0] : YesOrNo[1],
    notPartOfFinalMove: data?.notPartOfFinalMove ? YesOrNo[0] : YesOrNo[1],
    immunizationRequired: data?.immunizationRequired ? YesOrNo[0] : YesOrNo[1],
    immunizationFiled: data?.immunizationFiled ? YesOrNo[0] : YesOrNo[1],
    workPermit: data?.workPermit ? YesOrNo[0] : YesOrNo[1],

    birthDate: parseFormValue(data.birthDate, ValueType.DateTimeType),
    age: calculateAge(data.birthDate),
    highSchoolGradDate: parseFormValue(data.highSchoolGradDate, ValueType.DateTimeType),
    passportRecievedDate: parseFormValue(data.passportRecievedDate, ValueType.DateTimeType),
    filedDate: parseFormValue(data.filedDate, ValueType.DateTimeType),
    aquiredDate: parseFormValue(data.aquiredDate, ValueType.DateTimeType),
  };
};

export const createFamilyHeaderRow = () => {
  return { accessorKey: 'relationship', value: RelationshipOptions[0], title: 'Significant Other' };
};

export const getNameOfDeleteRow = (row) => {
  return `${row.getValue('firstName') ?? ''} ${row.getValue('lastName') ?? ''}`;
};

export const createFamilyPostRequest = (formData, authorizationId) => {
  const request = { ...formData };
  request.authorizationId = authorizationId;
  parseFamilyFormValues(request, formData);
  return request;
};

export const createFamilyPutRequest = (originalFormData, newFormData, authorizationId) => {
  const request = { ...newFormData };
  request.authorizationId = authorizationId;
  request.familyMemberId = originalFormData.familyMemberId;
  parseFamilyFormValues(request, newFormData);
  return request;
};

const parseFamilyFormValues = (currentRequest, newRequestValues) => {
  currentRequest.onAssignmentWithFamily = newRequestValues.onAssignmentWithFamily === YesOrNo[0];
  currentRequest.notPartOfFinalMove = newRequestValues.notPartOfFinalMove === YesOrNo[0];
  currentRequest.immunizationRequired = newRequestValues.immunizationRequired === YesOrNo[0];
  currentRequest.immunizationFiled = newRequestValues.immunizationFiled === YesOrNo[0];
  currentRequest.workPermit = newRequestValues.workPermit === YesOrNo[0];

  currentRequest.birthDate = parseFormValue(newRequestValues.birthDate, ValueType.DateTimeType);
  currentRequest.age = calculateAge(newRequestValues.birthDate);
  currentRequest.highSchoolGradDate = parseFormValue(newRequestValues.highSchoolGradDate, ValueType.DateTimeType);
  currentRequest.passportRecievedDate = parseFormValue(newRequestValues.passportRecievedDate, ValueType.DateTimeType);
  currentRequest.filedDate = parseFormValue(newRequestValues.filedDate, ValueType.DateTimeType);
  currentRequest.aquiredDate = parseFormValue(newRequestValues.aquiredDate, ValueType.DateTimeType);
};
