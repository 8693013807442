
import { POST_USER_SETTINGS_FAILURE, UPDATE_USER_SETTINGS_FAILURE } from '../store/types/authorizationTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postUserSettings, updateUserSettings } from '../store/actions/authorizations.actions';
import { showToast } from 'modules/layout/layout.actions';
import React, { useState } from 'react';
import UserSettingsModal from './userSettingsModal.component';

const SettingsModal = (props) => {

  const { authId, authDetails } = props;
  const details = authDetails ? authDetails[authId] : {};
  const [isLoading, setIsLoading] = useState(false);

  const userSettings = details ? details.userSettings : {};

  const updateFields = async (vip, webAccess, excludeFromVendorReport, far, excludeFromSystemReport) => {
    setIsLoading(true);

    const values = {
      'authorizationId': authId,
      'vip': vip ? vip : false,
      'webAccess': webAccess ? webAccess : false,
      'excludeFromVendorReport': excludeFromVendorReport ? excludeFromVendorReport : false,
      'far': far ? far : false,
      'excludeFromSystemReport': excludeFromSystemReport ? excludeFromSystemReport : false,
    };

    // update values that are set as true/false

    if (userSettings && userSettings.length !== 0) {
      const resp = await props.updateUserSettings(values);
      if (resp.type === UPDATE_USER_SETTINGS_FAILURE) {
        props.showToast('Failed to update the employee settings, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      const resp = await props.postUserSettings(values);
      if (resp.type === POST_USER_SETTINGS_FAILURE) {
        props.showToast('Failed to update the employee settings, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <UserSettingsModal
      settingsInfo={userSettings}
      updateFields={updateFields}
      isLoading={isLoading}
    />
  );

};

export default compose(
  connect(null, {
    postUserSettings,
    updateUserSettings,
    showToast,
  }),
)(SettingsModal);