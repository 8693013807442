/* eslint-disable no-case-declarations */
import {
  GET_ACTIVITY_LOG_REQUEST,
  GET_ALL_AUTH_SERVICES_FAILURE,
  GET_ALL_AUTH_SERVICES_REQUEST,
  GET_ALL_AUTH_SERVICES_SUCCESS,
  GET_AUTHORIZATIONS_FAILURE,
  GET_AUTHORIZATIONS_REQUEST,
  GET_AUTHORIZATIONS_SUCCESS,
  GET_AUTH_CLIENT_POLICIES_REQUEST,
  GET_AUTH_CLIENT_POLICIES_SUCCESS,
  GET_AUTH_DATE_SUMMARY_REQUEST,
  GET_AUTH_DATE_SUMMARY_SUCCESS,
  GET_AUTH_LIST_CLIENT_POLICIES_FAILURE,
  GET_AUTH_LIST_CLIENT_POLICIES_REQUEST,
  GET_AUTH_LIST_CLIENT_POLICIES_SUCCESS,
  GET_AUTH_SERVICES_REQUEST,
  GET_AUTH_SERVICES_SUCCESS,
  GET_AUTH_SUMMARY_SUCCESS,
  GET_AUTH_TEAM_SUCCESS,
  GET_COMMUNICATION_INFO_REQUEST,
  GET_COMMUNICATION_INFO_SUCCESS,
  GET_DEPARTURE_ADDRESS_SUCCESS,
  GET_DESTINATION_ADDRESS_SUCCESS,
  GET_EMPLOYEE_INFO_REQUEST,
  GET_EMPLOYEE_INFO_SUCCESS,
  GET_FAMILY_PET_REQUEST,
  GET_FAMILY_PET_SUCCESS,
  GET_FAMILY_REQUEST,
  GET_FAMILY_SUCCESS,
  GET_SO_INFO_REQUEST,
  GET_SO_INFO_SUCCESS,
  GET_USER_SETTINGS_REQUEST,
  GET_USER_SETTINGS_SUCCESS,
  POST_AUTH_CLIENT_POLICIES_SUCCESS,
  POST_AUTH_DATE_SUMMARY_SUCCESS,
  POST_AUTH_SERVICES_SUCCESS,
  POST_AUTH_TEAM_SUCCESS,
  POST_COMMUNICATION_INFO_SUCCESS,
  POST_DEPARTURE_ADDRESS_SUCCESS,
  POST_DESTINATION_ADDRESS_SUCCESS,
  POST_EMPLOYEE_INFO_SUCCESS,
  POST_FAMILY_PET_SUCCESS,
  POST_FAMILY_SUCCESS,
  POST_SO_INFO_SUCCESS,
  POST_USER_SETTINGS_SUCCESS,
  SET_AUTH_ID,
  SET_LOADING_COMPLETE,
  UPDATE_AUTH_CLIENT_POLICIES_SUCCESS,
  UPDATE_AUTH_DATE_SUMMARY_SUCCESS,
  UPDATE_AUTH_SERVICES_SUCCESS,
  UPDATE_AUTH_TEAM_SUCCESS,
  UPDATE_COMMUNICATION_INFO_SUCCESS,
  UPDATE_DEPARTURE_ADDRESS_SUCCESS,
  UPDATE_DESTINATION_ADDRESS_SUCCESS,
  UPDATE_EMPLOYEE_INFO_SUCCESS,
  UPDATE_FAMILY_DATA,
  UPDATE_FAMILY_PET_DATA,
  UPDATE_FAMILY_PET_SUCCESS,
  UPDATE_FAMILY_SUCCESS,
  UPDATE_SO_INFO_SUCCESS,
  UPDATE_USER_SETTINGS_SUCCESS,
} from '../types/authorizationTypes';

const handleAuthorizationReducer = (draft, action) => {
  if (!draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};

  switch (action.type) {
    case GET_AUTHORIZATIONS_REQUEST:
      draft.authorizationSummariesLoading = true;
      draft.isLoading = true;
      break;

    case GET_ALL_AUTH_SERVICES_REQUEST:
      draft.allServicesLoading = true;
      draft.isLoading = true;
      break;

    case GET_EMPLOYEE_INFO_REQUEST:
    case GET_SO_INFO_REQUEST:
    case GET_AUTH_DATE_SUMMARY_REQUEST:
    case GET_COMMUNICATION_INFO_REQUEST:
    case GET_AUTH_CLIENT_POLICIES_REQUEST:
    case GET_AUTH_SERVICES_REQUEST:
    case GET_USER_SETTINGS_REQUEST:
    case GET_FAMILY_REQUEST:
    case GET_FAMILY_PET_REQUEST:
      draft.isLoading = true;
      break;

    case GET_AUTHORIZATIONS_FAILURE:
      draft.authorizationSummariesLoading = false;
      draft.isLoading = false;
      break;

    case GET_AUTHORIZATIONS_SUCCESS:
      draft.authorizationSummariesLoading = false;
      draft.isLoading = false;
      draft.authorizationSummaries = action.response;
      break;

    case GET_ALL_AUTH_SERVICES_FAILURE:
      draft.allServicesLoading = false;
      draft.isLoading = false;
      break;

    case GET_ALL_AUTH_SERVICES_SUCCESS:
      draft.allServicesLoading = false;
      draft.isLoading = false;
      draft.allServices = action.response;
      break;

    case SET_AUTH_ID:
      draft.currentAuthId = action.response;
      break;

    case GET_USER_SETTINGS_SUCCESS:
      draft.isLoading = false;
      draft.authorizationDetails[draft.currentAuthId].userSettings = action.response;
      break;

    case SET_LOADING_COMPLETE:
      draft.isLoading = false;
      break;

    case GET_AUTH_SUMMARY_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].summary = action.response;
      break;

    case GET_EMPLOYEE_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].employee = action.response;
      break;

    case GET_SO_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].significantOther = action.response;
      break;

    case GET_COMMUNICATION_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].communication = action.response;
      break;

    case GET_AUTH_CLIENT_POLICIES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authClientPolicies = action.response;
      break;

    case GET_AUTH_SERVICES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authServices = action.response;
      break;

    case UPDATE_SO_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].significantOther = action.response;
      break;

    case UPDATE_EMPLOYEE_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].employee = action.response;
      break;

    case UPDATE_COMMUNICATION_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].communication = action.response;
      break;

    case UPDATE_AUTH_CLIENT_POLICIES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authClientPolicies = action.response;
      break;

    case UPDATE_AUTH_SERVICES_SUCCESS:
      if (draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId].authServices = action.response;
      break;

    case UPDATE_USER_SETTINGS_SUCCESS:
      if (draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId].userSettings = action.response;
      break;

    case POST_EMPLOYEE_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].employee = action.response;
      break;

    case POST_SO_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].significantOther = action.response;
      break;

    case POST_AUTH_SERVICES_SUCCESS:
      if (draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId].authServices = action.response;
      break;

    case UPDATE_AUTH_DATE_SUMMARY_SUCCESS:
    case GET_AUTH_DATE_SUMMARY_SUCCESS:
    case POST_AUTH_DATE_SUMMARY_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].dateSummary = action.response;
      break;

    case POST_COMMUNICATION_INFO_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].communication = action.response;
      break;

    case POST_AUTH_CLIENT_POLICIES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].authClientPolicies = action.response;
      break;

    case GET_DEPARTURE_ADDRESS_SUCCESS:
    case UPDATE_DEPARTURE_ADDRESS_SUCCESS:
    case POST_DEPARTURE_ADDRESS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].departureAddress = action.response;
      break;

    case UPDATE_DESTINATION_ADDRESS_SUCCESS:
    case GET_DESTINATION_ADDRESS_SUCCESS:
    case POST_DESTINATION_ADDRESS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].destinationAddress = action.response;
      break;

    case GET_AUTH_LIST_CLIENT_POLICIES_REQUEST:
      draft.clientPoliciesLoading = true;
      break;

    case GET_AUTH_LIST_CLIENT_POLICIES_SUCCESS:
      draft.clientPoliciesLoading = false;
      draft.clientPolicies = action.response;
      break;

    case GET_AUTH_LIST_CLIENT_POLICIES_FAILURE:
      draft.clientPoliciesLoading = false;
      break;

    case GET_AUTH_TEAM_SUCCESS:
    case UPDATE_AUTH_TEAM_SUCCESS:
    case POST_AUTH_TEAM_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].team = action.response;
      break;

    case POST_USER_SETTINGS_SUCCESS:
      if (draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId].userSettings = action.response;
      break;

    case GET_ACTIVITY_LOG_REQUEST:
      draft.isLoading = true;
      break;

    case GET_FAMILY_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].family = action.response;
      break;

    case UPDATE_FAMILY_SUCCESS: {
      const familyMembers = draft.authorizationDetails[draft.currentAuthId]?.family;
      if (familyMembers) {
        const indexToUpdate = familyMembers.findIndex((fam) => fam.familyMemberId === action.response?.familyMemberId);
        if (indexToUpdate !== -1) {
          draft.authorizationDetails[draft.currentAuthId].family[indexToUpdate] = action.response;
        }
      }
      break;
    }

    case POST_FAMILY_SUCCESS: {
      const familyMembers = draft.authorizationDetails[draft.currentAuthId]?.family || [];
      draft.authorizationDetails[draft.currentAuthId].family = [...familyMembers, action.response];
      break;
    }

    case UPDATE_FAMILY_DATA: {
      const familyMembers = draft.authorizationDetails[draft.currentAuthId]?.family;
      if (familyMembers) {
        draft.authorizationDetails[draft.currentAuthId].family = action.response;
      }
      break;
    }

    case GET_FAMILY_PET_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].familyPets = action.response;
      break;

    case UPDATE_FAMILY_PET_SUCCESS: {
      const familyPets = draft.authorizationDetails[draft.currentAuthId]?.familyPets;
      if (familyPets) {
        const indexToUpdate = familyPets.findIndex((pet) => pet.familyPetId === action.response?.familyPetId);
        if (indexToUpdate !== -1) {
          draft.authorizationDetails[draft.currentAuthId].familyPets[indexToUpdate] = action.response;
        }
      }
      break;
    }

    case POST_FAMILY_PET_SUCCESS: {
      const familyPets = draft.authorizationDetails[draft.currentAuthId]?.familyPets || [];
      draft.authorizationDetails[draft.currentAuthId].familyPets = [...familyPets, action.response];
      break;
    }

    case UPDATE_FAMILY_PET_DATA: {
      const familyPets = draft.authorizationDetails[draft.currentAuthId]?.familyPets;
      if (familyPets) {
        draft.authorizationDetails[draft.currentAuthId].familyPets = action.response;
      }
      break;
    }
    default:
      break;
  }
  return draft;
};

export default handleAuthorizationReducer;
