import { POST_GLOBAL_INFO_FAILURE, UPDATE_GLOBAL_INFO_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { postGlobalInfo, updateGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { showToast } from 'modules/layout/layout.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

import countries from 'modules/common/countries.json';

const OverviewGlobalCard = (props) => {

  const [isLoading, setIsLoading] = useState(false);

  const { globalInfo, id, isReadOnly } = props;

  const basicOptions = [
    { title: 'Yes', id: 1 },
    { title: 'No', id: 2 },
  ];

  const typeOptions = [
    { title: 'Long Term', id: 1 },
    { title: 'Short Term', id: 2 },
  ];

  const tabs = [
    { header: 'Overview', order: 1, iconPath: ['fas', 'suitcase'] },
    { header: 'Dates', order: 2, iconPath: ['fas', 'calendar-days'] },
  ];

  const summaries = [
    { header: 'Description', accessorKey: 'description', type: 'text', required: false, value: globalInfo ? globalInfo.description : '', tab: 1 },
    { header: 'Type', accessorKey: 'type', type: 'dropdown-v2', required: false, value: globalInfo ? globalInfo.type : '', options: typeOptions, tab: 1 },
    { header: 'Country', accessorKey: 'country', type: 'dropdown-v2', required: false, value: globalInfo ? globalInfo.country : '', options: countries, tab: 1 },
    { header: 'Length', accessorKey: 'lengthOfAssignment', type: 'text', required: false, value: globalInfo ? globalInfo.lengthOfAssignment : '', tab: 1 },
    { header: 'Client Number', accessorKey: 'clientNumber', type: 'text', required: false, value: globalInfo ? globalInfo.clientNumber : '', tab: 1 },
    { header: 'Home Country', accessorKey: 'homeCountry', type: 'dropdown-v2', required: false,  value: globalInfo ? globalInfo.homeCountry : '', options: countries, tab: 1 },
    { header: 'Home State', accessorKey: 'homeState', type: 'text', required: false, value: globalInfo ? globalInfo.homeState : '', tab: 1 },
    { header: 'Citizenship', accessorKey: 'citizenship', type: 'dropdown-v2', required: false, value: globalInfo ? globalInfo.citizenship : '', options: countries, tab: 1 },
    { header: 'Job Title', accessorKey: 'jobTitle', type: 'text', required: false, value: globalInfo ? globalInfo.jobTitle : '', tab: 1 },
    { header: 'Job Description', accessorKey: 'jobDescription', type: 'text', required: false, value: globalInfo ? globalInfo.jobDescription : '', tab: 1 },
    { header: 'Charge To Location', accessorKey: 'chargeToLocation', type: 'text', required: false, value: globalInfo ? globalInfo.chargeToLocation : '', tab: 1 },
    { header: 'Pay In Currency', accessorKey: 'payInCurrency', type: 'text', required: false, value: globalInfo ? globalInfo.payInCurrency : '', tab: 1 },
    { header: 'Currency Policy', accessorKey: 'currencyPolicy', type: 'text', required: false, value: globalInfo ? globalInfo.currencyPolicy : '', tab: 1 },
    { header: 'Eligible For Tax Service', accessorKey: 'eligibleForTaxService', type: 'dropdown-v2', required: false, value: globalInfo ? globalInfo.eligibleForTaxService : '', options: basicOptions, tab: 1 },
    { header: 'Notes', accessorKey: 'notes', type: 'notes', required: false, value: globalInfo ? globalInfo.notes : '', tab: 1 },

    { header: 'Estimated Start', accessorKey: 'estimatedStart', type: 'date', required: false, value: globalInfo ? globalInfo.estimatedStart : '', tab: 2 },
    { header: 'Estimated End', accessorKey: 'estimatedEnd', type: 'date', required: false, value: globalInfo ? globalInfo.estimatedEnd : '', tab: 2 },
    { header: 'Estimated Work Days', accessorKey: 'estimatedWorkDays', type: 'number', required: false, value: globalInfo ? globalInfo.estimatedWorkDays : '', tab: 2 },
    { header: 'Actual Start', accessorKey: 'actualStart', type: 'date', required: false, value: globalInfo ? globalInfo.actualStart : '', tab: 2 },
    { header: 'Actual End', accessorKey: 'actualEnd', type: 'date', required: false, value: globalInfo ? globalInfo.actualEnd : '', tab: 2 },
    { header: 'Actual Work Days', accessorKey: 'actualWorkDays', type: 'number', required: false, value: globalInfo ? globalInfo.actualWorkDays : '', tab: 2 },
    { header: 'Hire Date', accessorKey: 'hireDate', type: 'date', required: false, value: globalInfo ? globalInfo.hireDate : '', tab: 2 },
    { header: 'Completed Date', accessorKey: 'completedDate', type: 'date', required: false, value: globalInfo ? globalInfo.completedDate : '', tab: 2 },
    { header: 'Cancelled Date', accessorKey: 'cancelledDate', type: 'date', required: false, value: globalInfo ? globalInfo.cancelledDate : '', tab: 2 },
    { header: 'Cert of Coverage Exp', accessorKey: 'certificateOfCovExpires', type: 'date', required: false, value: globalInfo ? globalInfo.certificateOfCovExpires : '', tab: 2 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);
    if (globalInfo && globalInfo.length !== 0) {
      let newValues = { ...globalInfo };

      if (tab === 1) {
        newValues.description = values.description;
        newValues.type = values.type;
        newValues.country = values.country;
        newValues.lengthOfAssignment = values.lengthOfAssignment;
        newValues.clientNumber = values.clientNumber;
        newValues.homeCountry = values.homeCountry;
        newValues.homeState = values.homeState;
        newValues.citizenship = values.citizenship;
        newValues.jobTitle = values.jobTitle;
        newValues.jobDescription = values.jobDescription;
        newValues.chargeToLocation = values.chargeToLocation;
        newValues.payInCurrency = values.payInCurrency;
        newValues.currencyPolicy = values.currencyPolicy;
        newValues.eligibleForTaxService = values.eligibleForTaxService;
        newValues.notes = values.notes;
      } else {
        newValues.estimatedStart = values.estimatedStart;
        newValues.estimatedEnd = values.estimatedEnd;
        newValues.estimatedWorkDays = values.estimatedWorkDays;
        newValues.actualStart = values.actualStart;
        newValues.actualEnd = values.actualEnd;
        newValues.actualWorkDays = values.actualWorkDays;
        newValues.hireDate = values.hireDate;
        newValues.completedDate = values.completedDate;
        newValues.cancelledDate = values.cancelledDate;
        newValues.certificateOfCovExpires = values.certificateOfCovExpires;
      }

      const resp = await props.updateGlobalInfo(newValues);
      if (resp.type === UPDATE_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    } else {
      values.authorizationId = id;

      const resp = await props.postGlobalInfo(values);
      if (resp.type === POST_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to update global service info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        setIsLoading(false);
        return false;
      } else { 
        setIsLoading(false);
        return true; 
      }
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={5} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateGlobalInfo,
    postGlobalInfo,
    showToast,
  }),
)(OverviewGlobalCard);