/* eslint-disable no-restricted-globals */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-operators */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { Cancel, Delete, Edit, Save } from '@mui/icons-material';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { MaterialReactTable } from 'material-react-table';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { formatDateForInput } from 'utilities/common';
import { showToast } from 'modules/layout/layout.actions';
import { withStyles } from '@mui/styles';
import AdvancedSearch from 'modules/common/advancedSearch.component';
import React, { useCallback, useEffect, useState } from 'react';
import Spinner from 'modules/common/spinner.component';

const styles = (theme) => ({
  editModalContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100% !important',
    marginLeft: '0px !important',
  },
  editModalColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100% !important',
  },
  editModalInput: {
    minWidth: '332px',
  },
  dialogContentContainer: {
    margin: '15px',
  },
});

/*
    - Required Props: 
     * editingMode (must be 'row' or 'modal')
     * columns
     * rows
    - Optional Props: 
     * updateRow (typically an API PUT call followed by GET call to refresh the data, returns boolean) 
     * createRow (typically an API POST call followed by GET call to refresh the data, returns boolean) 
     * deleteRow (typically an API DELETE call followed by GET call to refresh the data, returns boolean) 
     * customValidator (function that must return boolean)
     * initialState (the initial state of the table - used to hide columns for example)
     * hideEdit (removes all editing/adding functionality to rows)

    - Required Column Fields: accessorKey, header, type, options (only required for dropdown type)
    - Optional Column Fields: required (defaults to false), size, enableColumnOrdering, enableEditing, enableSorting, hideInTable (if column should be displayed in the CRUD table or not)

    - Column Types: text, number, date, dropdown
*/

const CrudTable = ({
  editingMode,
  classes,
  columns,
  rows,
  updateRow,
  createRow,
  deleteRow,
  customValidator,
  showToast,
  customAddText,
  isLoading = false,
  getNameOfDeleteRow = null,
  hideEdit,
}) => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState(() => rows);
  const [validationErrors, setValidationErrors] = useState({});
  const [editingId, setEditingId] = useState(null);
  const [editingRow, setEditingRow] = useState(null);
  const [editingRowModalOpen, setEditingModalOpen] = useState(false);

  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const requiredIsEmpty = cell.column.columnDef.required && event.target.value.length === 0;
          const isValid = customValidator ? customValidator(cell, event.target.value) : true;
          if (!isValid || requiredIsEmpty) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is missing or incorrectly formatted.`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  // creation of the editing modal text fields
  columns.forEach((col) => {
    if (col.type === 'text') {
      if (!col.size) col.size = 50;
      col.muiTableBodyCellEditTextFieldProps = ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
      });
    } else if (col.type === 'email') {
      col.muiTableBodyCellEditTextFieldProps = ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
        type: 'email',
      });
    } else if (col.type === 'number') {
      col.muiTableBodyCellEditTextFieldProps = ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
        type: 'number',
      });
    } else if (col.type === 'date') {
      col.muiTableBodyCellEditTextFieldProps = ({ cell }) => ({
        ...getCommonEditTextFieldProps(cell),
        type: 'date',
      });
    } else if (col.type === 'dropdown') {
      col.muiTableBodyCellEditTextFieldProps = {
        select: true,
        children: col.options.map((opt) => (
          <MenuItem key={opt} value={opt}>
            {opt}
          </MenuItem>
        )),
      };
    } else if (col.type === 'dropdown-v2') {
      col.editSelectOptions = col.options;
      col.muiTableBodyCellEditTextFieldProps = {
        select: true,
        children: col.options.map((opt) => (
          <MenuItem key={opt.id} value={opt.id}>
            {opt.title}
          </MenuItem>
        )),
      };
    }
  });

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const handleCreateNewRow = async (values) => {
    // Send POST call to parent to update and refresh values.
    if (createRow) {
      const resp = await createRow(values);
      if (!resp) {
        showToast('Failed to update table data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
    }
  };

  const handleSaveRowEdits = async (exitEditingMode, row, values) => {
    if (!Object.keys(validationErrors).length) {
      tableData[row.index] = values;

      // Send update call to parent to update and refresh values.
      if (updateRow) {
        const resp = await updateRow(row, values);
        if (!resp) {
          showToast('Failed to update table data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (resp?.hasContent === true) {
          tableData[row.index] = resp.content;
        }
      }

      setTableData([...tableData]);
      exitEditingMode(); //required to exit editing mode and close modal
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const hideColumns = (columns) => {
    let hiddenCols = {};
    columns.map((col) => {
      if (col.hideInTable) {
        hiddenCols[col.accessorKey] = false;
      }
    });
    return hiddenCols;
  };

  const handleDeleteRow = useCallback(
    async (row) => {
      let nameOfDeleteRow = getNameOfDeleteRow === null ? row.getValue(columns[0].accessorKey) : getNameOfDeleteRow(row);
      if (!confirm(`Are you sure you want to delete ${nameOfDeleteRow}?`)) {
        return;
      }
      // Send update call to parent to update and refresh values.
      if (deleteRow) {
        const resp = await deleteRow(row);
        if (!resp) {
          showToast('Failed to update table data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
      }
      tableData.splice(row.index, 1);
      setTableData([...tableData]);
    },
    [tableData],
  );

  useEffect(() => {
    setTableData(rows);
  }, [rows]);

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          margin: 1,
          overflow: 'hidden',
        }}
      >
        <MaterialReactTable
          displayColumnDefOptions={{
            'mrt-row-actions': {
              muiTableHeadCellProps: {
                align: 'center',
              },
              size: 35,
            },
          }}
          columns={columns}
          data={tableData}
          editingMode={editingMode} //default
          enableColumnOrdering
          enableColumnResizing
          enableEditing
          onEditingRowSave={handleSaveRowEdits}
          onEditingRowCancel={handleCancelRowEdits}
          initialState={{ columnVisibility: hideColumns(columns) /* columnFilters: [{ id: 'status', value: 'A' }] */ }} //commented out per Derek because it's causing errors and should be fed into the crud table instead of staticly assigned here
          renderRowActions={({ row, table }) => (
            <>
              {(editingMode === 'modal' || (editingMode === 'row' && row.id !== editingId)) && (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <Tooltip arrow placement="left" title="Edit">
                    <IconButton
                      onClick={() => {
                        setEditingModalOpen(true);
                        setEditingId(row.id);
                        setEditingRow(row);
                      }}
                    >
                      <Edit />
                    </IconButton>
                  </Tooltip>
                  { !hideEdit &&
                  <>
                    <Tooltip arrow placement="right" title="Delete">
                      <IconButton color="error" disabled={row.original.deletionDisabled} onClick={() => handleDeleteRow(row)}>
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  </>
                  }

                </Box>
              )}
              {editingMode === 'row' && row.id === editingId && (
                <Box sx={{ display: 'flex', gap: '1rem' }}>
                  <Tooltip arrow placement="right" title="Cancel">
                    <IconButton
                      color="error"
                      onClick={() => {
                        setEditingId(null);
                        table.setEditingRow(null);
                      }}
                    >
                      <Cancel />
                    </IconButton>
                  </Tooltip>
                  <Tooltip arrow placement="left" title="Save">
                    <IconButton
                      onClick={() => {
                        handleSaveRowEdits({
                          exitEditingMode: () => {
                            setEditingId(null);
                            table.setEditingRow(null);
                          },
                          row,
                          values: table.getState().editingRow._valuesCache,
                        });
                      }}
                    >
                      <Save />
                    </IconButton>
                  </Tooltip>
                </Box>
              )}
            </>
          )}
          renderTopToolbarCustomActions={() => ( 
            <>
              {!hideEdit && (
                <Button color="primary" onClick={() => setCreateModalOpen(true)} variant="contained">
                  {customAddText ? customAddText : 'Add record'}
                </Button>
              )}
            </>
          )}
        />
      </Box>
      <CreateNewAccountModal
        columns={columns}
        classes={classes}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
        isLoading={isLoading}
      />
      {editingRowModalOpen && (
        <EditRecordModal
          columns={columns}
          classes={classes}
          open={editingRowModalOpen}
          onClose={() => setEditingModalOpen(false)}
          row={editingRow}
          onSubmit={handleSaveRowEdits}
          isLoading={isLoading}
          hideEdit={hideEdit}
        />
      )}
    </ThemeProvider>
  );
};

/*
    - 9/8/2023 - How the editing modal works
    - Field Types: 
     * text
     * notes (meant to expand the full width of the editing modal)
     * number
     * checkbox
     * dropdown
    - Column Division:
     * The editing modal is divided into 3 columns, inserting row-by-row in the order the columns are sent in
     * If a field is of type "notes", it will be inserted into a notes section
     * If a field is a: added on, added by, modified on, modified by, it will be inserted into its own section at the bottom of the modal
     * 

*/

//example of creating a mui dialog modal for creating new rows
export const EditRecordModal = ({ open, classes, columns, onClose, row, onSubmit, hideEdit, isLoading }) => {
  const [values, setValues] = useState(() =>
    columns.reduce((acc, column) => {
      acc[column.accessorKey ?? ''] = row.getValue(column.accessorKey);
      return acc;
    }, {}),
  );

  const renderInput = (column) => {
    if (column?.hideInModel === true) {
      return null;
    }
    if (column.type === 'text') {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={column.required}
          value={values[column.accessorKey]}
          disabled={column.disabled}
          margin="dense"
          variant="standard"
          sx={{ width: '360px' }}
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
        />
      );
    }
    if (column.type === 'notes') {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={column.required}
          value={values[column.accessorKey]}
          disabled={column.disabled}
          margin="dense"
          multiline
          variant="standard"
          rows={4}
          sx={{ width: '1100px' }}
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
        />
      );
    }
    if (column.type === 'number') {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={column.required}
          value={values[column.accessorKey]}
          margin="dense"
          disabled={column.disabled}
          sx={{ width: '360px' }}
          variant="standard"
          type="number"
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
        />
      );
    }
    if (column.type === 'checkbox') {
      return (
        <FormControl sx={{ marginTop: '20px', marginBottom: '5px', marginRight: '200px' }}>
          <FormControlLabel
            key={column.accessorKey}
            name={column.accessorKey}
            label={column.header}
            labelPlacement="start"
            control={<Checkbox />}
            required={column.required}
            disabled={column.disabled}
            checked={values[column.accessorKey]}
            variant="standard"
            margin="dense"
            sx={{ marginTop: '6px' }}
            onClick={(e) => setValues({ ...values, [e.target.name]: e.target.checked })}
          />
        </FormControl>
      );
    }
    if (column.type === 'dropdown') {
      return (
        <FormControl sx={{ marginTop: '6px' }}>
          <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={column.header}>
            {column.header}
          </InputLabel>
          <Select
            labelId={column.header}
            key={column.accessorKey}
            label={column.header}
            name={column.accessorKey}
            required={column.required}
            disabled={column.disabled}
            value={values[column.accessorKey]}
            margin="dense"
            variant="standard"
            sx={{ marginTop: '6px', marginBottom: '6px', width: '360px' }}
            onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
          >
            {column.options.map((opt) => (
              <MenuItem key={opt} value={opt} sx={{ margin: 'dense' }}>
                {opt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    if (column.type === 'dropdown-v2') {
      return (
        <FormControl sx={{ marginTop: '6px' }}>
          <InputLabel sx={{ marginLeft: '-15px', marginTop: '7px' }} id={column.header}>
            {column.header}
          </InputLabel>
          <Select
            labelId={column.header}
            key={column.accessorKey}
            label={column.header}
            name={column.accessorKey}
            required={column.required}
            disabled={column.disabled}
            value={values[column.accessorKey]}
            margin="dense"
            variant="standard"
            sx={{ marginTop: '6px', marginBottom: '6px', width: '360px' }}
            onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
          >
            {column.options.map((opt) => (
              <MenuItem key={opt.id} value={opt.id} sx={{ margin: 'dense' }}>
                {opt.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    if (column.type === 'date') {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={column.required}
          disabled={column.disabled}
          value={formatDateForInput(values[column.accessorKey])}
          sx={{ width: '360px' }}
          margin="dense"
          variant="standard"
          type="date"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
        />
      );
    }
    if (column.type === 'money') {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={column.required}
          value={values[column.accessorKey]}
          margin="dense"
          disabled={column.disabled}
          sx={{ maxWidth: '360px' }}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          variant="standard"
          allowDecimal={true}
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
        />
      );
    }
    if (column.type === 'advanced-search') {
      const searchValues = column.searchInfo.searchingRows;
      let nameKey = '';
      if (searchValues) {
        const selectedValue = searchValues.find((s) => s[column.accessorKey] === values[column.accessorKey]);
        if (selectedValue) {
          nameKey = selectedValue[column.nameKey];
        }
      }
      return (
        <FormControl sx={{ marginTop: '6px' }}>
          <AdvancedSearch
            labelText={column.header}
            displayValueAccessorKey={column.searchInfo.nameKey}
            saveIdValueAccessorKey={column.searchInfo.idKey}
            searchingCriteria={column.searchInfo.searchingColumns}
            searchingCriteriaExtensions={column.searchInfo.searchingColumnExtensions}
            searchingRows={column.searchInfo.searchingRows}
            id={values[column.accessorKey]}
            value={nameKey}
            setIdValue={(id, value) => {
              setValues({ ...values, [column.nameKey]: value, [column.accessorKey]: id });
            }}
            maxWidthsx={'360px'}
          />
        </FormControl>
      );
    }
  };

  let groupOne = [];
  let groupTwo = [];
  let groupThree = [];
  let notesGroup = [];
  let addedModifiedGroup = [];
  let indexTracker = 1;
  columns.map((column) => {
    if (column.type === 'notes') {
      notesGroup.push(column);
    } else if (column.accessorKey.includes('added') || column.accessorKey.includes('modified')) {
      addedModifiedGroup.push(column);
    } else if (indexTracker / 1 === 1) {
      groupOne.push(column);
      indexTracker++;
    } else if (indexTracker / 2 === 1) {
      groupTwo.push(column);
      indexTracker++;
    } else {
      groupThree.push(column);
      indexTracker = 1;
    }
  });

  const handleSubmit = (e) => {
    //put your validation logic here
    // need to add 'modified on / by' logic here
    e.preventDefault();
    onSubmit(onClose, row, values);
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={handleSubmit} fullScreen>
        <DialogTitle textAlign="center">Edit</DialogTitle>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <div className={classes.editModalContainer}>
              <div className={classes.editModalColumn}>
                {groupOne.map((column) => {
                  return renderInput(column);
                })}
              </div>
              <div className={classes.editModalColumn}>
                {groupTwo.map((column) => {
                  return renderInput(column);
                })}
              </div>
              <div className={classes.editModalColumn}>
                {groupThree.map((column) => {
                  return renderInput(column);
                })}
              </div>
            </div>
            <div className={classes.editModalContainer}>
              <div className={classes.editModalColumn}>
                {notesGroup.map((column) => {
                  return renderInput(column);
                })}
              </div>
            </div>
            <div className={classes.editModalContainer}>
              <div className={classes.editModalColumn}>
                {addedModifiedGroup.map((column) => {
                  return renderInput(column);
                })}
              </div>
            </div>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && !hideEdit && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="secondary" type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        )}
        {!isLoading && hideEdit && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Close</Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

//example of creating a mui dialog modal for creating new rows
export const CreateNewAccountModal = ({ classes, open, columns, onClose, onSubmit, isLoading }) => {
  const [values, setValues] = useState({});

  useEffect(() => {
    if (open) {
      setValues(
        columns.reduce((acc, column) => {
          acc[column.accessorKey ?? ''] = column.defaultVal != null ? column.defaultVal : '';
          return acc;
        }, {}),
      );
    }
  }, [open, columns]);

  const handleDropdownChange = (e, accessorKey) => {
    setValues((prevValues) => ({
      ...prevValues,
      [accessorKey]: e.target.value,
    }));
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  const renderInput = (column, index) => {
    if (column?.hideInModel === true) {
      return null;
    }
    if (column.type === 'text') {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={column.required}
          disabled={column.disabled}
          sx={{ width: '360px' }}
          margin="dense"
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
        />
      );
    }
    if (column.type === 'notes') {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={column.required}
          disabled={column.disabled}
          margin="dense"
          multiline
          rows={4}
          sx={{ width: '1100px' }}
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
        />
      );
    }

    if (column.type === 'number') {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={column.required}
          margin="dense"
          disabled={column.disabled}
          sx={{ width: '360px' }}
          type="number"
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
        />
      );
    }
    if (column.type === 'dropdown') {
      return (
        <FormControl key={index}>
          <InputLabel sx={{ marginTop: '7px' }} id={column.header}>
            {column.header}
          </InputLabel>
          <Select
            labelId={column.header}
            key={column.accessorKey}
            disabled={column.disabled}
            label={column.header}
            name={column.accessorKey}
            required={column.required}
            margin="dense"
            sx={{ marginTop: '6px', marginBottom: '6px', width: '360px' }}
            onChange={(e) => handleDropdownChange(e, column.accessorKey)}
          >
            {column.options.map((opt) => (
              <MenuItem key={opt} value={opt}>
                {opt}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    if (column.type === 'dropdown-v2') {
      return (
        <FormControl key={index}>
          <InputLabel sx={{ marginTop: '7px' }} id={column.header}>
            {column.header}
          </InputLabel>
          <Select
            labelId={column.header}
            key={column.accessorKey}
            disabled={column.disabled}
            label={column.header}
            name={column.accessorKey}
            required={column.required}
            margin="dense"
            sx={{ marginTop: '6px', marginBottom: '6px', width: '360px' }}
            value={values[column.accessorKey]}
            onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
          >
            {column.options.map((opt) => (
              <MenuItem key={opt.id} value={opt.id}>
                {opt.title}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      );
    }
    if (column.type === 'date') {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          disabled={column.disabled}
          name={column.accessorKey}
          required={column.required}
          sx={{ width: '360px' }}
          margin="dense"
          type="date"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
        />
      );
    }
    if (column.type === 'checkbox') {
      return (
        <FormControl sx={{ marginTop: '20px', marginBottom: '5px', marginRight: '200px' }} key={index}>
          <FormControlLabel
            key={column.accessorKey}
            name={column.accessorKey}
            label={column.header}
            labelPlacement="start"
            control={<Checkbox />}
            required={column.required}
            disabled={column.disabled}
            checked={values[column.accessorKey] != null ? values[column.accessorKey] : false}
            variant="standard"
            margin="dense"
            sx={{ marginTop: '6px' }}
            onClick={(e) => setValues({ ...values, [e.target.name]: e.target.checked })}
          />
        </FormControl>
      );
    }
    if (column.type === 'money') {
      return (
        <TextField
          key={column.accessorKey}
          label={column.header}
          name={column.accessorKey}
          required={column.required}
          value={values[column.accessorKey]}
          margin="dense"
          disabled={column.disabled}
          sx={{ maxWidth: '360px' }}
          type="number"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          allowDecimal={true}
          onChange={(e) => setValues({ ...values, [e.target.name]: e.target.value })}
        />
      );
    }
    if (column.type === 'advanced-search') {
      return (
        <FormControl sx={{ marginTop: '6px' }} key={index}>
          <AdvancedSearch
            key={column.accessorKey}
            labelText={column.header}
            displayValueAccessorKey={column.searchInfo.nameKey}
            saveIdValueAccessorKey={column.searchInfo.idKey}
            searchingCriteria={column.searchInfo.searchingColumns}
            searchingCriteriaExtensions={column.searchInfo.searchingColumnExtensions}
            searchingRows={column.searchInfo.searchingRows}
            id={values[column.accessorKey]}
            value={values[column.nameKey]}
            setIdValue={(id, value) => {
              setValues({ ...values, [column.nameKey]: value, [column.accessorKey]: id });
            }}
            maxWidthsx={'360px'}
          />
        </FormControl>
      );
    }
  };

  const handleSubmit = (e) => {
    //put your validation logic here
    // need to add 'added on / by' logic here
    e.preventDefault();
    setIsSubmitting(true);
    onSubmit(values);
    setIsSubmitting(false);
    onClose();
  };

  let groupOne = [];
  let groupTwo = [];
  let groupThree = [];
  let notesGroup = [];
  let addedModifiedGroup = [];
  let indexTracker = 1;
  columns.map((column) => {
    if (column.type === 'notes') {
      notesGroup.push(column);
    } else if (column.accessorKey.includes('added') || column.accessorKey.includes('modified')) {
      addedModifiedGroup.push(column);
    } else if (indexTracker / 1 === 1) {
      groupOne.push(column);
      indexTracker++;
    } else if (indexTracker / 2 === 1) {
      groupTwo.push(column);
      indexTracker++;
    } else {
      groupThree.push(column);
      indexTracker = 1;
    }
  });

  return (
    <Dialog open={open} fullWidth={true} maxWidth={'lg'}>
      <form onSubmit={handleSubmit}>
        <DialogTitle textAlign="center">Add Record</DialogTitle>
        <div className={classes.dialogContentContainer}>
          <Stack
            sx={{
              width: '100%',
              minWidth: { xs: '300px', sm: '360px', md: '400px', lg: '400px' },
              gap: '1.5rem',
            }}
          >
            <div className={classes.editModalContainer}>
              <div className={classes.editModalColumn}>
                {groupOne.map((column, index) => {
                  return renderInput(column, index);
                })}
              </div>
              <div className={classes.editModalColumn}>
                {groupTwo.map((column, index) => {
                  return renderInput(column, index);
                })}
              </div>
              <div className={classes.editModalColumn}>
                {groupThree.map((column, index) => {
                  return renderInput(column, index);
                })}
              </div>
            </div>
            <div className={classes.editModalContainer}>
              <div className={classes.editModalColumn}>
                {notesGroup.map((column, index) => {
                  return renderInput(column, index);
                })}
              </div>
            </div>
            <div className={classes.editModalContainer}>
              <div className={classes.editModalColumn}>
                {addedModifiedGroup.map((column, index) => {
                  return renderInput(column, index);
                })}
              </div>
            </div>
          </Stack>
        </div>
        {isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Spinner />
          </DialogActions>
        )}
        {!isLoading && (
          <DialogActions sx={{ p: '1.25rem' }}>
            <Button onClick={onClose}>Cancel</Button>
            <Button color="secondary" type="submit" variant="contained" disabled={isSubmitting}>
              Add Record
            </Button>
          </DialogActions>
        )}
      </form>
    </Dialog>
  );
};

export default compose(
  withStyles(styles),
  connect(null, {
    showToast,
  }),
)(CrudTable);
