/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import ExpenseDetails from './expenseDetails.component';

import { setPageTitle, showToast } from 'modules/layout/layout.actions';

const styles = (theme) => ({
  wideControl: {
    minWidth: 174,
  },
  section: {
    marginBottom: theme.spacing(2),
  },
  selfServicePolicySelect: {
    minWidth: 300,
  },
});

const ExpenseSummaryDetailsView = (props) => {

  useEffect(() => {
    props.setPageTitle('Expense Details');

    async function fetchData() {

    }

    fetchData();
  }, []);

  return (
    <Box>
      {!props.isLoading && (
        <ExpenseDetails
          classes={props.classes}
          id={props.match.params.id}
        />
      )}
      {(props.isLoading) && <FullscreenSpinner />}
    </Box>
  );
};

ExpenseSummaryDetailsView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { isLoading } = state.authorizations;
  return {
    isLoading,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    setPageTitle,
    showToast,
  }),
)(ExpenseSummaryDetailsView);