import { Checkbox } from '@mui/material';
import { TOAST_MESSAGE_SEVERITY_ERROR } from 'modules/layout/types';
import { UPDATE_APPRAISALS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { showToast } from 'modules/layout/layout.actions';
import { updateAppraisal } from 'modules/authorizations/store/actions/homesale.actions';
import EditableCard from 'modules/common/editableCard.component';
import React, { useState } from 'react';

const OrderDetailsCard = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  const { appraisalInfo, isReadOnly } = props;

  const tabs = [
    { header: 'Inspections Required', order: 1, iconPath: ['fas', 'magnifying-glass'] },
  ];

  const summaries = [
    { header: 'Cert of Occupancy', accessorKey: 'certOfOccupancy', type: 'checkbox', required: false, value: appraisalInfo ? appraisalInfo.certOfOccupancy : false, tab: 1, Cell: ({ cell }) => <Checkbox name="certOfOccupancy" label="Active" checked={cell.row.original.certOfOccupancy} disabled={true} /> },
    { header: 'Smoke Detector', accessorKey: 'smokeDetector', type: 'checkbox', required: false, value: appraisalInfo ? appraisalInfo.smokeDetector : false, tab: 1, Cell: ({ cell }) => <Checkbox name="smokeDetector" label="Active" checked={cell.row.original.smokeDetector} disabled={true} /> },
    { header: 'Septic', accessorKey: 'septic', type: 'checkbox', required: false,  value: appraisalInfo ? appraisalInfo.septic : false, tab: 1, Cell: ({ cell }) => <Checkbox name="septic" label="Active" checked={cell.row.original.septic} disabled={true} /> },
    { header: 'Radon', accessorKey: 'radon', type: 'checkbox', required: false, value: appraisalInfo ? appraisalInfo.radon : false, tab: 1, Cell: ({ cell }) => <Checkbox name="radon" label="Active" checked={cell.row.original.radon} disabled={true} /> },
    { header: 'Well', accessorKey: 'well', type: 'checkbox', required: false, value: appraisalInfo ? appraisalInfo.well : false, tab: 1, Cell: ({ cell }) => <Checkbox name="well" label="Active" checked={cell.row.original.well} disabled={true} /> },
    { header: 'Others', accessorKey: 'others', type: 'checkbox', required: false, value: appraisalInfo ? appraisalInfo.others : false, tab: 1, Cell: ({ cell }) => <Checkbox name="others" label="Active" checked={cell.row.original.others} disabled={true} /> },
    { header: 'Other Inspection Details', accessorKey: 'otherInspections', type: 'notes', required: false, showBelow: true, value: appraisalInfo ? appraisalInfo.otherInspections : '', tab: 1 },
  ];

  const updateFields = async (tab, values) => {
    setIsLoading(true);

    let newValues = { ...appraisalInfo };

    newValues.certOfOccupancy = values.certOfOccupancy;
    newValues.smokeDetector = values.smokeDetector;
    newValues.septic = values.septic;
    newValues.radon = values.radon;
    newValues.well = values.well;
    newValues.others = values.others;
    newValues.otherInspections = values.otherInspections;

    const resp = await props.updateAppraisal(newValues);
    if (resp.type === UPDATE_APPRAISALS_FAILURE) {
      props.showToast('Failed to update appraisal info, please try again.', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      setIsLoading(false);
      return false;
    } else { 
      setIsLoading(false);
      return true; 
    }
  };
    
  return (
    <EditableCard
      tabs={tabs}
      summaries={summaries}
      numColumns={3} 
      updateFields={updateFields}
      isLoading={isLoading}
      hideEdit={isReadOnly}
    />
  );

};

export default compose(
  connect(null, {
    updateAppraisal,
    showToast,
  }),
)(OrderDetailsCard);