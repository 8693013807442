/* eslint-disable react-hooks/exhaustive-deps */

import  { withStyles } from '@material-ui/core';

import {
  Box, Grid,
} from '@mui/material';

import { compose } from 'recompose';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import Estimates from './estimates.component';
import RequestDetails from './requestDetails.component';
import Spinner from 'modules/common/spinner.component';
import VendorInformation from './vendorInformation.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  iconRow:{
    display: 'flex',
    flexDirection: 'row',
  },
  dialogIconRow:{
    display: 'flex',
    flexDirection: 'row',
    padding: '40px',
    paddingBottom: '20px',
  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const EstimatesActuals = (props) => {    
  const { details, estimateId, location, isLoadingEstimates, segmentId } = props;

  const segmentInfo = details.hhgSegments ? details.hhgSegments.find((s) => s.segmentId === segmentId) : null;

  const estimateInfo = segmentInfo && estimateId != null ? details.hhgEstimates.find((e) => e.estimateId === estimateId) : null;

  let itemsTotal = 0;
  if (details.hhgItems) details.hhgItems.map((item) => itemsTotal = itemsTotal + Number(item.value));

  return (
    <Box>
      {estimateInfo == null && !isLoadingEstimates && (
        <Box sx={{ padding: '20px', fontSize: '20px' }}>
          <b>No estimate selected. Create a new estimate above to get started.</b>
        </Box>
      )}
      {estimateInfo != null && !isLoadingEstimates && (
        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
          <Grid item xs={12} xl={8} rowSpacing={2}>
            <VendorInformation estimateInfo={estimateInfo} hideEdit={location.pathname.includes('expenses')} />
            <Box sx={{ paddingTop: '16px' }}>
              <RequestDetails estimateInfo={estimateInfo} hideEdit={location.pathname.includes('expenses')} />
            </Box>
          </Grid>
          <Grid item xs={12} xl={4} rowSpacing={2}>
            <Estimates estimateInfo={estimateInfo} itemsTotal={itemsTotal} hideEdit={location.pathname.includes('expenses')} />
          </Grid>
        </Grid>
      )}
      {isLoadingEstimates && <Spinner />}
    </Box>
  );

};

EstimatesActuals.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
  }),
)(EstimatesActuals);