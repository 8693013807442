/* eslint-disable no-case-declarations */
import {
  GET_AUTH_DESTINATION_SERVICES_SUCCESS,
  GET_AUTH_HOUSE_HUNTING_LIST_SUCCESS,
  GET_AUTH_OFFER_HISTORY_LIST_SUCCESS,
  POST_AUTH_DESTINATION_SERVICES_SUCCESS,
  POST_AUTH_HOUSE_HUNTING_LIST_SUCCESS,
  POST_AUTH_OFFER_HISTORY_LIST_SUCCESS,
  UPDATE_AUTH_DESTINATION_SERVICES_SUCCESS,
  UPDATE_AUTH_HOUSE_HUNTING_LIST_SUCCESS,
  UPDATE_AUTH_OFFER_HISTORY_LIST_SUCCESS,
} from '../types/destinationServiceTypes';

const handleDestinationService = (draft, action) => {
  if (!draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};

  switch (action.type) {
    case UPDATE_AUTH_DESTINATION_SERVICES_SUCCESS:
    case POST_AUTH_DESTINATION_SERVICES_SUCCESS:
    case GET_AUTH_DESTINATION_SERVICES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].destinationServices = action.response;
      break;

    case POST_AUTH_HOUSE_HUNTING_LIST_SUCCESS:
    case UPDATE_AUTH_HOUSE_HUNTING_LIST_SUCCESS:
    case GET_AUTH_HOUSE_HUNTING_LIST_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].houseHunting = action.response;
      break;

    case POST_AUTH_OFFER_HISTORY_LIST_SUCCESS:
    case UPDATE_AUTH_OFFER_HISTORY_LIST_SUCCESS:
    case GET_AUTH_OFFER_HISTORY_LIST_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].offerHistory = action.response;
      break;

    default:
      break;
  }

  return draft;
};

export default handleDestinationService;
