/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core';
import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../../layout/types';
import { showToast } from 'modules/layout/layout.actions';

import {
  getAuthClientPolicies, getAuthDateSummary, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress,
  getDestinationAddress, getEmployeeInfo, getSOInfo, getServices, getTeam, getUserSettings, setAuthId,
  setLoadingComplete,
} from '../../store/actions/authorizations.actions';

import {
  getBrokerAgents, getExpenseCodes, getInspectors, getVendorContacts, getVendors,
} from 'modules/authorizations/store/actions/dataManagement.actions';

import {
  getAssignmentExtensions, getAssignmentTrips, getGlobalInfo,
} from 'modules/authorizations/store/actions/global.actions';

import {
  getAllAppraisals, getAllListings, getEquity, getHomesaleInfo, getHomesaleResaleOffers, getInspections,
} from 'modules/authorizations/store/actions/homesale.actions';
import { getTitleReps } from 'modules/authorizations/store/actions/dataManagement.actions';

import {
  GET_AUTH_CLIENT_POLICIES_FAILURE, GET_AUTH_DATE_SUMMARY_FAILURE, GET_AUTH_SERVICES_FAILURE, GET_AUTH_SUMMARY_FAILURE,
  GET_AUTH_TEAM_FAILURE, GET_COMMUNICATION_INFO_FAILURE, GET_DEPARTURE_ADDRESS_FAILURE, GET_DESTINATION_ADDRESS_FAILURE,
  GET_EMPLOYEE_INFO_FAILURE, GET_SO_INFO_FAILURE, GET_USER_SETTINGS_FAILURE,
} from '../../store/types/authorizationTypes';

import {
  GET_ALL_BROKER_AGENTS_FAILURE, GET_EXPENSE_CODES_FAILURE, GET_INSPECTORS_FAILURE, GET_TITLE_REP_FAILURE, GET_VENDORS_FAILURE, 
  GET_VENDOR_CONTACT_FAILURE,
} from 'modules/authorizations/store/types/dataManagementTypes';

import {
  GET_ASSIGNMENT_EXTENSIONS_FAILURE, GET_ASSIGNMENT_TRIPS_FAILURE, GET_GLOBAL_INFO_FAILURE,
} from 'modules/authorizations/store/types/globalTypes';

import {
  GET_ALL_HOMESALE_RESALE_OFFER_FAILURE, GET_APPRAISALS_FAILURE, GET_EQUITY_FAILURE, GET_HOMESALE_INFO_FAILURE, 
  GET_INSPECTIONS_FAILURE, GET_LISTINGS_FAILURE,
} from 'modules/authorizations/store/types/homesaleTypes';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';

import HomesaleService from './homesaleService.component';

const styles = (theme) => ({
  card: {
    width: '100%',
    maxWidth: 1500,
    flexGrow: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  cardContainer: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',

  },
  cardDisplayContent: {
    maxWidth: '100% !important',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    margin: '10px',
  },
});

const HomesaleView = (props) => {

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
   
  });
  const details = props.authorizationDetails ? props.authorizationDetails[props.match.params.id] : null;
  const clientInfo = details && details.summary && props.unmappedClients ? props.unmappedClients.find((e) => e.description === details.summary.clientName) : null;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function fetchData () {
      const id = props.match.params.id;

      window.scrollTo(0, 0);
      props.setAuthId(id);

      setIsLoading(true);
          
      const [authSummaryResp, employeeInfoResp, soInfoResp, authDateSummaryResp, communicationInfoResp, departureResp, destinationResp, authClientPoliciesResp,
        teamResp, authServiceResp, userSettingsResp, globalDetailsResp, assignmentTripsResp, assignmentExtensionsResp, vendorsResp, vendorContactsResp, homesaleResp, brokerAgentsResp,
        appraisalsResp, inspectorsResp, homesaleInspectionsResp, listingsResp, titleRepsResp, resaleResp, equitiesResp, expenseCodesResp ] = await Promise.all([
        props.getAuthorizationSummary(id),
        props.getEmployeeInfo(id),
        props.getSOInfo(id),
        props.getAuthDateSummary(id),
        props.getCommunicationInfo(id),
        props.getDepartureAddress(id),
        props.getDestinationAddress(id),
        props.getAuthClientPolicies(id),
        props.getTeam(id),
        props.getServices(id),
        props.getUserSettings(id),
        props.getGlobalInfo(id),
        props.getAssignmentTrips(id),
        props.getAssignmentExtensions(id),
        props.getVendors(),
        props.getVendorContacts(),
        props.getHomesaleInfo(id),
        props.getBrokerAgents(),
        props.getAllAppraisals(id),
        props.getInspectors(),
        props.getInspections(id),
        props.getAllListings(id),
        props.getTitleReps(id),
        props.getHomesaleResaleOffers(id),
        props.getEquity(id),
        props.getExpenseCodes(),
      ]);
  
      props.setLoadingComplete();
      setIsLoading(false);

      if (authSummaryResp.type === GET_AUTH_SUMMARY_FAILURE) {
        props.showToast('Failed to retrieve authorization info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
    
      if (employeeInfoResp.type === GET_EMPLOYEE_INFO_FAILURE) {
        props.showToast('Failed to retrieve employee info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
    
      if (soInfoResp.type === GET_SO_INFO_FAILURE) {
        props.showToast('Failed to retrieve significant other info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
            
      if (authDateSummaryResp.type === GET_AUTH_DATE_SUMMARY_FAILURE) {
        props.showToast('Failed to retrieve auth summary info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (communicationInfoResp.type === GET_COMMUNICATION_INFO_FAILURE) {
        props.showToast('Failed to retrieve employee communication info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (departureResp.type === GET_DEPARTURE_ADDRESS_FAILURE) {
        props.showToast('Failed to retrieve departure address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (destinationResp.type === GET_DESTINATION_ADDRESS_FAILURE) {
        props.showToast('Failed to retrieve destination address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
        props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (teamResp.type === GET_AUTH_TEAM_FAILURE) {
        props.showToast('Failed to retrieve auth team, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (authServiceResp.type === GET_AUTH_SERVICES_FAILURE) {
        props.showToast('Failed to retrieve auth services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
        props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (userSettingsResp.type === GET_USER_SETTINGS_FAILURE) {
        props.showToast('Failed to retrieve employee settings info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (globalDetailsResp.type === GET_GLOBAL_INFO_FAILURE) {
        props.showToast('Failed to retrieve global service info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (assignmentTripsResp.type === GET_ASSIGNMENT_TRIPS_FAILURE) {
        props.showToast('Failed to retrieve global trips info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (assignmentExtensionsResp.type === GET_ASSIGNMENT_EXTENSIONS_FAILURE) {
        props.showToast('Failed to retrieve global extensions info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (vendorsResp.type === GET_VENDORS_FAILURE) {
        props.showToast('Failed to retrieve vendor info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (vendorContactsResp.type === GET_VENDOR_CONTACT_FAILURE) {
        props.showToast('Failed to retrieve vendor contact info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (homesaleResp.type === GET_HOMESALE_INFO_FAILURE) {
        props.showToast('Failed to retrieve homesale info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (brokerAgentsResp.type === GET_ALL_BROKER_AGENTS_FAILURE) {
        props.showToast('Failed to retrieve brokers and agents, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (appraisalsResp.type === GET_APPRAISALS_FAILURE) {
        props.showToast('Failed to retrieve homesale appraisals, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (inspectorsResp.type === GET_INSPECTORS_FAILURE) {
        props.showToast('Failed to retrieve inspectors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }
        
      if (homesaleInspectionsResp.type === GET_INSPECTIONS_FAILURE) {
        props.showToast('Failed to retrieve homesale inspections, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (listingsResp.type === GET_LISTINGS_FAILURE) {
        props.showToast('Failed to retrieve listings information, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (titleRepsResp.type === GET_TITLE_REP_FAILURE) {
        props.showToast('Failed to retrieve title reps, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (resaleResp.type === GET_ALL_HOMESALE_RESALE_OFFER_FAILURE) {
        props.showToast('Failed to retrieve resale offers, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }
          
      if (equitiesResp.type === GET_EQUITY_FAILURE) {
        props.showToast('Failed to retrieve equities information, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (expenseCodesResp.type === GET_EXPENSE_CODES_FAILURE) {
        props.showToast('Failed to retrieve expense codes, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

    }

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && clientInfo &&
          <HomesaleService 
            brokerAgents={props.brokerAgents} 
            details={details} 
            clientInfo={clientInfo} 
            id={props.match.params.id} 
            history={props.history} 
            location={props.location}
            vendors={props.vendors}
            inspectors={props.inspectors}
            titleReps={props.titleReps}
            isReadOnly={false}
          />
        }
        {isLoading && <FullscreenSpinner />}

      </Box>
    </ThemeProvider>
  );

};

HomesaleView.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { authorizationDetails, brokerAgents, isLoading, vendors, inspectors, titleReps } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    brokerAgents,
    unmappedClients,
    isLoading,
    inspectors,
    vendors,
    titleReps,
  };
};

export default compose(
  withStyles(styles),
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getEmployeeInfo,
    getSOInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    setAuthId,
    setLoadingComplete,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getGlobalInfo,
    getAssignmentTrips,
    getAssignmentExtensions,
    getVendorContacts,
    getVendors,
    getHomesaleInfo,
    getBrokerAgents,
    getAllAppraisals,
    showToast,
    getInspectors,
    getInspections,
    getAllListings,
    getTitleReps,
    getHomesaleResaleOffers,
    getEquity,
    getExpenseCodes,
  }),
)(HomesaleView);