import {
  Box, Grid,
} from '@mui/material';

import AddressCard from './addressCard.component';
import ClientPoliciesCard from './clientPoliciesCard.component';
import CommunicationCard from './communicationCard.component';
import EmployeeCard from './Employee/employeeCard.component';
import React from 'react';
import ServicesCard from './servicesCard.component';
import StatusStepper from './statusStepper.component';
import SummaryCard from './summaryCard.component';
import TeamCard from './teamCard.component';

const AuthorizationDetails = (props) => {
  const details = props.details;
  const status = details.summary  ? details.summary.status  : 'Init';
  return (
    <Box sx={{ padding: '20px' }}>
      { status && details.dateSummary && details.userSettings && <StatusStepper status={status} dateSummary={details.dateSummary} vip={details.userSettings.vip} webAccess={details.userSettings.webAccess} /> }
      <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 2 }}>
        <Grid item xs={12} xl={8} rowSpacing={2}>
          <EmployeeCard clientInfo={props.clientInfo} employee={details.employee} family={details.family} id={props.id} vendors={props.vendors} familyPets={details.familyPets} />
          <Box sx={{ paddingTop: '16px' }}>
            <ClientPoliciesCard clientInfo={props.clientInfo} clientPolicies={details.authClientPolicies} id={props.id} />
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <CommunicationCard clientInfo={props.clientInfo} communication={details.communication} id={props.id} />
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <AddressCard departureAddress={details.departureAddress} destinationAddress={details.destinationAddress} id={props.id} />
          </Box>
        </Grid>
        <Grid item xs={12} xl={4} rowSpacing={2}>
          <SummaryCard dateSummary={details.dateSummary} id={props.id} hideEdit={false} />
          <Box sx={{ paddingTop: '16px' }}>
            <TeamCard team={details.team} id={props.id} />
          </Box>
          <Box sx={{ paddingTop: '16px' }}>
            <ServicesCard authServices={details.authServices} destinationServices={details.destinationServices} candidateServices={details.candidateServices} internServices={details.internServices} id={props.id} moveType={details.summary.moveType} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AuthorizationDetails;