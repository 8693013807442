/* eslint-disable no-case-declarations */
import {
  GET_HHG_ESTIMATES_SUCCESS,
  GET_HHG_ITEMS_SUCCESS,
  GET_HHG_MOVING_DATE_SUCCESS,
  GET_HHG_SEGMENTS_SUCCESS,
  UPDATE_HHG_ESTIMATE_SUCCESS,
  UPDATE_HHG_SEGMENT_SUCCESS,
} from '../types/houseHoldGoodsTypes';

const handleHouseHoldGoodsReducer = (draft, action) => {
  if (!draft.authorizationDetails[draft.currentAuthId]) draft.authorizationDetails[draft.currentAuthId] = {};

  switch (action.type) {
    case GET_HHG_SEGMENTS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].hhgSegments = action.response;
      break;

    case UPDATE_HHG_SEGMENT_SUCCESS:
      let tempSegments = draft.authorizationDetails[draft.currentAuthId].hhgSegments;
      const tempSegIndex = tempSegments.findIndex((s) => s.segmentId === action.response.segmentId);
      tempSegments[tempSegIndex] = action.response;
      draft.authorizationDetails[draft.currentAuthId].hhgSegments = tempSegments;
      break;

    case GET_HHG_MOVING_DATE_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].hhgMovingDates = action.response;
      break;

    case GET_HHG_ITEMS_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].hhgItems = action.response;
      break;

    case GET_HHG_ESTIMATES_SUCCESS:
      draft.authorizationDetails[draft.currentAuthId].hhgEstimates = action.response;
      break;

    case UPDATE_HHG_ESTIMATE_SUCCESS:
      let tempEstimates = draft.authorizationDetails[draft.currentAuthId].hhgEstimates;
      const tempEstimateIndex = tempEstimates.findIndex((e) => e.estimateId === action.response.estimateId);
      tempEstimates[tempEstimateIndex] = action.response;
      draft.authorizationDetails[draft.currentAuthId].hhgEstimates = tempEstimates;
      break;

    default:
      break;
  }

  return draft;
};

export default handleHouseHoldGoodsReducer;
