/* eslint-disable react-hooks/exhaustive-deps */

import { Box, ThemeProvider, createTheme } from '@mui/material';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import FullscreenSpinner from 'modules/common/fullscreenSpinner.component';

import { COLOR_PRIMARY, COLOR_SECONDARY, COLOR_WARNING } from 'styles/theme';
import { GET_AUTH_SUMMARY_FAILURE, GET_EMPLOYEE_INFO_FAILURE, GET_AUTH_CLIENT_POLICIES_FAILURE, GET_AUTH_DATE_SUMMARY_FAILURE, GET_AUTH_SERVICES_FAILURE,
  GET_AUTH_TEAM_FAILURE, GET_COMMUNICATION_INFO_FAILURE, GET_DEPARTURE_ADDRESS_FAILURE, GET_DESTINATION_ADDRESS_FAILURE,
  GET_SO_INFO_FAILURE, GET_USER_SETTINGS_FAILURE, } from '../../../store/types/authorizationTypes';
import { GET_EXPENSE_LINE_BY_SUMMARY_ID_FAILURE, GET_EXPENSE_LINES_BY_AUTH_FAILURE, GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE } from 'modules/expenses/types';
import { TOAST_MESSAGE_SEVERITY_ERROR } from '../../../../layout/types';
import { getAuthClientPolicies, getEmployeeInfo, setAuthId, getAuthDateSummary, getAuthorizationSummary, getCommunicationInfo, getDepartureAddress, getDestinationAddress,
  getSOInfo, getServices, getTeam, getUserSettings } from '../../../store/actions/authorizations.actions';
import { getExpenseLinesByAuthId, getExpenseLinesBySummaryId, getExpenseSummariesByAuth } from 'modules/expenses/expenses.actions';
import { getClients, setLoadingComplete } from 'modules/clients/clients.actions';
import { showToast } from 'modules/layout/layout.actions';
import ExpenseDetails from './expenseDetails.component';
import { getBrokerAgents, getExpenseCodes, getInspectors, getTitleReps } from 'modules/authorizations/store/actions/dataManagement.actions';
import { GET_ALL_BROKER_AGENTS_FAILURE, GET_EXPENSE_CODES_FAILURE, GET_INSPECTORS_FAILURE, GET_TITLE_REP_FAILURE } from 'modules/authorizations/store/types/dataManagementTypes';

import {
  getHhgSegments,
} from 'modules/authorizations/store/actions/houseHoldGoods.actions';

import {
  getVendorContacts, getVendors,
} from 'modules/authorizations/store/actions/dataManagement.actions';

import {
  GET_HHG_SEGMENTS_FAILURE,
} from 'modules/authorizations/store/types/houseHoldGoodsTypes';

import {
  GET_VENDORS_FAILURE, GET_VENDOR_CONTACT_FAILURE,
} from 'modules/authorizations/store/types/dataManagementTypes';
import { GET_CLIENTS_FAILURE } from 'modules/clients/types';
import { getAssignmentExtensions, getAssignmentTrips, getGlobalHomeServiceProperties, getGlobalInfo } from 'modules/authorizations/store/actions/global.actions';
import { getAllAppraisals, getAllListings, getEquity, getHomesaleInfo, getHomesaleResaleOffers, getInspections } from 'modules/authorizations/store/actions/homesale.actions';
import { GET_APPRAISALS_FAILURE, GET_EQUITY_FAILURE, GET_HOMESALE_INFO_FAILURE, GET_HOMESALE_RESALE_OFFER_FAILURE, GET_INSPECTIONS_FAILURE, GET_LISTINGS_FAILURE } from 'modules/authorizations/store/types/homesaleTypes';
import { GET_ASSIGNMENT_EXTENSIONS_FAILURE, GET_ASSIGNMENT_TRIPS_FAILURE, GET_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE, GET_GLOBAL_INFO_FAILURE } from 'modules/authorizations/store/types/globalTypes';
import { getAuthDestinationServices, getOfferHistoryList, getHouseHuntingList } from 'modules/authorizations/store/actions/destinationService.actions';
import { GET_AUTH_DESTINATION_SERVICES_FAILURE, GET_AUTH_HOUSE_HUNTING_LIST_FAILURE, GET_AUTH_OFFER_HISTORY_LIST_FAILURE } from 'modules/authorizations/store/types/destinationServiceTypes';
import { getCandidateServices, getInternServices, getCandidateTrips, getCostEstimates } from 'modules/authorizations/store/actions/canidateTrip.actions';
import { GET_ALL_CANDIDATE_TRIPS_FAILURE, GET_CANDIDATE_SERVICES_FAILURE, GET_COST_ESTIMATES_FAILURE, GET_INTERN_SERVICES_FAILURE } from 'modules/authorizations/store/types/canidateTripTypes';

const ExpenseDetailsView = props => {
  const { authorizationDetails, currentAuthId, match, expenseCodes } = props;

  const theme = createTheme({
    palette: {
      primary: COLOR_PRIMARY,
      secondary: COLOR_SECONDARY,
      warning: COLOR_WARNING,
    },
  });

  const details = authorizationDetails ? authorizationDetails[currentAuthId] : null;
  const clientInfo = details && details.summary && props.unmappedClients ? props.unmappedClients.find((e) => e.description === details.summary.clientName) : null;

  const expenseId = match.params.expenseId;
  const authId = match.params.id;

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const id = match.params.id;

      setIsLoading(true);

      await props.setAuthId(id);

      const [authSummaryResp, employeeInfoResp, expenseSummariesResp, expenseLinesResp, expenseLinesByAuthIdResp, expenseCodesResp, clientsResp,
        brokerAgentsResp, inspectionsResp
       ] = await Promise.all([
        props.getAuthorizationSummary(id),
        props.getEmployeeInfo(id), 
        props.getExpenseSummariesByAuth(id), 
        props.getExpenseLinesBySummaryId(expenseId), 
        props.getExpenseLinesByAuthId(id),
        props.getExpenseCodes(), 
        props.getClients(),
        props.getBrokerAgents(),
        props.getInspectors(),
      ]);

      props.setLoadingComplete();
      setIsLoading(false);

      if (authSummaryResp.type === GET_AUTH_SUMMARY_FAILURE) {
        showToast('Failed to retrieve authorization info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (employeeInfoResp.type === GET_EMPLOYEE_INFO_FAILURE) {
        showToast('Failed to retrieve employee info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (expenseSummariesResp.type === GET_EXPENSE_SUMMARIES_BY_AUTH_FAILURE) {
        showToast('Failed to retrieve submitted expenses, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (expenseLinesResp.type === GET_EXPENSE_LINE_BY_SUMMARY_ID_FAILURE) {
        showToast('Failed to retrieve expense lines, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (expenseLinesByAuthIdResp.type === GET_EXPENSE_LINES_BY_AUTH_FAILURE) {
        props.showToast('Failed to retrieve expense lines by auth id, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (expenseCodesResp.type === GET_EXPENSE_CODES_FAILURE) {
        showToast('Failed to retrieve expense codes, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (clientsResp.type === GET_CLIENTS_FAILURE) {
        showToast('Failed to retrieve data, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
      }

      if (brokerAgentsResp.type === GET_ALL_BROKER_AGENTS_FAILURE) {
        props.showToast('Failed to retrieve broker/agents, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (inspectionsResp.type === GET_INSPECTIONS_FAILURE) {
        props.showToast('Failed to retrieve inspections, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
        return;
      }

      if (!details) {
        setIsLoading(true);
          
        const [authSummaryResp, employeeInfoResp, soInfoResp, authDateSummaryResp, communicationInfoResp, departureResp, destinationResp, authClientPoliciesResp,
          teamResp, authServiceResp, userSettingsResp, hhgSegmentsResp, vendorsResp, vendorContactsResp, globalInfoResp,
        assignmentTripsResp, assignmentExtensionsResp, homeSaleInfoResp, appraisalsResp, inspectorsResp,
      allListingsResp, titleRepsResp, homeSaleResaleOfferResp, equityResp, globalHomeServicePropertiesResp, destinationServicesResp, houseHuntingResp, offerHistoryResp, candidateServicesResp, internServicesResp, candidateTripsResp, costEstimatesResp ] = await Promise.all([
          props.getAuthorizationSummary(id),
          props.getEmployeeInfo(id),
          props.getSOInfo(id),
          props.getAuthDateSummary(id),
          props.getCommunicationInfo(id),
          props.getDepartureAddress(id),
          props.getDestinationAddress(id),
          props.getAuthClientPolicies(id),
          props.getTeam(id),
          props.getServices(id),
          props.getUserSettings(id),
          props.getHhgSegments(id),
          props.getVendors(),
          props.getVendorContacts(),
          props.getGlobalInfo(id),
          props.getAssignmentTrips(id),
          props.getAssignmentExtensions(id),
          props.getHomesaleInfo(id),
          props.getAllAppraisals(id),
          props.getInspections(id),
          props.getAllListings(id),
          props.getTitleReps(id),
          props.getHomesaleResaleOffers(id),
          props.getEquity(id),
          props.getGlobalHomeServiceProperties(id),
          props.getAuthDestinationServices(id),
          props.getHouseHuntingList(id),
          props.getOfferHistoryList(id),
          props.getCandidateServices(id),
          props.getInternServices(id),
          props.getCandidateTrips(id),
          props.getCostEstimates(id),
        ]);
  
        props.setLoadingComplete();
        setIsLoading(false);

        if (authSummaryResp.type === GET_AUTH_SUMMARY_FAILURE) {
          props.showToast('Failed to retrieve authorization info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
    
        if (employeeInfoResp.type === GET_EMPLOYEE_INFO_FAILURE) {
          props.showToast('Failed to retrieve employee info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
    
        if (soInfoResp.type === GET_SO_INFO_FAILURE) {
          props.showToast('Failed to retrieve significant other info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
            
        if (authDateSummaryResp.type === GET_AUTH_DATE_SUMMARY_FAILURE) {
          props.showToast('Failed to retrieve auth summary info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (communicationInfoResp.type === GET_COMMUNICATION_INFO_FAILURE) {
          props.showToast('Failed to retrieve employee communication info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (departureResp.type === GET_DEPARTURE_ADDRESS_FAILURE) {
          props.showToast('Failed to retrieve departure address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (destinationResp.type === GET_DESTINATION_ADDRESS_FAILURE) {
          props.showToast('Failed to retrieve destination address info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
          props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (teamResp.type === GET_AUTH_TEAM_FAILURE) {
          props.showToast('Failed to retrieve auth team, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authServiceResp.type === GET_AUTH_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve auth services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (authClientPoliciesResp.type === GET_AUTH_CLIENT_POLICIES_FAILURE) {
          props.showToast('Failed to retrieve employee client policies info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (userSettingsResp.type === GET_USER_SETTINGS_FAILURE) {
          props.showToast('Failed to retrieve employee settings info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (hhgSegmentsResp.type === GET_HHG_SEGMENTS_FAILURE) {
          props.showToast('Failed to retrieve hhg segments info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (vendorsResp.type === GET_VENDORS_FAILURE) {
          props.showToast('Failed to retrieve vendor info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (vendorContactsResp.type === GET_VENDOR_CONTACT_FAILURE) {
          props.showToast('Failed to retrieve vendor contact info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
        
        if (globalInfoResp.type === GET_GLOBAL_INFO_FAILURE) {
          props.showToast('Failed to retrieve global info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (assignmentTripsResp.type === GET_ASSIGNMENT_TRIPS_FAILURE) {
          props.showToast('Failed to retrieve assignment trips info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (assignmentExtensionsResp.type === GET_ASSIGNMENT_EXTENSIONS_FAILURE) {
          props.showToast('Failed to retrieve assignment extensions, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (homeSaleInfoResp.type === GET_HOMESALE_INFO_FAILURE) {
          props.showToast('Failed to retrieve homesale info, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (appraisalsResp.type === GET_APPRAISALS_FAILURE) {
          props.showToast('Failed to retrieve appraisals, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (inspectorsResp.type === GET_INSPECTORS_FAILURE) {
          props.showToast('Failed to retrieve inspectiors, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (allListingsResp.type === GET_LISTINGS_FAILURE) {
          props.showToast('Failed to retrieve listings, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (titleRepsResp.type === GET_TITLE_REP_FAILURE) {
          props.showToast('Failed to retrieve title reps please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (homeSaleResaleOfferResp.type === GET_HOMESALE_RESALE_OFFER_FAILURE) {
          props.showToast('Failed to retrieve homesale resale offers, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (equityResp.type === GET_EQUITY_FAILURE) {
          props.showToast('Failed to retrieve equity, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (globalHomeServicePropertiesResp.type === GET_GLOBAL_HOME_SERVICE_PROPERTY_FAILURE) {
          props.showToast('Failed to retrieve global home service properties, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (destinationServicesResp.type === GET_AUTH_DESTINATION_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve destination services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (houseHuntingResp.type === GET_AUTH_HOUSE_HUNTING_LIST_FAILURE) {
          props.showToast('Failed to retrieve house hunting list, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (offerHistoryResp.type === GET_AUTH_OFFER_HISTORY_LIST_FAILURE) {
          props.showToast('Failed to retrieve offer history list, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
        
        if (candidateServicesResp.type === GET_CANDIDATE_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve candidate services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (internServicesResp.type === GET_INTERN_SERVICES_FAILURE) {
          props.showToast('Failed to retrieve intern services, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (candidateTripsResp.type === GET_ALL_CANDIDATE_TRIPS_FAILURE) {
          props.showToast('Failed to retrieve candidate trips, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }

        if (costEstimatesResp.type === GET_COST_ESTIMATES_FAILURE) {
          props.showToast('Failed to retrieve cost estimates, please try again', { severity: TOAST_MESSAGE_SEVERITY_ERROR });
          return;
        }
      }

    };

    fetchData();
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Box>
        {!isLoading && details && <ExpenseDetails 
          details={details} 
          expenseId={expenseId} 
          authId={authId} 
          clientInfo={clientInfo} 
          expenseCodes={expenseCodes} 
          history={props.history} 
          location={props.location} 
          match={props.match}
          brokerAgents={props.brokerAgents}
          vendors={props.vendors}
          inspectors={props.inspectors}
          titleReps={props.titleReps}
          />
        }
        {isLoading && <FullscreenSpinner />}
      </Box>
    </ThemeProvider>
  );
};

const mapStateToProps = state => {
  const { authorizationDetails, isLoading, currentAuthId, expenseCodes, brokerAgents, vendors, inspectors, titleReps } = state.authorizations;
  const { unmappedClients } = state.clients;
  return {
    authorizationDetails,
    isLoading,
    currentAuthId,
    expenseCodes,
    unmappedClients,
    brokerAgents,
    vendors,
    inspectors,
    titleReps
  };
};

export default compose(
  connect(mapStateToProps, {
    getAuthorizationSummary,
    getEmployeeInfo,
    getExpenseSummariesByAuth,
    setAuthId,
    setLoadingComplete,
    showToast,
    getExpenseLinesBySummaryId,
    getExpenseLinesByAuthId,
    getExpenseCodes,
    getHhgSegments,
    getSOInfo,
    getCommunicationInfo,
    getAuthClientPolicies,
    getAuthDateSummary,
    getDepartureAddress,
    getDestinationAddress,
    getTeam,
    getServices,
    getUserSettings,
    getVendors,
    getVendorContacts,
    getClients,
    getGlobalInfo,
    getAssignmentTrips,
    getAssignmentExtensions,
    getHomesaleInfo,
    getBrokerAgents,
    getAllAppraisals,
    getInspectors,
    getInspections,
    getAllListings,
    getTitleReps,
    getHomesaleResaleOffers,
    getEquity,
    getGlobalHomeServiceProperties,
    getAuthDestinationServices,
    getHouseHuntingList,
    getOfferHistoryList,
    getCandidateServices,
    getInternServices,
    getCandidateTrips,
    getCostEstimates,
  }),
)(ExpenseDetailsView);
